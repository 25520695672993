import './CheckoutSuccess.scss';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Container from '@material-ui/core/Container';
import { PopupButton } from 'react-calendly';

import { Button } from '@fifth-gear-tech/common-components/dist/components/Button';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Card } from '@fifth-gear-tech/common-components/dist/components/Card';
import Logger from '@fgt/common/utils/logger';

import InviteUserModal from '../../components/InviteUserModal';

import CartState from '../../state/cart';
import AuthState from '../../state/auth';
import ModalState from '../../state/modal';
import { STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDLABEL_PRODUCT_ID } from '../../utils/constants';

const logger = new Logger(__filename);

export default observer(() => {
    logger.extra();

    const [{ receiptUrl, checkoutSession }] = useState(() => CartState);
    const [subscriptionProductId, setSubscriptionProductId] = useState();

    const hasOnboardingLink = (subProductId) => [STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDLABEL_PRODUCT_ID].includes(subProductId);

    // refresh auth and get checkout session on load
    useEffect(async () => {
        logger.extra();

        // refresh token for new subscriptions
        await AuthState.refreshLogin();

        // get the checkout session
        await CartState.getCheckoutSession();

    }, []);

    // when the subscription product id is loaded in use 
    useEffect(() => {
        try {
            const [calendlyWidget] = document.getElementsByClassName('Calendly');
            if (calendlyWidget) {
                // hack to get auto button click to work on ios
                calendlyWidget.addEventListener('touchstart', () => { });
                calendlyWidget.addEventListener('touchend', () => { });
                calendlyWidget.addEventListener('touchcancel', () => { });
                calendlyWidget.addEventListener('touchmove', () => { });
                // if speedcad or speedtemplate subscription then open calendly widget
                if (hasOnboardingLink(subscriptionProductId)) {
                    calendlyWidget.click();
                }
            }
        } catch (err) {
            logger.error(err);
        }
    }, [subscriptionProductId]);

    // get the stripe product id from the checkout session
    useEffect(() => {
        setSubscriptionProductId(checkoutSession?.stripe_product_id);
    }, [checkoutSession]);

    const calendlyUrl = (subProductId) => {

        const linkBase = 'https://calendly.com/';

        switch (subProductId) {
            case STRIPE_SPEEDLABEL_PRODUCT_ID:
            case STRIPE_SPEEDCAD_PRODUCT_ID: return `${linkBase}yoshi-fifthgeartech-speedlabel/onboarding-and-training-60min-yoshi`;
            default: return `${linkBase}fifthgear/speedlabel-software-onboarding-calendar`;
        }
    };

    return (
        <div className='CheckoutSuccess'>
            <Container className="MuiContainer">
                <Toolbar />
                <Card>
                    <Typography variant='h4' align='center'>Your purchase was successful.
                        <div>
                            <div className='CheckIcon'>
                                <CheckCircleOutlineIcon htmlColor={'green'} style={{ width: '2rem', height: '2rem', marginTop: '1rem' }} />
                            </div>

                            {hasOnboardingLink(subscriptionProductId) &&
                                <PopupButton
                                    url={calendlyUrl(subscriptionProductId)}
                                    rootElement={document.getElementById('root')}
                                    text="Schedule integration"
                                    textColor="#ffffff"
                                    color="#46a758"
                                    isOpen={true}
                                    className='Calendly Button'
                                />
                            }

                            <Button label='Invite Team Members' className='InviteButton' type={ButtonType.Email} onClick={() => ModalState.open(<InviteUserModal />)} />

                            {receiptUrl &&
                                < a href={receiptUrl} target='_blank' rel="noreferrer">
                                    <Button label='Download Receipt' colorTheme='green' type={ButtonType.Save} />
                                </a>}

                            <a href={`https://${window.location.hostname}/account?activeTab=2`} target='_blank' rel="noreferrer">
                                <Button label='Manage Subscriptions' type={ButtonType.Update} colorTheme='green' />
                            </a>
                        </div>
                    </Typography>
                </Card>
            </Container >
        </div >
    );
});
