import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export default makeStyles((theme) => (
    {
        root: {
            display: 'flex',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        contentWrapper: {
            flexGrow: 1,
            // ffs why do we have a million different color palettes? 🤦
            minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(3),
        },
        tabFullWidth: {
            width: '100%',
        },
        listItemTypography: {
            textTransform: 'none !important'
        },
        'MuiTabs-scrollButtons.Mui-disabled': {
            opacity: '100%'
        }
    }
));