import makeStyles from '@material-ui/core/styles/makeStyles';
import colors from '../../theme/colors';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import Logger from '@fgt/common/utils/logger';
import PropTypes from 'prop-types';

const logger = new Logger(__filename);
const useStatusChipStyles = makeStyles(() => (
    {
        active: {
            borderColor: colors.green[200],
            color: colors.green[200],
            backgroundColor: colors.green[300],
            fontWeight: 'bold'
        },
        inactive: {
            borderColor: colors.red[200],
            color: colors.red[200],
            backgroundColor: colors.red[300],
            fontWeight: 'bold'
        }
    }
));

const StatusChip = ({ status }) => {
    logger.extra();
    const classes = useStatusChipStyles();

    return (
        <Chip
            className={status === 'active' ? classes.active : classes.inactive }
            variant='outlined'
            label={status.toUpperCase()}
        />
    );
};

StatusChip.propTypes = {
    status: PropTypes.string
};

export default StatusChip;