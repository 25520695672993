import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import useQuery from './queryHook';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const DashboardDrawer = ({
    DrawerHeader,
    DrawerTabs,
}) => {
    logger.extra();
    const classes = useStyles();
    const query = useQuery();
    const activeTab = Number(query.get('activeTab'));

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{
                elevation: 1,
            }}
        >
            <Toolbar />
            {DrawerHeader && (
                <CardContent>
                    {DrawerHeader}
                </CardContent>
            )}
            {DrawerHeader && <Divider />}
            <Tabs
                orientation='vertical'
                value={activeTab}
                variant='scrollable'
                scrollButtons='auto'
            >
                {DrawerTabs.map((drawerTab, i) => (
                    <Tab
                        key={`DrawerTab-${i}`}
                        classes={{ root: classes.tabFullWidth }}
                        label={drawerTab}
                    />
                ))}
            </Tabs>
        </Drawer>
    );
};

DashboardDrawer.propTypes = {
    DrawerHeader: PropTypes.node,
    DrawerTabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default DashboardDrawer;