import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useQuery } from '../../hooks';

import { Card } from '@fifth-gear-tech/common-components/dist/components/Card';

import Toolbar from '@material-ui/core/Toolbar';

import SignupForm from '../../features/signupForm';
import InviteState from '../../state/invite';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(() => {
    logger.extra();
    const [{ invite_object }] = useState(() => InviteState);
    const query = useQuery();
    const inviteCode = query.get('inviteCode');

    useEffect(() => {
        logger.extra({ inviteCode });
        if (inviteCode) {
            InviteState.setCode(inviteCode);
            InviteState.getCode();
        }
    }, []);

    const handleAcceptInvite = ({ ...rest }) => {
        logger.extra();
        InviteState.acceptInvite({
            email: rest.email,
            first_name: rest.firstName,
            last_name: rest.lastName,
            password: rest.password,
            phone: rest.phone,
            title: rest.title,
            username: rest.username
        });
    };

    return (
        <>
            <Toolbar />
            {invite_object && invite_object.user_email && (
                <Card>
                    <SignupForm
                        onSubmitClick={handleAcceptInvite}
                        overrideCompanyId={invite_object.invitedByCustomer.internal_id}
                        overrideEmail={invite_object.user_email}
                        emailImmutable={true}
                        disableCard
                        userOnly={true}
                    />
                </Card>
            )}
        </>
    );
});