import './EmailUpdateForm.scss';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { FormSection, FormContainer, Button, InputField } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, Orientation, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import Logger from '../Logger';

import AuthState from '../../state/auth';
import LoggerState from '../../state/logger';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
export default observer(() => {
    logger.extra();
    const [newEmail, setNewEmail] = useState('');
    const [emailResetCode, setEmailResetCode] = useState('');
    const [authState] = useState(() => AuthState);
    const [logState] = useState(() => LoggerState);

    useEffect(() => {
        logger.extra();
        if (authState.emailResetValidated === true) {
            setEmailResetCode('');
        }
    }, [authState.emailResetValidated]);

    const handleEmailOnChange = (e) => {
        logger.extra();
        logger.extra(e.target.value.split('@'));
        setNewEmail(e.target.value);
        if (e.target.value.split('@').length >= 2) {
            authState.validateEmail(e.target.value);
        }
    };

    return (
        <FormContainer
            colorTheme={Colors.Grey}
            title={''}
            buttons={[
                !!newEmail && !!authState.emailResetRequested ? (
                    <Button
                        key={'validate'}
                        label={'Validate Code'}
                        type={ButtonType.Save}
                        disabled={!newEmail && !emailResetCode}
                        colorTheme={Colors.Red}
                        onClick={() => {
                            AuthState.validateEmailUpdateCode({
                                newEmail,
                                resetCode: emailResetCode,
                            });
                        }}
                    />
                ) : (
                    <Button
                        key={'get-code'}
                        label={'Get Code'}
                        type={ButtonType.Reset}
                        disabled={!newEmail}
                        colorTheme={Colors.Orange}
                        onClick={() => {
                            AuthState.requestEmailUpdate({
                                newEmail,
                            });
                        }}
                    />
                )
            ]}
        >
            <FormSection
                colorTheme={Colors.Grey}
                orientation={Orientation.Horizontal}
                label={'Change Email'}
                subtext={'Please enter your new email and reset code here'}
            >
                <div className='EmailUpdateFormSection' >

                    {(logState.logs[AuthState.logKeys.email_update] && logState.logs[AuthState.logKeys.email_update].length > 0 && (
                        <Logger logKey={AuthState.logKeys.email_update} />
                    ))}

                    {(logState.logs[AuthState.logKeys.email_exists] && logState.logs[AuthState.logKeys.email_exists].length > 0 && (
                        <Logger logKey={AuthState.logKeys.email_exists} />
                    ))}

                    <InputField
                        value={newEmail}
                        placeholder={'email@address.com'}
                        label={'New Email Address'}
                        disabled={authState.emailResetRequested}
                        onChange={handleEmailOnChange}
                        clearIcon={false}
                    />

                    {authState.emailResetRequested && !authState.emailResetValidated && (<span><InfoCircledIcon /> Check your email for the reset code</span>)}

                    {authState.emailResetRequested && !authState.emailResetValidated && (
                        <InputField
                            value={emailResetCode}
                            placeholder={'Enter Reset Code Here...'}
                            label={'Reset Code'}
                            onChange={(e) => setEmailResetCode(e.target.value)}
                            clearIcon={false}
                        />
                    )}

                </div>

            </FormSection>
        </FormContainer>
    );
});