import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperUserCustomerRoles {
    user_id = null;

    user_customer_roles = [];

    loading = false;

    constructor() {
        logger.extra();
        makeObservable(this, {
            user_id: observable,
            user_customer_roles: observable,
            loading: observable,
            fetch: action,
            update: action,
        }, { deep: true });
    }

    async fetch(user_id) {
        logger.extra();
        try {
            this.loading = true;
            this.user_id = user_id;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/super/customerRoles/${user_id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { user_customer_roles } = res.body;

            this.user_customer_roles = user_customer_roles;

        } catch (e) {
            logger.error(' FETCH USER CUSTOMER ROLES ERROR:', e);

            LoggerState.addLog('superUserCustomerRoles', {
                type: 'error',
                message: `Error fetching user customer roles: ${e}`,
            });
        }

        this.loading = false;
    }

    // - customer_id: Number
    // - roles: [{ id, name, cost }]
    async update(customer_id, roles) {
        logger.extra();
        try {
            this.loading = true;
            const customerRoleIndex = this.user_customer_roles.findIndex((ucr) => ucr.id === customer_id);
            if (customerRoleIndex > -1) {
                const token = await AuthState.getToken();
                const res = await request('PUT', `${INTERNAL_API_BASE}/private/super/customerRoles/${this.user_id}`)
                    .set('Content-Type', 'application/json')
                    .set('Authorization', `Bearer ${token}`)
                    .send({
                        customer_id,
                        roles,
                    });
                const { user_customer_roles } = res.body;

                this.user_customer_roles.splice(customerRoleIndex, 1, user_customer_roles);
            }

            LoggerState.addLog('superUserCustomerRoles', {
                type: 'success',
                message: 'User customer roles updated!',
            });
        } catch (e) {
            logger.error('SET USER CUSTOMER ROLES ERROR:', e);

            LoggerState.addLog('superUserCustomerRoles', {
                type: 'error',
                message: `User customer roles error: ${e}`,
            });
        }

        this.loading = false;
    }

}

export default new SuperUserCustomerRoles();