import React from 'react';
import { Button } from '@fifth-gear-tech/common-components/dist/components/Button';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import PropTypes from 'prop-types';

const SubmitButton = ({ children, ...rest }) => (
    <Button
        disabled={rest.disabled}
        className={!rest.disabled ? 'SubmitButton' : 'SubmitButtonDisabled'}
        label={!children ? 'Login' : ' '}
        colorTheme={rest.disabled ? Colors.Grey : Colors.Orange}
        type={ButtonType.Login}
        form={true}
        onClick={rest.onClick}
    >{children}</Button>
);

SubmitButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
};

export default SubmitButton;