import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card, InputField, FormSection, Button } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import '../Customer.scss';

import { ENVIRONMENT } from '../../../../utils/constants';
import SuperCustomer from '../../../../state/super_customer';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer((/* { customer }*/) => {
    logger.extra();
    const [{ customer }] = useState(() => SuperCustomer);
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState(customer ? customer.name : '');
    const [internal_id, setInternalId] = useState(customer ? customer.internal_id : '');
    const [external_id, setExternalId] = useState(customer ? customer.external_id : '');

    useEffect(() => {
        setName(customer ? customer.name : '');
        setInternalId(customer ? customer.internal_id : '');
        setExternalId(customer ? customer.external_id : '');
    }, [customer]);

    const getShippingAddress = () => {
        logger.extra();
        const { stripeCustomer } = customer;

        if (!stripeCustomer.address && stripeCustomer.paymentMethods.length === 0) {
            return 'No Shipping Address Found.';
        }

        const {
            city,
            country,
            line1,
            line2,
            postal_code,
            state,
        } = stripeCustomer.address ? stripeCustomer.address : stripeCustomer.paymentMethods[0].billing_details.address;
        return `${line1 ? line1 : ''} ${line2 ? line2 : ''} ${city ? city : ''} ${state ? state : ''}, ${postal_code ? postal_code : ''}, ${country ? country : ''}`;
    };

    const displayCard = () => (
        <Card
            className={'CustomerDetails--card'}
        >
            <span>
                <p className={'subtext'}>Company Name:</p>
                <span>{customer.name}</span>
            </span>
            <span>
                <p className={'subtext'}>Company Id:</p>
                <span>{customer.internal_id}</span>
            </span>
            <span>
                <p className={'subtext'}>Stripe Customer Id:</p>
                <span>
                    <a
                        href={`https://dashboard.stripe.com${ENVIRONMENT !== 'production' ? '/test' : ''}/customers/${customer.external_id}`}
                        target="_blank"
                        rel="noreferrer"
                    >{customer.external_id}</a>
                </span>
            </span>
            <span>
                <p className={'subtext'}>Shipping Address:</p>
                <span>{getShippingAddress()}</span>
            </span>

            <hr className={Colors.Grey} />

            <div>
                <Button
                    label={'Edit Customer'}
                    onClick={() => setEditMode(true)}
                    type={ButtonType.Update}
                    colorTheme={Colors.Orange}
                />
            </div>
        </Card>
    );

    const editCard = () => (
        <Card
            className={'CustomerDetails--EditCard'}
        >
            <FormSection
                label={'Company Details'}
                subtext={'Edit basic company details here'}
            >
                <InputField
                    label={'Company Name'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <InputField
                    label={'Company Id'}
                    value={internal_id}
                    onChange={(e) => setInternalId(e.target.value)}
                />
                <InputField
                    label={'Stripe Customer Id:'}
                    value={external_id}
                    onChange={(e) => setExternalId(e.target.value)}
                />
                <p><strong>Note:</strong> Shipping address must be changed in Stripe.</p>
            </FormSection>
            <div className={'--buttonArea'}>
                <Button
                    label={'Cancel'}
                    onClick={() => setEditMode(false)}
                    type={ButtonType.Close}
                />
                {editMode ? (
                    <Button
                        label={'Save'}
                        onClick={() => {
                            SuperCustomer.updateCustomer({
                                id: customer.id,
                                name,
                                internal_id,
                                external_id,
                            });
                            setEditMode(false);
                        }}
                        type={ButtonType.Save}
                        colorTheme={Colors.Green}
                    />
                ) : null}
            </div>
        </Card>
    );

    return customer ? (
        <section className={'CustomerDetails'}>
            <h5>Details</h5>
            {editMode ? editCard() : displayCard()}
        </section>
    ) : null;
});