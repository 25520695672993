import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, FormSection, LocalAlert } from '@fifth-gear-tech/common-components/dist/components';
import { handleInput, set, signupFormSelector } from '../../features/signupForm/signupFormSlice';
import validation from '../../helpers/validation';

const CompanyDetails = () => {
    // --- redux state ---
    const dispatch = useDispatch();
    const signupForm = useSelector(signupFormSelector);
    const { companyName, companyId, companyDetailsValid } = signupForm;

    // component company ID validation
    const [repeatCompanyId, setRepeatCompanyId] = useState('');
    const [repeatCompanyIdDisabled, setRepeatCompanyIdDisabled] = useState(true);
    const [companyIdValid, setCompanyIdValid] = useState(false);
    const [companyIdAlertMessage, setCompanyIdAlertMessage] = useState('Missing');

    // --- helpers ----
    const purify = (val) => val.replace(/[^A-Za-z0-9]/g, ' ').split(' ').join('').toLowerCase();

    const handleCompanyIdInput = (target) => {
        const { name, value } = target;
        dispatch(handleInput({ name, value: purify(value) }));
    };

    const verifyCompany = async (cmpnyId) => {

        // is company_id in state
        if (cmpnyId.length > 0) {

            // check if company exists
            if (await validation.companyExists(cmpnyId)) {
                setCompanyIdAlertMessage('Company ID already in use.');
                setCompanyIdValid(false); // company id is NOT valid
                setRepeatCompanyId(''); // discard repeat company id
                setRepeatCompanyIdDisabled(true); // user CANNOT edit repeat company id
                return false; // NOT valid company id
            }

            // if company is in state and does not exist in db
            setCompanyIdValid(true); // the company id is VALID
            setRepeatCompanyIdDisabled(false); // the company CAN edit the user id
            return true; // VALID company id

        }

        // missing company id
        setCompanyIdAlertMessage('Missing');
        setRepeatCompanyId(''); // discard repeat company id
        setRepeatCompanyIdDisabled(true); // can NOT edit repeat customer id
        setCompanyIdValid(false); // company is not valid
        return false;
    };

    const setCompanyValid = (bool) => {
        dispatch(set({ name: 'companyDetailsValid', val: bool }));
    };

    const verifyDetails = async () => {
        const companyVerified = await verifyCompany(companyId);
        setCompanyValid(!!companyVerified && (companyId == repeatCompanyId) && !!companyName);
    };

    // --- return component ----
    return (
        <FormSection label={'Company Details'} subtext={'This is the name of your company along with your company\'s unique identifier.'}>
            <LocalAlert active={!companyName} message={'Missing'} />
            <InputField
                // aesthetic
                label={'Company Name:'}
                placeholder='Company Name'
                clearIcon={false}
                // entry
                value={companyName}
                name={'companyName'}
                onChange={(e) => {
                    handleCompanyIdInput({ name: 'companyId', ...e.target });
                    dispatch(handleInput(e.target));
                }}
                // validation
                onBlur={() => verifyDetails()}
                isValid={!!companyName} // is truthy string
            />

            {/* remind users that they're making a company not just a personal account */}
            <br />
            <div className='CompanyIDInfoCaption'>
                <p><strong>Note: </strong>Make sure your company is not already enrolled. If your company is already enrolled contact your administrator to create a personal account.</p>
            </div>

            <LocalAlert active={!companyIdValid} message={companyIdAlertMessage} />
            <InputField
                // aesthetic
                label={'Company ID:'}
                placeholder='company-id'
                clearIcon={false}
                // entry
                name={'companyId'}
                value={companyId}
                onChange={(e) => handleCompanyIdInput(e.target)}
                // validation
                onBlur={() => verifyDetails()}
                isValid={companyIdValid}
            />

            <LocalAlert active={!companyDetailsValid && companyIdValid} message={repeatCompanyId ? 'Company ID does not match.' : 'Confirm company ID.'} />
            <InputField
                // aesthetic
                label={'Repeat Company ID:'}
                placeholder='company-id'
                clearIcon={false}
                // entry
                value={repeatCompanyIdDisabled ? '' : repeatCompanyId}
                name={'repeatCompanyId'}
                onChange={(e) => setRepeatCompanyId(purify(e.target.value))}
                disabled={repeatCompanyIdDisabled}
                // validation
                isValid={companyDetailsValid}
                onBlur={() => verifyDetails()}
            />
        </FormSection >
    );

};

export default CompanyDetails;