import './PasswordReset.scss';

import React, { useState } from 'react';
import { observer } from 'mobx-react';


import PasswordResetRequest from '../../components/PasswordResetRequestForm';
import PasswordResetValidate from '../../components/PasswordResetValidateForm';
import Logger from '../../components/Logger';

import { useQuery } from '../../hooks';

import AuthState from '../../state/auth';
import LogState from '../../state/logger';
import { Redirect } from 'react-router-dom';

import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);

export default observer(() => {
    logger.extra();
    const query = useQuery();
    const tfaCode = query.get('resetHash');
    const [hasResetCode, setHasResetCode] = useState(tfaCode ? true : false);
    const [passwordReset, setPasswordReset] = useState(false);
    const onResetSubmit = (e, { username: uName, email: eMail }) => {
        logger.extra();
        e.preventDefault();
        AuthState.requestPasswordReset({ username: uName, email: eMail });
        setHasResetCode(true);
    };
    const onValidateSubmit = (e, { newPass, resetCode }) => {
        logger.extra();
        AuthState.validateResetCode({ password: newPass, tfaCode: resetCode });

        setPasswordReset(true);
    };

    const CustomHeader = () => {
        logger.extra();
        return (
            <div className='PasswordResetHeader'>

                {LogState.logs['pwResetRequest'] && (
                    <Logger logKey='pwResetRequest' />
                )}
            </div>
        );
    };

    return (
        <div className='homeHeaderBG HomeHeader'>

            {passwordReset && (
                <Redirect to='/' />
            )}

            {!hasResetCode && (
                <PasswordResetRequest
                    onSubmit={onResetSubmit}
                    customHeader={(
                        <CustomHeader />
                    )}
                    customLinkArea={(
                        <span
                            role='button'
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => setHasResetCode(true)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setHasResetCode(true);
                                }
                            }}
                            tabIndex={0}
                        >
                            Already Have a Code?
                        </span>
                    )}

                />
            )}

            {hasResetCode && (
                <PasswordResetValidate
                    onSubmit={onValidateSubmit}
                    customHeader={(
                        <CustomHeader />
                    )}
                    customLinkArea={(
                        <span
                            role='button'
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => setHasResetCode(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setHasResetCode(false);
                                }
                            }}
                            tabIndex={0}
                        >Resend Code</span>
                    )}
                />
            )}
        </div>
    );
});