import './ChargesTable.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, object, bool, func } from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';

import { Card, Button, FormSection } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType, Colors } from '@fifth-gear-tech/common-components/src/utils/StyledTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Chip,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

import { parseAmount } from '../../helpers/stripe/price';
import { isSuper } from '../../utils/auth';
import SearchableCustomers from '../../state/searchable_customers';
import { useWindowSize } from '../../hooks';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const determineStatusClass = (status) => {
    if (status === 'pending') {
        return '--warning';
    }
    if (status === 'succeeded') {
        return '--active';
    }
    if (status === 'failed') {
        return '--error';
    }
};

const ChargeRow = observer(({ charge, index, enableCustomerLink = false }) => {
    logger.extra();
    const timestamp = moment(charge.created * 1000);
    const [{ customers }] = useState(() => SearchableCustomers);
    const customerIndex = customers.findIndex((c) => c.external_id === charge.customer);

    return (
        <>
            <TableRow className={(index % 2) === 0 ? ' ' : 'invoiceRowOdd'}>
                <TableCell >{timestamp.format('MMMM Do YYYY')} at {timestamp.format('LT')}</TableCell>
                <TableCell >{parseAmount({ unit_amount: charge.amount })}</TableCell>
                <TableCell >
                    <Chip
                        className={`TransactionHistoryStatus ${determineStatusClass(charge.status)}`}
                        label={charge.status}
                    />
                </TableCell>
                <TableCell>
                    {isSuper() && enableCustomerLink && customerIndex > -1 && (
                        <Link
                            to={`/super/c/${customers[customerIndex].id}`}
                            className={'actionBtn'}
                        >{customers[customerIndex].name}</Link>
                    )}
                    {(!isSuper() || !enableCustomerLink || customerIndex === -1) && charge.billing_details.name}
                </TableCell>
                <TableCell >
                    <a
                        className={'actionBtn'}
                        target='_blank'
                        rel="noreferrer"
                        href={charge.receipt_url}
                    >View Receipt</a>
                </TableCell>
            </TableRow>
        </>
    );
});

const ChargesTable = observer(({ charges, loading, hasPagination, paginationOnClick, enableCustomerLink = false }) => {
    logger.extra();
    const { width } = useWindowSize();

    useEffect(() => {
        if (isSuper() && SearchableCustomers.customers.length === 0) {
            SearchableCustomers.superFetchAll();
        }
    }, []);

    if (width <= 768) {
        return (
            <>
                {charges.map((charge, i) => {
                    logger.extra('MAPPING CHARGES:', charge);
                    return <MobileChargeCard key={`mobile-charge-card-${i}`} charge={charge} />;
                })}
            </>
        );
    }

    return (
        <section className={'TransactionHistory'}>
            <h4>Invoice History</h4>
            <Card
                className={'TransactionHistoryCard'}
            >
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date Invoiced</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Billing Name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {charges.map((charge, i) => (
                                <ChargeRow
                                    key={charge.id}
                                    charge={charge}
                                    index={i}
                                    enableCustomerLink={enableCustomerLink}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
                        <CircularProgress />
                    </div>
                )}
                {hasPagination && (
                    <div
                        style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}
                    >
                        <Button
                            label='Load More'
                            type={ButtonType.Update}
                            onClick={(e) => paginationOnClick(e)}
                        />
                    </div>
                )}
            </Card>
        </section>
    );
});

const MobileChargeCard = observer(({ charge }) => {
    logger.extra('THIS THE MOBILE CHARGE CARD:', charge);
    const timestamp = moment(charge.created * 1000);
    const [{ customers }] = useState(() => SearchableCustomers);
    const customerIndex = customers.findIndex((c) => c.external_id === charge.customer);

    return (
        <Card className={'MobileChargeCard'}>
            <FormSection
                label={`${customerIndex > -1 ? customers[customerIndex].name : ''} ${parseAmount({ unit_amount: charge.amount })}`}
                subtext={(
                    <p>
                        {timestamp.format('MMMM Do YYYY')} {timestamp.format('LT')} &nbsp;
                        <Chip
                            className={`TransactionHistoryStatus ${determineStatusClass(charge.status)}`}
                            label={charge.status}
                        />
                    </p>
                )}
            >
                <hr className={Colors.Grey} />
                <a
                    className={'actionBtn'}
                    target='_blank'
                    rel="noreferrer"
                    href={charge.receipt_url}
                >View Receipt</a>
            </FormSection>
        </Card>
    );
});

ChargesTable.propTypes = {
    charges: arrayOf(object).isRequired,
    loading: bool.isRequired,
    hasPagination: bool,
    paginationOnClick: func,
};

export default ChargesTable;