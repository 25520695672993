import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import SearchableUsersState from '../../state/searchable_users';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const UserSearchSelect = observer(({
    disableLabel = false,
    SelectProps,
    GridContainerProps,
}) => {
    logger.extra();
    const [searchableUsersState] = useState(() => SearchableUsersState);

    useEffect(() => {
        logger.extra();
        if (searchableUsersState.entireUserBase.length === 0) {
            SearchableUsersState.superFetchAll();
        }
    }, [searchableUsersState.entireUserBase.length]);

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: '1500'
        }),
    };

    return (
        <Grid {...GridContainerProps} container item>
            {!disableLabel && (
                <>
                    <Grid item xs={12}>
                        <Typography align='left'>Select Existing User</Typography>
                    </Grid>
                    <Grid item xs={12} ><Divider /></Grid>
                </>
            )}
            <Grid item xs={12}>
                <Select
                    {...SelectProps}
                    styles={customStyles}
                    options={searchableUsersState.entireUserBase}
                />
            </Grid>
        </Grid>
    );
});

export default UserSearchSelect;