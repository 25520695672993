import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Card } from '@fifth-gear-tech/common-components/dist/components';
import StatusChip from '../../../../components/StatusChip';
import SubscriptionsState from '../../../../state/subscriptions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../Customer.scss';

import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(({ subscriptions }) => {
    logger.extra();

    const generateTable = () => {
        logger.extra();
        return (
            <TableContainer className={'CustomerSubscriptions--table'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Subscription</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Renewal Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(subscriptions.length === 0) &&
							<tr className={'emptyListRow'}>
							    <td><p className={'subtext'}>No Subscriptions For This Customer.</p></td>
							</tr>
                        }

                        {subscriptions.map((subscription, i) => {
                            const externalPrice = subscription.external_subscription ? SubscriptionsState.buildExternalPrice(subscription) : { price: '$0.00', interval: 'per month' };
                            if (!externalPrice) {
                                return null;
                            }
                            const { price, interval } = externalPrice;
                            const date = moment(subscription.external_subscription ? subscription.external_subscription.current_period_end * 1000 : subscription.created_at);

                            // logger.extra('SUBSCRIPTION:', subscription);

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        {subscription.program.name}
                                    </TableCell>
                                    <TableCell>
                                        <StatusChip status={subscription.status} />
                                    </TableCell>
                                    <TableCell>
                                        {price} {interval}
                                    </TableCell>
                                    <TableCell>
                                        {date.format('MMM Do YYYY')}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <section className={'CustomerSubscriptions'}>
            <div>
                <h5>Subscriptions</h5>
            </div>
            <Card className={'CustomerSubscriptions--card'}>
                {generateTable()}
            </Card>
        </section>
    );
});