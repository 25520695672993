import './Signup.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';

import SignupForm from '../../features/signupForm';

import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Card } from '@fifth-gear-tech/common-components/dist/components';


const SignUpPage = () => (
    <>
        <Toolbar />
        <Toolbar />
        <section className={'SignUpSection'}>
            <h4>Enroll Company</h4>
            <Card className={'SignupCard'} colorTheme={Colors.Gray}>
                <SignupForm
                    disableCard={true}
                />
            </Card>
            <p className={'subtext'}>
                Already Have an Account? <Link to="/login">Sign In</Link>
            </p>
        </section>
    </>
);

export default SignUpPage;