import './UpdateBilling.scss';

import { Card } from '@fifth-gear-tech/common-components/dist/components';
import Logger from '@fgt/common/utils/logger';
import authState from '../../state/auth';
import SignInForm from '../../components/SignInForm';
import { hasAuth, isAdmin } from '../../utils/auth';
import React, { useEffect } from 'react';

const logger = new Logger(__filename);

const RedirectMessage = () => isAdmin() ? <h3>Redirecting you to Stripe...</h3> : <h4>Only company admins can update billing info. Please contact your admin.</h4>;

const UpdateBilling = () => {

    useEffect(async () => {
        if (hasAuth()) {
            try {
                await authState.requestStripePortalSessionRedirect();
                window.location.href = authState.stripePortalUrl;
            } catch (err) {
                logger.error(err);
            }
        }
    }, []);

    return (
        <div className='UpdateBilling'>
            {
                !hasAuth()
                    ?
                    // prompt login
                    <Card>
                        <SignInForm onLoginSuccess={() => window.location.reload()} customHeader={<h3>Sign In To Update Billing Info</h3>} />
                    </Card>
                    :
                    <RedirectMessage />

            }
        </div>
    );
};

export default UpdateBilling;