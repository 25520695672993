import './CartProductLineItem.scss';

import React from 'react';
import { observer } from 'mobx-react';

import { FormSectionLabel, Checkbox } from '@fifth-gear-tech/common-components/dist/components';

import Grid from '@material-ui/core/Grid';

import { transformPrice } from '../../helpers/stripe/price';

import CartState from '../../state/cart';
import Logger from '@fgt/common/utils/logger';
import { STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID } from '../../utils/constants';
import QuantityInput from '../QuantityInput';

const logger = new Logger(__filename);

const LineItem = observer(({ product, price, qty }) => {
    logger.extra();

    const transformedPrice = transformPrice({ price, unit: 'User' });

    const buildPriceSubtext = () => {
        const { breakdown } = transformedPrice;

        return breakdown.map((bd, index) => `
		${index === 0 ? '' : bd.per_unit_preamble} 
		${bd.per_unit_price} 
		${bd.per_unit_string ? bd.per_unit_string : ''} 
		${bd.up_to_string ? bd.up_to_string : ''}${index === breakdown.length - 1 ? bd.interval_string ? ` ${bd.interval_string}` : '' : ''}`).join(', ');
    };

    const onInputCallback = (value) => {

        // if has components, remove components first, then remove parent product.
        const quantity = value === '' || !value ? 0 : Number(value);

        if (qty > 0) {
            CartState.updateQty({ product_id: product.id, qty: quantity });
        }

        if (qty === 0 && quantity > 0) {
            CartState.addItem({ product, price, qty: quantity });
        }

    };

    return (
        <Grid className={'LineItem'} container item xs={12} >

            <Grid item xs={6}>
                <FormSectionLabel label={product.external_product.name} subtext={buildPriceSubtext()} />
            </Grid>

            <Grid container item xs={6} justify='flex-end'>
                {/* Quantity Modifier Only enabled for SPEEDcad, and Components. Not SPEEDlabel*/}
                <Grid item>

                    {/* Dillon is only using local storage to update these 🤦 so i'm using up and down arrows to avoid having to rebuild the cart */}
                    {[STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID].includes(product?.external_product_id) ?
                        <QuantityInput
                            qty={qty}
                            decreaseCallback={() => {
                                CartState.updateQty({ product_id: product?.id, qty: qty - 1 });
                            }}
                            increaseCallback={() => {
                                CartState.updateQty({ product_id: product?.id, qty: qty + 1 });
                            }}
                        />
                        :
                        <Checkbox label={null} defaultChecked={CartState.itemInCart(product)} inputCallback={(checked) => onInputCallback(checked)} />
                    }
                </Grid>
            </Grid>

        </Grid >
    );
});

export default observer(({ product, price, qty }) => {
    logger.extra();

    return (
        <Grid container item className={'LineItemWrapper'} spacing={2}>
            <Grid item xs={12}>
                <LineItem product={product} price={price} qty={qty} />
            </Grid>

            <Grid item xs={12}>
            </Grid>

            <hr />

        </Grid>
    );
});