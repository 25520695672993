import './SignupForm.scss';

import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// validation is done within these components
import { AccountDetails, CompanyDetails, PersonalDetails, GranQuartzSalesRepDetails, SubmitButton } from '../../components';
// all forms 👆 state is pulled from this slice here 👇
import { signupFormSelector } from '../../features/signupForm/signupFormSlice';

import AuthState from '../../state/auth';
import { hasAuth } from '../../utils/auth';

import eula from '../../assets/eula.pdf';

const SignupForm = ({ onSubmitClick, signUpSuccess, userOnly = false, auth0AccessToken = null, overrideEmail = null, emailImmutable = false, granQuartz = false }) => {

    // focus references for different forms
    const [accountCredsRef, compDetailsRef, personalDetailsRef, granQuartzSalesRef] = [useRef(), useRef(), useRef(), useRef()];

    // redux signup form state ⚛️
    const signupForm = useSelector(signupFormSelector);
    const {
        // --- account details ---
        email, password, accountDetailsValid,
        // --- company details ---
        companyId, companyName, companyDetailsValid,
        // --- personal details ---
        firstName, lastName, phone, title, personalDetailsValid,
        // --- gran quartz sales rep details ---
        repFirstName, repLastName, repDetailsValid
    } = signupForm;

    // auth state 🤷 from dillon's code (one day this needs to be overhauled to not use classes as state 🤦)
    const [{ user }] = useState(() => AuthState);
    const [signupComplete, setSignupComplete] = useState(false);
    const [loginComplete, setLoginComplete] = useState(false);

    // this must be checking if the user has signed up and logged in? 🤷 more of dillon's code
    useEffect(() => {
        if (user && signupComplete && !loginComplete && !hasAuth()) {
            const loginBody = { username: email, companyId };

            if (!password && !!auth0AccessToken) {
                loginBody.auth0AccessToken = auth0AccessToken;
            }
            if (!!password && !auth0AccessToken) {
                loginBody.password = password;
            }

            AuthState.login(loginBody);
            setLoginComplete(true);
        }
    }, [user, signupComplete, loginComplete, email, password, auth0AccessToken, companyId]);

    // callback to run when create user / company form is submitted ...more of dillon's code
    const handleSubmit = async () => {

        const formData = { email, username: email, password, companyName, companyId, firstName, lastName, title, phone, auth0AccessToken, granQuartz: granQuartz ? { repFirstName, repLastName } : false };

        // if there is an accept invite callback run it
        if (onSubmitClick) {
            await onSubmitClick(formData);
        }

        // if this is not an invite accept create a company & user
        if (!onSubmitClick) {
            await AuthState.create(formData);
            setSignupComplete(true);

            await AuthState.login(formData); // log in immediately after signup
            setLoginComplete(true);
        }

        // this is after auth so as to not get a 401 error
        if (signUpSuccess) {
            await signUpSuccess();
        }
    };

    // validate that all data is present (minus companyName and companyId if this is a user only signup flow)
    const isDisabled = () =>
        // no user email or password
        !email || !password ||
        // no company name or company id if this is a user only signup
        !(companyName && companyId || userOnly) ||
        // no first name or last name or title
        !firstName || !lastName || !title ||
        // no sales rep first name or last name if this is a granQuartz signup
        (!repFirstName && !repLastName && granQuartz) ||
        // check that all details components are
        !accountDetailsValid || !(companyDetailsValid || userOnly) || !personalDetailsValid || (!repDetailsValid && granQuartz);

    return (
        <div>

            {/* hidden button for form focusing */}
            < button className={'Reference'} ref={accountCredsRef} />

            {/* if creating a company and a user */}
            {
                !userOnly && (
                    <>
                        {/* hidden button for form focusing */}
                        <button className={'Reference'} ref={compDetailsRef} />
                        <CompanyDetails />
                    </>
                )
            }

            <AccountDetails
                overrideEmail={overrideEmail}
                emailImmutable={emailImmutable}
                auth0AccessToken={auth0AccessToken}
                userOnly={userOnly}
            />

            {/* hidden button for form focusing */}
            <button className={'Reference'} ref={personalDetailsRef} />
            <PersonalDetails />

            {granQuartz &&
                <div>
                    {/* hidden button for form focusing */}
                    < button className={'Reference'} ref={granQuartzSalesRef} />
                    <GranQuartzSalesRepDetails />
                </div>
            }

            {
                // account details not valid
                !accountDetailsValid ||
                    // company details not valid (and not user only signup)
                    (!companyDetailsValid && !userOnly) ||
                    // personal details not valid
                    !personalDetailsValid ||
                    // gran quartz sales rep details not valid (and gran quartz signup)
                    (!repDetailsValid && granQuartz) ?
                    // show problems
                    <div className='Problems'>
                        <strong >There seems to be some issue(s) with your:</strong>
                        <ul>
                            {!accountDetailsValid && (
                                <li
                                    onClick={() => accountCredsRef.current.focus()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            accountCredsRef.current.focus(); 
                                        } 
                                    }}
                                    // eslint-disable-next-line
                                    role="button"
                                    tabIndex={0}
                                >
                                    <span>Email or password.</span>
                                </li>
                            )}
                            {(!companyDetailsValid && !userOnly) && (
                                <li
                                    onClick={() => compDetailsRef.current.focus()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            compDetailsRef.current.focus(); 
                                        } 
                                    }}
                                    // eslint-disable-next-line
                                    role="button"
                                    tabIndex={0}
                                >
                                    <span>Company name or company ID.</span>
                                </li>
                            )}
                            {!personalDetailsValid && (
                                <li
                                    onClick={() => personalDetailsRef.current.focus()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            personalDetailsRef.current.focus(); 
                                        } 
                                    }}
                                    // eslint-disable-next-line
                                    role="button"
                                    tabIndex={0}
                                >
                                    <span>Name, title, or phone number.</span>
                                </li>
                            )}
                            {(!repDetailsValid && granQuartz) && (
                                <li
                                    onClick={() => granQuartzSalesRef.current.focus()}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            granQuartzSalesRef.current.focus(); 
                                        } 
                                    }}
                                    // eslint-disable-next-line
                                    role="button"
                                    tabIndex={0}
                                >
                                    <span>GranQuartz rep details.</span>
                                </li>
                            )}
                        </ul>

                        <p>Fix them to continue.</p>
                    </div>

                    // else display submit button
                    :
                    < div >
                        <div className={'TosCheckboxContainer'}>
                            <p>By clicking &quot;Create Company&quot; you agree to FifthGear Tech&apos;s <a href={eula} target='_blank' rel="noreferrer" style={{ color: '#ff791f' }} > End User License Agreement</a>.</p>
                        </div>

                        <div>
                            <SubmitButton onClick={handleSubmit} disabled={isDisabled()}>Create {userOnly ? 'User' : 'Company'}</SubmitButton>
                        </div>
                    </div>
            }
        </div>
    );
};

SignupForm.propTypes = {
    onSubmitClick: PropTypes.func,
    signUpSuccess: PropTypes.func,
    userOnly: PropTypes.bool,
    auth0AccessToken: PropTypes.string,
    overrideEmail: PropTypes.string,
    emailImmutable: PropTypes.bool,
    granQuartz: PropTypes.bool,
};

export default SignupForm;