import React, { useState } from 'react';

import './RemoveLicenseModal.scss';

import PropTypes from 'prop-types';
import { Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import SubscriptionState from '../../state/subscriptions';
import ModalState from '../../state/modal';

const decreaseUserLimit = async ({ password, userLimit, subscriptionItemId }) => {
    try {
        document.body.style.pointerEvents = 'none'; // disable mouse events so users don't do anything weird
        ModalState.open(<h3>Updating your subscription...</h3>);
        await SubscriptionState.updateUserLimit({ password, userLimit, subscriptionItemId });
        ModalState.open(<h3>Your user limit has been updated.</h3>);
        setTimeout(() => {
            document.body.style.pointerEvents = 'auto';
            window.location.reload();
        }, 1000);
    } catch (err) {
        {
            // if the password incorrect
            err.status == 401 || !password ?
                ModalState.open(<div>
                    <h3>Incorrect Password!</h3>
                </div>)
                // else have user contact support
                :
                ModalState.open(<div>
                    <h3>There was an issue updating your subscription. Please contact support.</h3>
                    <p>(708) 505-2812</p>
                    <p>support@fifthgeartech.com</p>
                </div>);
            document.body.style.pointerEvents = 'auto';
        }
    }
};

const RemoveLicenseModal = ({ programName, subscription, userLimit }) => {

    const { external_subscription, program: { external_id } } = subscription;

    const { id } = external_subscription.items.data.find((i) => i.plan.product == external_id);

    const [password, setPassword] = useState(null);

    return (
        <div className='RemoveLicenseModal'>
            <h3>Decrease <strong>{programName}</strong> user limit to {userLimit}?</h3>

            <div className='ConfirmPassword'>
                <p>Confirm Password:</p>
                <input type='password' onChange={(e) => setPassword(e.target.value)}></input>
            </div>

            <Button label='Yes' type={ButtonType.Save} colorTheme={ButtonType.Green} onClick={() => decreaseUserLimit({ password, userLimit, subscriptionItemId: id })} />
            <Button className='Cancel' type={ButtonType.Close} colorTheme={ButtonType.Red} label='Never Mind' onClick={() => ModalState.close()} />
        </div>
    );
};

RemoveLicenseModal.propTypes = {
    programName: PropTypes.string,
    subscription: PropTypes.object,
    userLimit: PropTypes.number
};

export default RemoveLicenseModal;