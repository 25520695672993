import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { Card, Button } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import UsersTable from '../../../components/UsersTable';
import UserModal from './UserModal';
import UserSearchSelect from '../../../components/UserSearchSelect';
import ModalState from '../../../state/modal';
import './Users.scss';
import Fuse from 'fuse.js';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(({ users, index }) => {

    logger.extra();

    const match = useRouteMatch();
    const [filteredUsers, setFilteredUsers] = useState(users);

    const fuse = new Fuse(users, { keys: ['first_name', 'last_name', 'name', 'email', 'phone', 'username'] });

    const [selectedUser, setSelectedUser] = useState(null);
    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        logger.extra();
        setFilteredUsers(users);
    }, [users]);

    const search = (searchVal) => fuse.search(searchVal).map((itm) => itm.item);

    if (selectedUser) {
        return <Redirect to={`${match.url}/u/${selectedUser.id}?activeTab=${index}`} />;
    }

    return (
        <section className={'Users'}>
            <div>
                <h4>Users</h4>
                <div>
                    <UserSearchSelect
                        disableLabel
                        SelectProps={{
                            isClearable: true,
                            onInputChange: (opt, { action }) => {
                                if (opt && opt !== '') {
                                    setFilteredUsers(search(opt));
                                }
                                if (opt === '' && action === 'input-change') {
                                    setFilteredUsers(users);
                                }
                            },
                            onChange: (selectedValue) => {
                                if (selectedValue) {
                                    setFilteredUsers(search(selectedValue.label));
                                }
                                if (!selectedValue) {
                                    setFilteredUsers(users);
                                }
                            }
                        }}
                    />
                    <Button
                        onClick={() => ModalState.open(<UserModal />, { paperless: true })}
                        label={'Create User'}
                        type={ButtonType.Add}
                    />
                </div>
            </div>
            <Card colorTheme={Colors.Grey} className={'Users--card'}>
                <UsersTable
                    users={filteredUsers.length > 0 ? filteredUsers : users}
                    onSettingsClick={(e, customer) => setSelectedUser(customer)}
                />
            </Card>
        </section>
    );
});