import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperCustomerUsers {
    customer_id = null;

    users = [];

    loading = false;

    constructor() {
        logger.extra();
        makeObservable(this, {
            customer_id: observable,
            users: observable,
            loading: observable,
            fetch: action,
            updateUserRoles: action,
        }, { deep: true });
    }

    async fetch(customer_id) {
        logger.extra();
        this.customer_id = customer_id;

        try {
            this.loading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${customer_id}/users`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { users } = res.body;

            this.users = users;
        } catch (e) {
            logger.error('FETCH CUSTOMER SUBSCRIPTIONS ERROR:', e);
            LoggerState.addLog('superCustomerUsers', {
                type: 'error',
                message: `Fetch Super Customer Users Error: ${e}`,
            });
        }

        this.loading = false;
    }

    async updateUserRoles(user_id, roles) {
        logger.extra();
        try {
            this.loading = true;
            const token = await AuthState.getToken();
            const res = await request('PUT', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/user`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    user_id,
                    roles,
                });

            const userIndex = this.users.findIndex((usr) => usr.id === user_id);

            if (userIndex > -1) {
                this.users.splice(userIndex, 1, res.body.user);
            } else {
                this.users.push(res.body.user);
            }

            LoggerState.addLog('superCustomerUsers', {
                type: 'success',
                message: 'Updated Customer User Roles!',
            });
        } catch (e) {
            logger.error('UPDATE USER ROLES ERROR:', e);
            LoggerState.addLog('superCustomerUsers', {
                type: 'error',
                message: `Update Customer User Roles Error: ${e}`,
            });
        }

        this.loading = false;
    }

    async delete() {

    }
}

export default new SuperCustomerUsers();