import React, { useState } from 'react';
import { observer } from 'mobx-react';
import LoggerState, { logTypeEnum } from '../../state/logger';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
import Error from './error';
import Info from './info';
import Warning from './warning';
import Success from './success';

const logTypeMappings = [
    {
        type: logTypeEnum.ERROR,
        component: Error,
    },
    {
        type: logTypeEnum.INFO,
        component: Info,
    },
    {
        type: logTypeEnum.WARNING,
        component: Warning,
    },
    {
        type: logTypeEnum.SUCCESS,
        component: Success,
    }
];

const Logger = observer(({ logKey, logTypes }) => {
    logger.extra();
    const [logState] = useState(() => LoggerState);
    const logs = logState.logs[logKey] ? logState.logs[logKey] : [];

    return (
        <>
            {logs.map((log, index) => {
                const { component: LogComponent } = logTypeMappings.find((mapping) =>
                    mapping.type === log.type);
                    // todo: look here if the component is freaking out. could be a need for== vs ===
                const logTypesIndex = logTypes ? logTypes.findIndex((type) => type === log.type) : -1;

                if (logTypes && logTypesIndex > -1) {
                    return <LogComponent key={`log-component-${index}`} log={log} />;
                } else if (logTypes && logTypesIndex < 0) {
                    return null;
                } else {
                    return <LogComponent key={`log-component-${index}`} log={log} />;
                }

            })}
        </>
    );
});

export default Logger;