import './AdvertiserOnboarding.scss';

import React, { useEffect } from 'react';

import { ButtonType, Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Button, Card } from '@fifth-gear-tech/common-components/dist/components';
import Logger from '@fgt/common/utils/logger';

import AuthTabs from '../../components/AuthTabs';
import AdTermsOfServiceModal from '../../components/AdTermsOfServiceModal';

import AccountUserState from '../../state/account_users';
import AuthState from '../../state/auth';
import ModalState from '../../state/modal';
import SpeedfolderState from '../../state/speedfolder';
import { sfBannerLight } from '../../assets';

import { hasAuth } from '../../utils/auth';
import { ROLES, SPEEDFOLDER_APP_BASE } from '../../utils/constants';

const logger = new Logger(__filename);
const LoadingMessage = <div><strong>Getting SPEEDfolder ready...</strong></div>;

const advertiserRoles = [ROLES.ADVERTISE, ROLES.DELETE, ROLES.GRANT, ROLES.READ, ROLES.WRITE];

const agreeToTerms = async (user_id) => {
    await AccountUserState.fetchUsers(AuthState.creds.user.cId);
    await AccountUserState.updateProgramRoles({ user_id, program_id: 5, roles: advertiserRoles });
    await SpeedfolderState.initSpeedfolderBasicAd();
    await AuthState.refreshLogin();
    window.location.href = SPEEDFOLDER_APP_BASE; // redirect
    ModalState.close();
};

const speedfolderAdvertiserSubscribe = async () => {
    try {
        document.body.style.pointerEvents = 'none'; // disable mouse events so users don't do anything weird
        ModalState.open(LoadingMessage);
        await SpeedfolderState.speedfolderCheckout(); // get everything ready
        const { uId } = AuthState.creds.user;
        document.body.style.pointerEvents = 'auto';
        ModalState.open(<AdTermsOfServiceModal uId={uId} callback={() => agreeToTerms(uId)} />);
    } catch (err) {
        logger.error(err, 'Error subscribing to SPEEDfolder.'); // log error
        window.location.href = SPEEDFOLDER_APP_BASE; // redirect (SF probably won't work at this point but it's better than nothing)
        throw err;
    }
};

const FifthGearSignupForAdvertiser = () => (
    <div>
        <h5><strong>Login</strong> or <strong>Enroll Company</strong> to become a <em>SPEEDfolder</em> Sponsor and Manage your ads.</h5>
        <AuthTabs
            colorTheme={Colors.Black}
            signUpSuccess={speedfolderAdvertiserSubscribe}
            onLoginSuccess={speedfolderAdvertiserSubscribe}
            defaultTab={'Login'}
        />
    </div>
);

const Speedfolder = () => {
    const isLoggedIn = hasAuth();

    useEffect(async () => {
        if (isLoggedIn) {
            await speedfolderAdvertiserSubscribe();
            return LoadingMessage;
        } else if (!isLoggedIn) {
            ModalState.open(<FifthGearSignupForAdvertiser />);
        }
    }, []);


    return (
        <div className='AdvertiserOnboarding'>
            <Card>
                <div className='ButtonForm'>

                    <img src={sfBannerLight} width={'450rem'} alt='speedfolder-banner' />

                    <div className='SignUpButton'>
                        <Button label='Join SPEEDfolder as an advertiser' type={ButtonType.Add} onClick={() => ModalState.open(<FifthGearSignupForAdvertiser />)} />
                    </div>

                </div>
            </Card>
        </div>);

};

export default Speedfolder;