import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import SuperIcon from '@material-ui/icons/Warning';

import './Super.scss';

import Dashboard, { DrawerTab } from '../../components/Dashboard';
import Customers, { CustomersTab } from './Customers';
import Users, { UsersTab } from './Users';
import PrintLog, { PrintLogTab } from './PrintLog';
import TransactionHistory, { TransactionHistoryTab } from './TransactionHistory';

import SearchableCustomers from '../../state/searchable_customers';
import SearchableUsers from '../../state/searchable_users';
import AuthState from '../../state/auth';

import Logger from '@fgt/common/utils/logger';
const logger = new Logger(__filename);

const Super = observer(() => {
    logger.extra();
    const [{ customers }] = useState(() => SearchableCustomers);
    const [{ entireUserBase }] = useState(() => SearchableUsers);

    useEffect(() => {
        logger.extra();
        if (SearchableCustomers.customers.length === 0) {
            SearchableCustomers.superFetchAll();
        }
        if (SearchableUsers.entireUserBase.length === 0) {
            SearchableUsers.superFetchAll();
        }
    }, []);

    return (
        <Dashboard
            DrawerHeader={
                <div className={'CurrentUserInfo'}>
                    <div>
                        <div>
                            <h5 className='subtext'>{`${AuthState.creds.user.first_name} ${AuthState.creds.user.last_name}`}</h5>
                            <p className='subtext'>{AuthState.creds.user.email}</p>
                            <br />
                            <p>
								Company: {AuthState.creds.user.cName}
                                <br />
								ID: {AuthState.creds.user.iId}
                            </p>
                            <br />
                        </div>
                    </div>

                    <div>
                        <strong className='danger'><SuperIcon style={{ marginBottom: '-2.5px' }} fontSize='small' color='error' /> ADMIN DASHBOARD</strong>
                    </div>
                </div>
            }
            DrawerTabs={[
                <CustomersTab key={0} index={0} />,
                <UsersTab key={1} index={1} />,
                <PrintLogTab key={2} index={2} />,
                <TransactionHistoryTab key={3} index={3} />,
                <DrawerTab key={4} index={4}>
                    <Typography color='error' variant='subtitle3'>Exit Admin Dashboard</Typography>
                </DrawerTab>,
                <DrawerTab key={5} index={5}>
                    <Typography variant='subtitle3'>Logout of FifthGearTech</Typography>
                </DrawerTab>
            ]}

        >
            <Customers index={0} customers={customers} />
            <Users index={1} users={entireUserBase} />
            <PrintLog index={2} />
            <TransactionHistory index={3} />
        </Dashboard >
    );
});

export default Super;