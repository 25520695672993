import './AccountDetails.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, FormSection, Checkbox, LocalAlert } from '@fifth-gear-tech/common-components/dist/components';
import { handleInput, reset, set, signupFormSelector } from '../../features/signupForm/signupFormSlice';
import { validation } from '../../helpers';
import PropTypes from 'prop-types';

const AccountDetails = ({ overrideEmail, emailImmutable, auth0AccessToken, userOnly }) => {

    // --- redux state ---
    const dispatch = useDispatch();
    // reset signup form state on component mount (this is here and not in the SignupForm feature so email is not overwritten for users being invited 🤷)
    useEffect(() => {
        dispatch(reset());
    }, []);
    const signupForm = useSelector(signupFormSelector);
    const { email, password, repeatPassword } = signupForm;

    // component email validation state
    const [emailValid, setEmailValid] = useState(false);
    const [emailAlertMessage, setEmailAlertMessage] = useState('Not a valid email.');

    // component password validation state
    const [passwordFieldType, setPasswordFieldType] = useState(true);
    const [passwordExists, setPasswordExists] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const emailValidation = async (eml) => {
        // if formatted email exists in state
        if (eml.length > 0 && validation.emailValid(eml)) {

            // check if email is already being used
            if (await validation.emailExists(eml)) {
                setEmailAlertMessage('Email already in use.');
                return false; // email is in use while properly formatted
            } else {
                return true; // email is properly formatted and does not exist
            }
        }

        setEmailAlertMessage('Not a valid email.');
        return false; // default (not valid or does not exist)
    };

    // set whether account details are valid given conditions
    // this is not in the "useEffect" because this doesn't need to spam the API
    const validateDetailsByEmail = async (eml, pwdExists, pwdsMatch) => {
        const validated = await emailValidation(eml);
        setEmailValid(validated);
        dispatch(set({ name: 'accountDetailsValid', val: validated && pwdExists && pwdsMatch }));
    };

    // set and validate override email on component mount
    useEffect(async () => {
        if (overrideEmail) {
            dispatch(set({ name: 'email', val: overrideEmail }));
            await validateDetailsByEmail(overrideEmail);
        }
    }, []);

    // listen to email and password changes to validate account details
    useEffect(() => {
        // password exists
        const exists = password.length > 0;
        setPasswordExists(exists);

        // password and repeat password exists
        const matches = String(password) === String(repeatPassword);
        setPasswordsMatch(matches);

        // set whether account details are valid given conditions
        dispatch(set({ name: 'accountDetailsValid', val: exists && matches && emailValid }));
    }, [password, repeatPassword, emailValid]);

    // --- helpers ---
    const purify = (val) => val.split(' ').join('').toLowerCase();

    const handleEmailInput = (target) => {
        const { name, value } = target;
        dispatch(handleInput({ name, value: purify(value) }));
    };

    // --- return component ----
    return (
        <FormSection onSubmit={(e) => e.preventDefault()} label={'Account Details'} subtext={userOnly ? 'Specify an email and password for your account.' : 'Specify an email and password for your administrative company account across all of Fifth Gear Tech.'}>

            <LocalAlert active={overrideEmail && !!email ? false : !emailValid} message={emailAlertMessage} />
            {/* email */}
            <InputField
                // aesthetic
                label='Email Address:'
                placeholder='example@email.com'
                clearIcon={false}
                // entry
                name={'email'}
                value={overrideEmail || email}
                disabled={emailImmutable}
                onChange={({ target }) => handleEmailInput(target)}
                // validation
                isValid={emailValid && !!email}
                onBlur={({ target }) => validateDetailsByEmail(target.value, passwordExists, passwordsMatch)}
            />

            {/* password and reset password */}
            {!auth0AccessToken && (
                <div>
                    <LocalAlert active={!passwordExists} message={'Missing'} />
                    {/* TODO: make this buffer unnecessary */}
                    <hr className='Buffer' />
                    <InputField
                        // aesthetic
                        label='Password:'
                        placeholder=''
                        clearIcon={false}
                        // entry
                        name={'password'}
                        type={passwordFieldType ? 'password' : 'text'}
                        onChange={({ target }) => dispatch(handleInput(target))}
                        // validation
                        isValid={passwordExists}
                    />

                    <LocalAlert active={!passwordsMatch} message={'Passwords don\'t match.'} />
                    <InputField
                        // aesthetic
                        label='Repeat Password:'
                        placeholder=''
                        clearIcon={false}
                        // entry
                        name={'repeatPassword'}
                        type={passwordFieldType ? 'password' : 'text'}
                        onChange={({ target }) => dispatch(handleInput(target))}
                        // validation
                        isValid={passwordExists}
                    />

                    <br />
                    <Checkbox
                        label={'Show Password'}
                        defaultChecked={false}
                        inputCallback={() => setPasswordFieldType(!passwordFieldType)}
                    />
                </div>
            )}

        </FormSection>
    );

};

AccountDetails.propTypes = {
    overrideEmail: PropTypes.string,
    emailImmutable: PropTypes.bool,
    auth0AccessToken: PropTypes.string,
    userOnly: PropTypes.bool,
};

export default AccountDetails;