import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import { SPEEDLABEL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperPrintLog {
    printLog = [];

    startDate = null;

    endDate = null;

    customer_id = null;

    constructor() {
        logger.extra();
        makeObservable(this, {
            printLog: observable,
            startDate: observable,
            endDate: observable,
            customer_id: observable,
            setStartDate: action,
            setEndDate: action,
            setCustomerID: action,
            fetchPrintLog: action,
            clearPrintLog: action,
        }, { deep: true });
    }

    setStartDate(date) {
        logger.extra();
        this.startDate = date;
    }

    setEndDate(date) {
        logger.extra();
        this.endDate = date;
    }

    setCustomerID(customer_id) {
        logger.extra();
        this.customer_id = customer_id;
    }

    async fetchPrintLog() {
        logger.extra();
        const startDateUnix = new Date(this.startDate);
        const endDateUnix = new Date(this.endDate);

        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${SPEEDLABEL_API_BASE}/private/admin/report/print/inches/${startDateUnix.getTime()}/${endDateUnix.getTime()}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query(this.customer_id ? { customer_id: this.customer_id } : {});

            this.printLog = res.body;
        } catch (e) {
            logger.error(e);
            LoggerState.addLog('superPrintLog.fetch', {
                type: 'error',
                message: `User subscription: ${e}`,
            });
        }
    }

    clearPrintLog() {
        logger.extra();
        this.printLog = [];
    }
}

export default new SuperPrintLog();