import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';

import AuthState from './auth';
import LoggerState from './logger';
import { INTERNAL_API_BASE } from '../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
// const AccountUserSchema = Joi.object({
//
// });

class AccountUsers {
    users = [];

    customer_id = null;

    logKeys = {
        createUser: 'account_users.create_user',
        updateProgramRoles: 'account_users.update_program_roles'
    };

    constructor() {
        logger.extra();
        makeObservable(this, {
            users: observable,
            customer_id: observable,
            fetchUsers: action,
            createUser: action,
            updateRoles: action,
        }, { deep: true });
    }

    async fetchUsers(customer_id) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${customer_id}/users`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});
            const { users } = res.body;

            this.customer_id = customer_id;
            this.users = users;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
        }
    }

    async createUser(newUser) {

        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/user`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(newUser);

            const { user } = res.body;

            this.users.push(user);

            // todo: add logger data here.
            LoggerState.addLog(this.logKeys.createUser, {
                type: 'success',
                message: 'User Created Successfully!'
            });

            this.fetchUsers(this.customer_id);

        } catch (e) {
            logger.error('ACCOUNT USER CREATE ERROR:', e);

            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog(this.logKeys.createUser, {
                type: 'error',
                message: `Error Creating User: ${e}`,
            });
        }
    }

    async updateRoles({ user_id, roles }) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            await request('PUT', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/user`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    user_id,
                    roles,
                });

            this.fetchUsers(this.customer_id);

            LoggerState.addLog('accountUser', {
                type: 'success',
                message: 'User Roles Updated Successfully!'
            });
        } catch (e) {

            logger.error(e);

            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('accountUser', {
                type: 'error',
                message: `Error Editing User Roles: ${e}`,
            });
        }
    }

    async updateProgramRoles({ user_id, program_id, roles }) {
        logger.extra({ user_id, program_id, roles });

        const token = await AuthState.getToken();
        const rolesRes = await request('PUT', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/program/${program_id}/user`)
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${token}`)
            .send({
                user_id,
                roles,
            });

        logger.extra('UPDATE PROGRAM ROLES:', rolesRes);

        this.fetchUsers(this.customer_id);

        LoggerState.replaceLog(this.logKeys.updateProgramRoles, {
            type: 'success',
            message: 'Roles successfully updated!',
        });

    }

}

export default new AccountUsers();