import React, { useState } from 'react';
// import { Button } from '@fifth-gear-tech/common-components/dist/components'
// import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import PropTypes from 'prop-types';
import RolesPicker from '../RolesPicker';
import AccountUsersState from '../../state/account_users';
import AuthState from '../../state/auth';
import './UserRoleEditor.scss';
import { ROLES_ARRAY } from '../../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const UserRolesEditor = ({ user, roles, onSaveClick, title, disableTitle, displayRoles = true, productName }) => {
    logger.extra();
    const [internalRoles, setRoles] = useState(roles && displayRoles ? roles.map((role) => ROLES_ARRAY.find((ra) => ra.id === role.role_id)) : []);
    const isSameUser = AuthState.creds.user.uId === user.id;
    const disabledRoles = [];

    for (let i = 0; i < internalRoles.length; i++) {
        const role = internalRoles[i];
        const isAdminRole = role.name === 'Admin';
        const hasOtherAdmins = AccountUsersState.users.findIndex((usr) => {
            const hasAdminRole = usr.roles.findIndex((r) => r.role.name === 'Admin') > -1;
            const isDifferentUser = usr.id !== AuthState.creds.user.uId;

            return hasAdminRole && isDifferentUser;
        }) > -1;

        if (isSameUser && isAdminRole && !hasOtherAdmins) {
            disabledRoles.push(role);
        }
    }

    return (
        <div className={'UserRoleEditor'}>
            <RolesPicker
                label={disableTitle ? '' : title ? title : 'Edit User Company Roles:'}
                roles={internalRoles}
                setRoles={async (roles) => {
                    try {
                        if (onSaveClick) {
                            await onSaveClick(roles, roles);
                        }
                        setRoles(roles);
                    } catch (err) {
                        setRoles([]);
                    }
                }}
                disabledRoles={disabledRoles}
                rolesType={productName == 'SPEEDfolder' ? 'ads' : undefined}
            />
        </div>
    );
};

UserRolesEditor.propTypes = {
    user: PropTypes.object,
    roles: PropTypes.array,
    onSaveClick: PropTypes.func,
    title: PropTypes.string,
    disableTitle: PropTypes.bool,
    displayRoles: PropTypes.bool,
    productName: PropTypes.string
};

export default UserRolesEditor;