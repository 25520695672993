import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import ProductsState from './products';
import AccountUsersState from './account_users';
import { INTERNAL_API_BASE } from '../utils/constants';
import { calcPrice, parseAmount, parseRecurringInterval } from '../helpers/stripe/price';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class Subscriptions {
    subscriptions = [];

    subscriptionsLoading = true;

    constructor() {
        logger.extra();
        makeObservable(this, {
            subscriptions: observable,
            subscriptionsLoading: observable,
            addSubscription: action,
            fetchSubscriptions: action,
            updateUserLimit: action
        }, { deep: true });

        ProductsState.fetchProducts();
    }

    addSubscription(subscription) {
        logger.extra();
        this.subscriptions.push(subscription);
    }

    async fetchSubscriptions() {
        logger.extra();
        this.subscriptionsLoading = true;
        try {
            const token = await AuthState.getToken();

            const response = await request('GET', `${INTERNAL_API_BASE}/private/subscription`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { customer_program_subscriptions } = response.body;

            this.subscriptions = customer_program_subscriptions;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('subscriptions', {
                type: 'error',
                message: `Fetch subscriptions error: ${e.message}`,
            });
        }

        this.subscriptionsLoading = false;
    }

    buildExternalPrice(subscription) {
        logger.extra();
        // const { external_id } = subscription;
        logger.extra('subscription', subscription);
        // const subscription = this.subscriptions.find(sub => sub.external_id === external_id);

        if (!subscription) {
            return null;
        }

        const { program, external_subscription } = subscription;

        if (!program || !external_subscription) {
            return null;
        }

        const programItem = external_subscription.items.data.find((itm) => itm.price.product === program.external_id);

        logger.extra('PROGRAM:', programItem, ProductsState.products.length);

        // find price Object from Products
        const product = ProductsState.products.find((pdct) => {
            logger.extra('PRODUCTS FIND:', pdct);
            return pdct.external_product.id === program.external_id;
        });

        logger.extra('PRODUCT:', product);

        if (!product) {
            return null;
        }

        const price = product.external_product.prices.find((prc) => prc.id === programItem?.price?.id);

        if (!price) {
            return null;
        }

        const { subtotal } = calcPrice({ price, quantity: programItem.quantity });

        return {
            price: parseAmount({ unit_amount: subtotal }),
            interval: parseRecurringInterval({ price: programItem.price }),
        };
    }

    updateUserLimit = async ({ userId, password, userLimit, subscriptionItemId, programId, roles }) => {
        logger.extra({ userId, password, userLimit, subscriptionItemId, programId, roles });

        // get logged in user from auth state
        const { creds: { user: { username } } } = AuthState;

        // authenticate user
        try {
            await request('POST', `${INTERNAL_API_BASE}/public/login`)
                .set('Content-Type', 'application/json')
                .send({ password, username });
        } catch (err) {
            if (err.status == 300) {
                // valid username and password- do nothing :)
            } else {
                logger.error(err);
                throw err;
            }
        }

        // hit subscription api
        const token = await AuthState.getToken();
        await request('PUT', `${INTERNAL_API_BASE}/private/subscription/item`)
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${token}`)
            .send({ user_limit: userLimit, subscriptionItemId });

        // if user is sending roles
        if (roles?.length > 0 && userId && programId) {
            await AccountUsersState.updateProgramRoles({ user_id: userId, program_id: programId, roles });
        }
    }

}

export default new Subscriptions();