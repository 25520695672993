import './Account.scss';

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';


import AccountSettings from './AccountSettings';
import AccountDrawer from './AccountDrawer';
import Subscriptions from './Subscriptions';
import AccountIntegrations from './Integrations';
import AccountUsers from './AccountUsers';
import TransactionHistory from './TransactionHistory';
import Components from './Components';

import { isAdmin, isSuper } from '../../utils/auth';
import RolesState from '../../state/roles';
import SearchableUsersState from '../../state/searchable_users';
import colors from '../../theme/colors';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const useQuery = () => new URLSearchParams(useLocation().search);


const drawerWidth = 240;
const useStyles = makeStyles((theme) => (
    {
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: drawerWidth
            },
            flexShrink: 0,
        },
        drawerPaper: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: drawerWidth
            },
        },
        drawerContainer: {
            overflow: 'auto',
        },
        contentWrapper: {
            flexGrow: 1,
            backgroundColor: colors.monotone[600],
            minHeight: '100vh',
        },
        content: {
            padding: theme.spacing(3),
        }
    }
));

const Account = observer(() => {
    logger.extra();
    const classes = useStyles();
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    let query = useQuery();

    useEffect(() => {
        logger.extra('FETCH ROLES AND SEARCHABLE USERS');
        RolesState.fetch();
        if (isAdmin()) {
            SearchableUsersState.fetch();
        }
    }, []);

    return (
        <div className={classes.root}>
            <AccountDrawer
                activeTab={Number(query.get('activeTab'))}
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
                isOpen={drawerIsOpen}
                onClose={() => setDrawerIsOpen(!drawerIsOpen)}
            />
            <div className={classes.contentWrapper} >
                <Toolbar />
                <TabPanel
                    index={0}
                    activeTab={Number(query.get('activeTab'))}
                >
                    <AccountSettings />
                </TabPanel>

                {isAdmin() && (
                    <TabPanel
                        index={1}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Account Managers'}
                    >
                        <AccountUsers />
                    </TabPanel>
                )}
                {isAdmin() && (
                    <TabPanel
                        index={2}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Program Subscriptions'}
                    >
                        <Subscriptions />
                    </TabPanel>
                )}
                {isAdmin() && (
                    <TabPanel
                        index={3}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Components'}
                    >
                        <Components />
                    </TabPanel>
                )}
                {isAdmin() && (
                    <TabPanel
                        index={4}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Integration Settings'}
                    >
                        <AccountIntegrations />
                    </TabPanel>
                )}
                {isAdmin() && (
                    <TabPanel
                        index={5}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Transaction History'}
                    >
                        <TransactionHistory />
                    </TabPanel>
                )}
                {isSuper() && (
                    <TabPanel
                        index={6}
                        activeTab={Number(query.get('activeTab'))}
                        tabHeader={'Speedsuite Administrator'}
                    >
                        <Redirect to='/super' />
                    </TabPanel>
                )}
            </div>
        </div>
    );

});

const TabPanel = ({ index, activeTab, children }) => {
    logger.extra();
    const classes = useStyles();

    return (
        <div className={classes.contentWrapper} hidden={(index !== activeTab)}>
            {index === activeTab && (
                <>
                    <div className={classes.content}>
                        {children}
                    </div>
                </>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    index: PropTypes.number,
    activeTab: PropTypes.number,
    children: PropTypes.node.isRequired
};

export default Account;