import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';

import Customers from './Customers';
import Customer from './Customer';
import { DashboardPanel, DrawerTab } from '../../../components/Dashboard';
import { observer } from 'mobx-react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export const CustomersTab = ({ index }) => (
    <DrawerTab index={index}>
        <Typography variant='subtitle3'>Companies</Typography>
    </DrawerTab>
);

CustomersTab.propTypes = {
    index: PropTypes.number.isRequired,
};

export default observer(({ index, customers }) => {
    logger.extra();
    // eslint-disable-next-line
	const [breadCrumb, setBreadCrumb] = useState(null);

    const match = useRouteMatch();
    return (
        <DashboardPanel
            index={index}
        >
            <Switch>
                <Route path={`${match.url}/c/:customer_id`} exact={true}>
                    <Customer crumbUpdate={setBreadCrumb} />
                </Route>
                <Route path={`${match.url}`} exact={true}>
                    <Customers customers={customers} />
                </Route>
            </Switch>

        </DashboardPanel>
    );

});