import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, FormSection, LocalAlert } from '@fifth-gear-tech/common-components/dist/components';
import { signupFormSelector, set, handleInput } from '../../features/signupForm/signupFormSlice';

const GranQuartzSalesRepDetails = () => {
    // --- redux state ---
    const dispatch = useDispatch();
    const signupForm = useSelector(signupFormSelector);
    const { repFirstName, repLastName } = signupForm;

    // listen to rep details and set validity
    useEffect(() => {
        if (repFirstName.length > 0 && repLastName.length > 0) {
            dispatch(set({ name: 'repDetailsValid', val: true }));
        } else {
            dispatch(set({ name: 'repDetailsValid', val: false }));
        }
    }, [repFirstName, repLastName]);


    // --- return component ---
    return (
        <FormSection label={'GranQuartz Representative'} subtext={'Please enter your representative\'s details.'}>

            <LocalAlert active={!repFirstName} message={'Missing'} />
            <InputField
                // aesthetic
                label={'First Name:'}
                placeholder={'First Name'}
                clearIcon={false}
                // entry
                value={repFirstName}
                name={'repFirstName'}
                onChange={(e) => dispatch(handleInput(e.target))}
                // validation
                isValid={!!repFirstName}
            />

            <LocalAlert active={!repLastName} message={'Missing'} />
            <InputField
                // aesthetic
                label={'Last Name:'}
                placeholder={'Last Name'}
                clearIcon={false}
                // entry
                name={'repLastName'}
                value={repLastName}
                onChange={(e) => dispatch(handleInput(e.target))}
                // validation
                isValid={!!repLastName}
            />

        </FormSection>
    );
};

export default GranQuartzSalesRepDetails;