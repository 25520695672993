import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { DashboardPanel, DrawerTab } from '../../../components/Dashboard';

import ChargesTable from '../../../components/ChargesTable';

import SuperChargesHistory from '../../../state/super_charges_history';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export const TransactionHistoryTab = ({ index }) => (
    <DrawerTab index={index} >
        <Typography variant='subtitle3' >Invoice History</Typography>
    </DrawerTab>
);

TransactionHistoryTab.propTypes = {
    index: PropTypes.number.isRequired,
};

const TransactionHistory = observer(({ index }) => {
    logger.extra();
    const [{ charges, chargesLoading }] = useState(() => SuperChargesHistory);

    useEffect(() => {
        if (SuperChargesHistory.charges.length === 0) {
            SuperChargesHistory.fetch();
        }
    }, []);

    return (
        <DashboardPanel
            index={index}
        >
            <ChargesTable
                charges={charges}
                loading={chargesLoading}
                enableCustomerLink={true}
            />
        </DashboardPanel>
    );
});

export default TransactionHistory;