/* const couponObj = {
"id": "Z4OV52SU",
"object": "coupon",
"amount_off": null,
"created": 1637292290,
"currency": "usd",
"duration": "repeating",
"duration_in_months": 3,
"livemode": false,
"max_redemptions": null,
"metadata": {},
"name": "25.5% off",
"percent_off": 25.5,
"redeem_by": null,
"times_redeemed": 0,
"applies_to": { products: ['stripe_product_id']},
"valid": true
};*/

const { parseAmount } = require('./price');

const isProductSpecific = ({ coupon }) => !!coupon.applies_to;

const hasDiscount = ({ product_id, coupon }) => {
    if (!coupon.applies_to) {
        return false;
    }
    return coupon.applies_to.products.includes(product_id);
};

const transformCoupon = (coupon) => {
    const { duration, duration_in_months, amount_off, percent_off } = coupon;

    let discount_value = '';
    let discount_string = 'off';
    let duration_string = '';

    if (amount_off !== null) {
        discount_value = `${parseAmount({ unit_amount: amount_off })}`;
    } else if (percent_off !== null) {
        discount_value = `${percent_off}%`;
    }

    switch (duration) {
        case 'repeating':
            duration_string = `per month for ${duration_in_months} month${duration_in_months > 1 ? 's' : ''}`;
            break;
        default:
            duration_string = duration;
            break;
    }

    return {
        discount_value,
        discount_string,
        duration_string,
        coupon,
    };
};

const calcCouponDiscount = ({ coupon, value }) => {
    const { amount_off, percent_off } = coupon;
    const subtotal = amount_off ? value - amount_off : value - (value * (percent_off / 100));

    return subtotal > 0 ? subtotal : 0;
};

module.exports = {
    isProductSpecific,
    hasDiscount,
    transformCoupon,
    calcCouponDiscount,
};