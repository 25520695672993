import { makeObservable, observable } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE } from '../utils/constants';
// import user from './user';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SearchableUsers {
    users = [];

    entireUserBase = []; // separating state so other components don't cause side effects

    constructor() {
        logger.extra();
        makeObservable(this, {
            users: observable,
            entireUserBase: observable,
            fetch: observable,
            superFetchAll: observable,
        });
    }

    async fetch() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${AuthState.creds.user.cId}/users`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { users } = res.body;

            this.users = users.map((user) => ({
                ...user,
                label: `${user.username} -- ${user.first_name} ${user.last_name} -- ${user.email}`,
                value: user.id,
            }));
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
            LoggerState.addLog('searchableUsers', {
                type: 'error',
                message: `Fetch searchable users error: ${e}`,
            });
            logger.error('FETCH SEARCHABLE USERS ERROR', e);
        }
    }

    async superFetchAll() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/users/listAll`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { users } = res.body;

            this.entireUserBase = users.map((user) => ({
                ...user,
                label: `${user.username} -- ${user.first_name} ${user.last_name} -- ${user.email}`,
                value: user.id,
            }));

        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
            LoggerState.addLog('searchableUsers', {
                type: 'error',
                message: `Fetch searchable users error: ${e}`,
            });
            logger.error('FETCH SEARCHABLE USERS ERROR', e);
        }
    }

    async create(userData) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/super/user`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(userData);

            const { user } = res.body;

            this.users.push({
                ...user,
                label: user.username,
                value: user.id,
            });

            LoggerState.addLog('searchableUsers', {
                type: 'success',
                message: 'Successfully Created User',
            });

        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('searchableUsers', {
                type: 'error',
                message: `Error creating user: ${e}`,
            });
        }
    }

    async update(userData) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('PUT', `${INTERNAL_API_BASE}/private/super/user/${userData.id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(userData);

            const { user } = res.body;
            const userIndex = this.users.findIndex((usr) => usr.id === user.id);
            const nUser = {
                ...user,
                label: user.username,
                value: user.id,
            };

            if (userIndex > -1) {
                this.users.splice(userIndex, 1, nUser);
            } else {
                this.users.push(nUser);
            }

            LoggerState.addLog('searchableUsers', {
                type: 'success',
                message: 'User Updated!'
            });
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('searchableUsers', {
                type: 'error',
                message: `Error Updating User: ${e}`,
            });
        }
    }

}

export default new SearchableUsers();