import './AppHeader.scss';

// - package imports
import React from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';

// - UI library imports
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { NavigationDropdownMenu } from '@fifth-gear-tech/common-components/dist/components';
import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { ArrowTopRightIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';

// - state imports
import { hasAuth } from '../../utils/auth';
import AuthState from '../../state/auth';
import { useWindowSize } from '../../hooks';

import { ENVIRONMENT } from '../../utils/constants';
import PropTypes from 'prop-types';

import FifthGearTechLogo from '../FifthGearTechLogo';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const AppHeader = observer(() => {
    logger.extra();
    const { /* height,*/ width } = useWindowSize();
    const history = useHistory();

    logger.extra('HAS AUTH:', hasAuth());

    const generateNav = () =>
        width <= 860 ?
            <>
                {hasAuth() ?
                    <NavigationDropdownMenu
                        colorTheme={Colors.Grey}
                        icon={<HamburgerMenuIcon />}
                        contents={[{ label: 'SPEEDfolder', right: <ArrowTopRightIcon />, callback: () => window.location.href = '/app/speedfolder' }]}
                        supportCallback={() => {
                            window.open('mailto: support@fifthgeartech.com', '_blank');
                        }}
                        loginCallback={false}
                        dashboardCallback={() => history.push('/account')}
                        logoutCallback={() => AuthState.logout()}
                    />

                    :
                    <NavigationDropdownMenu
                        colorTheme={Colors.Grey}
                        icon={<HamburgerMenuIcon />}
                        contents={[{ label: 'SPEEDfolder', right: <ArrowTopRightIcon />, callback: () => window.location.href = '/app/speedfolder' }]}
                        supportCallback={() => {
                            window.open('mailto: support@fifthgeartech.com', '_blank');
                        }}
                        loginCallback={() => {
                            history.push('/login');
                        }}
                    />
                }
            </>
            :
            <nav className={'link-container'}>
                {ENVIRONMENT !== 'production' && (
                    <HeaderLink to={'/app/speedcad'} external target="_blank" >SPEEDcad</HeaderLink>
                )}
                <HeaderLink to={'/app/speedfolder'} external target="_blank" >SPEEDfolder</HeaderLink>
                <HeaderLink to={'/app/speedlabel'} external target="_blank" >SPEEDlabel</HeaderLink>
                {hasAuth() ?
                    <HeaderLink to={'/account'}>
                        <PersonOutlineIcon />
                        Fifth Gear Dashboard
                    </HeaderLink>
                    :
                    <HeaderLink to={'/login'}>
                        <ExitToAppOutlinedIcon />
                        Fifth Gear Sign In
                    </HeaderLink>
                }
            </nav>;

    const HeaderLink = ({ to, children, external = false, ...rest }) => (
        <>
            {external ?
                <a href={to} {...rest} >{children}</a>
                :
                <Link to={to} {...rest} >
                    {children}
                </Link>
            }
        </>
    );

    HeaderLink.propTypes = {
        to: PropTypes.string,
        children: PropTypes.node,
        external: PropTypes.bool,
    };

    return (
        <AppBar classes={{ root: 'AppBar' }} position='fixed'>
            <Toolbar className="AppToolbar">

                <div className={'logo-container'}>
                    <Link to="/" className='cursor-pointer nostyle'>
                        <FifthGearTechLogo />
                    </Link>
                </div>
                {generateNav()}
            </Toolbar>
        </AppBar>
    );

});


export default AppHeader;