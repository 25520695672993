import './SignIn.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Logger from '@fgt/common/utils/logger';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { SignInForm } from '@fifth-gear-tech/common-components/dist/components';

import AuthState from '../../state/auth';

const logger = new Logger(__filename);

const SignIn = observer(() => {
    logger.extra();


    const handleIfRedirect = () => {

        // Create a URLSearchParams object from the current URL
        const searchParams = new URLSearchParams(window.location.search);

        // Get the 'redirect' query parameter
        const redirectUrl = searchParams.get('redirect');

        // Check if the redirect URL exists
        if (redirectUrl) {
            // Redirect the window to the redirect URL
            window.location.href = redirectUrl;
        } else {
            logger.warn('Redirect URL not found in query parameters. Using default behavior.');
        }
    };

    return (
        <div className='HomeHeader homeHeaderBG'>
            <Toolbar />
            <div className={'signInArea'} >
                <div className={'formArea'}>
                    <SignInForm
                        fullWidth
                        // disableCard={true}
                        onLoginSuccess={() => {
                            handleIfRedirect();
                            AuthState.refreshLogin();
                        }}
                    />
                </div>
                <center>
                    <div className='homeLogoRegion' >
                        <h2>New To Fifth Gear?</h2>
                        <div className='buttonArea'>
                            <a href="https://speedlabel.net" className='nostyle' target='_blank' rel="noreferrer">
                                {/* <Button size='large' variant='outlined' fullWidth> Learn More </Button> */}
                            </a>
                            <Link to='/signup' className='nostyle'>
                                <Button size='large' variant='contained' fullWidth> Enroll Company</Button>
                            </Link>
                        </div>
                    </div>
                </center>
            </div>
        </div>
    );
});

export default SignIn;