import './GranQuartzOnboarding.scss';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Card, Button } from '@fifth-gear-tech/common-components/dist/components';

import Logger from '@fgt/common/utils/logger';

import ModalState from '../../state/modal';
import { stIcon } from '../../assets';
import SignupForm from '../../features/signupForm/SignupForm';
import CartState from '../../state/cart';
import ProductsState from '../../state/products';
import { hasAuth } from '../../utils/auth';

import { STRIPE_SPEEDTEMPLATE_PRODUCT_ID } from '../../utils/constants';

const logger = new Logger(__filename);

const GranQuartzOnboarding = () => {
    const history = useHistory();

    const [productList, setProductList] = useState([]);
    const [enrolled, setEnrolled] = useState(hasAuth());

    const showSignUpForm = async () => {
        hasAuth() ? await ProductsState.fetchProducts() : ModalState.open(<SignupForm granQuartz={true} signUpSuccess={() => setEnrolled(true)} />);
    };

    // show the sign up form on page load
    useEffect(async () => {
        await ProductsState.fetchProducts();
        setProductList(ProductsState.products);
        showSignUpForm();
    }, []);

    // add the product to the cart when the user is enrolled and the product list is available
    useEffect(() => {
        logger.extra({ enrolled, productList });

        if (!enrolled || productList.length <= 0) {
            return;
        }
        CartState.emptyCart();
        const product = productList.find((p) => p.external_product_id == STRIPE_SPEEDTEMPLATE_PRODUCT_ID);
        const price = product.external_product.prices.find((p) => p.id == product.external_product?.default_price);
        CartState.addItem({ product, price, qty: 1 });
        history.push('/cart');
        ModalState.close();
    }, [enrolled, productList]);


    return (
        <div className='GranQuartzOnboarding'>
            <Card >

                <h3>Sign Up For SPEEDtemplate</h3>

                <img src={stIcon} width={'450rem'} alt='speedtemplate-banner' className='SpeedtemplateIcon' />

                <div className='SignUpButton'>
                    <Button
                        label={'Get SPEEDTemplate'}
                        type={ButtonType.Add}
                        onClick={() => showSignUpForm()}
                    />
                </div>

            </Card >
        </div>);

};

export default GranQuartzOnboarding;