export const ROLES = {
    SUPER: { id: 1, name: 'Super', cost: 0 },
    ADMIN: { id: 2, name: 'Admin', cost: 0 },
    GRANT: { id: 3, name: 'Grant', cost: 0 },
    DELETE: { id: 4, name: 'Delete', cost: 0 },
    WRITE: { id: 5, name: 'Write', cost: 0 },
    READ: { id: 6, name: 'Read', cost: 0 },
    ADVERTISE: { id: 7, name: 'Advertise', cost: 0 },
};

export const ROLES_ARRAY = [ROLES.SUPER, ROLES.ADMIN, ROLES.GRANT, ROLES.DELETE, ROLES.WRITE, ROLES.READ, ROLES.ADVERTISE];

let _ENVIRONMENT = 'development';

let _INTERNAL_API_BASE = `http://${window.location.hostname}:8080/api`;
let _SPEEDLABEL_API_BASE = `http://${window.location.hostname}:8081/api`;
let _SPEEDFOLDER_API_BASE = `http://${window.location.hostname}:8082/api`;

let _SPEEDFOLDER_APP_BASE = `http://${window.location.hostname}:3002/app/speedfolder`;

// external subscriptions
let _STRIPE_SPEEDLABEL_PRODUCT_ID = 'prod_JIQPQr22xggP28';
let _STRIPE_SPEEDCAD_PRODUCT_ID = 'prod_JTer80si61KdlE';
let _STRIPE_SPEEDFOLDER_PRODUCT_ID = 'prod_M34oZzPkm6aOpV';
let _STRIPE_SPEEDTEMPLATE_PRODUCT_ID = 'prod_P64WNMpI8aQyCg';

// printer, ink, starter kit product ids
let _STRIPE_PRINTER_PRODUCT_ID = 'prod_JzTHbRPj0HmHBI';
let _STRIPE_INK_PRODUCT_ID = 'prod_PLuex9V5O09wMQ';
let _STRIPE_STARTER_PRODUCT_ID = 'prod_JzTdIhe0YvnBV9';

let _AUTH0_DOMAIN = 'dev-oedlhkrn.us.auth0.com';
let _AUTH0_CLIENT_ID = 'D9iY6wVuvMXtqQ6yD6rpN7TZr43QvhoG';
let _AUTH0_REDIRECT_URI = 'http://localhost:3000';
let _AUTH0_AUDIENCE = 'http://localhost:8080';

(() => {
    switch (window.location.hostname) {
        case 'www.staging.fifthgeartech.com':
            _ENVIRONMENT = 'staging';

            _INTERNAL_API_BASE = 'https://subscription-api.staging.fifthgeartech.com/api';
            _SPEEDLABEL_API_BASE = 'https://speedlabel-web-api.staging.fifthgeartech.com/api';
            _SPEEDFOLDER_API_BASE = 'https://speedfolder-web-api.staging.fifthgeartech.com/api';

            _SPEEDFOLDER_APP_BASE = 'https://www.staging.fifthgeartech.com/app/speedfolder/';

            _AUTH0_DOMAIN = 'dev-oedlhkrn.us.auth0.com';
            _AUTH0_CLIENT_ID = 'gBZGhu4Rl13bInWbbYI0VnxJkzBBsxtC';
            _AUTH0_REDIRECT_URI = 'https://staging.fifthgeartech.com';
            _AUTH0_AUDIENCE = 'https://subscription-api.staging.fifthgeartech.com';
            break;
        case 'www.fifthgeartech.com':
            _ENVIRONMENT = 'production';

            _INTERNAL_API_BASE = 'https://subscription-api.fifthgeartech.com/api';
            _SPEEDLABEL_API_BASE = 'https://speedlabel-web-api.fifthgeartech.com/api';
            _SPEEDFOLDER_API_BASE = 'https://speedfolder-web-api.fifthgeartech.com/api';

            _SPEEDFOLDER_APP_BASE = 'https://www.fifthgeartech.com/app/speedfolder/';

            _STRIPE_SPEEDLABEL_PRODUCT_ID = 'prod_K5qwLrFO09WT83';
            _STRIPE_SPEEDCAD_PRODUCT_ID = 'prod_KWydoFwXTeYCDv';
            _STRIPE_SPEEDFOLDER_PRODUCT_ID = 'prod_M355GKUTCvCSoX';
            _STRIPE_SPEEDTEMPLATE_PRODUCT_ID = 'prod_PKqnp1CuwoqqfZ';

            _STRIPE_PRINTER_PRODUCT_ID = 'prod_NBhXPiowM7F5Ab';
            _STRIPE_INK_PRODUCT_ID = 'prod_KurBtTR5lgRM48';
            _STRIPE_STARTER_PRODUCT_ID = 'prod_K5qy7Tk72KAzxn';

            _AUTH0_DOMAIN = 'dev-oedlhkrn.us.auth0.com';
            _AUTH0_CLIENT_ID = 'gGICKh68P7pLvuH1D8HzQqENdWQa9gNQ';
            _AUTH0_REDIRECT_URI = 'https://fifthgeartech.com';
            _AUTH0_AUDIENCE = 'https://subscription-api.fifthgeartech.com';
            break;
        default:
            break;
    }
})();


export const ENVIRONMENT = _ENVIRONMENT;

export const INTERNAL_API_BASE = _INTERNAL_API_BASE;
export const SPEEDLABEL_API_BASE = _SPEEDLABEL_API_BASE;
export const SPEEDFOLDER_API_BASE = _SPEEDFOLDER_API_BASE;

export const SPEEDFOLDER_APP_BASE = _SPEEDFOLDER_APP_BASE;

export const STRIPE_SPEEDLABEL_PRODUCT_ID = _STRIPE_SPEEDLABEL_PRODUCT_ID;
export const STRIPE_SPEEDCAD_PRODUCT_ID = _STRIPE_SPEEDCAD_PRODUCT_ID;
export const STRIPE_SPEEDFOLDER_PRODUCT_ID = _STRIPE_SPEEDFOLDER_PRODUCT_ID;
export const STRIPE_SPEEDTEMPLATE_PRODUCT_ID = _STRIPE_SPEEDTEMPLATE_PRODUCT_ID;

export const STRIPE_PRINTER_PRODUCT_ID = _STRIPE_PRINTER_PRODUCT_ID;
export const STRIPE_INK_PRODUCT_ID = _STRIPE_INK_PRODUCT_ID;
export const STRIPE_STARTER_PRODUCT_ID = _STRIPE_STARTER_PRODUCT_ID;

export const AUTH0_DOMAIN = _AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = _AUTH0_CLIENT_ID;
export const AUTH0_REDIRECT_URI = _AUTH0_REDIRECT_URI;
export const AUTH0_AUDIENCE = _AUTH0_AUDIENCE;