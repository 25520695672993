import './Components.scss';
import React from 'react';
import { Card } from '@fifth-gear-tech/common-components/dist/components';
import { EmailOutlined, PhoneOutlined } from '@material-ui/icons';


const Components = () => (
    <section className={'Components'}>
        <h4>Request Refills</h4>
        <Card className={'Card'}>
            <a href='mailto:support@fifthgeartech.com'>
                <EmailOutlined fontSize='large' />
                <br />
					support@fifthgeartech.com
            </a>
        </Card>

        <Card className={'Card'}>
            <a href='tele:+17085052812'>
                <PhoneOutlined fontSize='large' />
                <br />
					(708) 505-2812
            </a>
        </Card>
    </section >
);

export default Components;