import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export const formatPhoneNumber = (phoneNumberString) => {
    if (phoneNumberString.startsWith('+')) {
        return phoneNumberString;
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    logger.debug(cleaned, { depth: null });
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
};