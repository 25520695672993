import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperCustomerSubscriptions {
    customer_id = null;

    subscriptions = [];

    loading = false;

    constructor() {
        logger.extra();
        makeObservable(this, {
            customer_id: observable,
            subscriptions: observable,
            loading: observable,
            fetch: action,
        }, { deep: true });
    }

    async fetch(customer_id) {
        logger.extra();
        try {
            this.loading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/super/subscriptions/customer/${customer_id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { subscriptions } = res.body;

            this.subscriptions = subscriptions;
        } catch (e) {
            logger.error('FETCH CUSTOMER SUBSCRIPTIONS ERROR:', e);
            LoggerState.addLog('superCustomerSubscriptions', {
                type: 'error',
                message: `Fetch customer subscriptions error: ${e}`,
            });
        }

        this.loading = false;
    }

    // async add() {
    //
    // }

    async update() {

    }

    async delete() {

    }
}

export default new SuperCustomerSubscriptions();