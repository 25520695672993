import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperCustomer {
    customer = null;

    customer_id = null;

    chargesLoading = false;

    charges = [];

    constructor() {
        logger.extra();
        makeObservable(this, {
            customer_id: observable,
            customer: observable,
            charges: observable,
            chargesLoading: observable,
            fetch: action,
            fetchCharges: action,
            clear: action,
        }, {
            deep: true
        });
    }

    setCustomerId(customerId) {
        logger.extra();
        this.customer_id = customerId;
    }

    async fetch(customer_id) {
        logger.extra();
        this.setCustomerId(customer_id);

        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/super/customer/${this.customer_id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { customer } = res.body;

            this.customer = customer;
        } catch (e) {
            logger.error('HTTP ERROR:', e);

            LoggerState.addLog('superCustomer', {
                type: 'error',
                message: `Fetch customer error:${e}`,
            });
        }
    }

    async fetchCharges() {
        logger.extra();

        try {
            this.chargesLoading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/charges`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            this.charges = res.body.charges;
        } catch (e) {
            logger.error('FETCH INVOICES ERROR');
        }

        this.chargesLoading = false;
    }

    async updateCustomer(customer) {
        logger.extra();

        try {
            const token = await AuthState.getToken();
            await request('PUT', `${INTERNAL_API_BASE}/private/super/customer/${customer.id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(customer);

            // this.customer = res.body.customer;
            await this.fetch(customer.id);
        } catch (e) {
            logger.error('UPDATE CUSTOMER ERROR');
        }
    }

    clear() {
        logger.extra();
        this.customer_id = null;
        this.customer = null;
        this.charges = [];
    }

}

export default new SuperCustomer();