import './TransactionHistory.scss';

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import CustomerState from '../../../state/customer';
import ChargesTable from '../../../components/ChargesTable';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);

const TransactionHistory = observer(() => {
    const [{ charges, chargesLoading }] = useState(() => CustomerState);

    useEffect(() => {
        logger.extra();
        if (CustomerState.charges.length === 0) {
            CustomerState.fetchCharges();
        }
    }, []);

    return (
        <ChargesTable
            charges={charges}
            loading={chargesLoading}
        />
    );
});

export default TransactionHistory;
