import './PasswordResetRequestForm.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormSection, FormContainer, Button, InputField } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const PasswordResetRequest = ({ disableCard = false, ...props }) => {
    logger.extra();
    if (disableCard === false) {
        return (
            <PasswordResetRequestForm {...props} />
        );
    }

    return <PasswordResetRequestForm {...props} />;
};

const PasswordResetRequestForm = ({ customHeader, onSubmit, customLinkArea }) => {
    logger.extra();
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        logger.extra();
        e.preventDefault();
        onSubmit(e, { email });
    };

    return (
        <div className='PasswordResetRequestForm'>
            <FormContainer title='Reset Password' onSubmit={handleSubmit} >
                {!customHeader && (
                    <h5>Enter Username and Email</h5>
                )}

                {!!customHeader && customHeader}

                <FormSection
                    label={'Enter Account Email'}
                    subtext={'Enter the email associated with the account to receive a one time reset code.'}
                >
                    <InputField
                        label='Email:'
                        placeholder='Email'
                        clearIcon={null}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormSection>

                <div className={'ButtonArea'}>
                    <Button
                        label='Send Reset'
                        type={ButtonType.Orange}
                        form={true}
                    />
                </div>

                {!!customLinkArea && (
                    <div>
                        <br />
                        <span>{customLinkArea}</span>
                    </div>
                )}
            </FormContainer>
        </div>
    );
};

PasswordResetRequest.propTypes = {
    disableCard: PropTypes.bool
};

PasswordResetRequestForm.propTypes = {
    customHeader: PropTypes.node,
    onSubmit: PropTypes.func,
    customLinkArea: PropTypes.node
};
export default PasswordResetRequest;