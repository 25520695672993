import { Link, useRouteMatch } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const DrawerTab = ({
    children,
    icon,
    index,
    ListItemProps,
    ListItemTexProps,
    TypographyProps,
}) => {
    logger.extra();
    const { path } = useRouteMatch();
    const classes = useStyles();

    return (
        <Link to={{
            pathname: path,
            search: `?activeTab=${index}`
        }} className={'nostyle'} >
            <ListItem button {...ListItemProps} >
                {icon && (
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText
                    disableTypography={true}
                    {...ListItemTexProps}
                    primary={(
                        <Typography
                            classes={{ root: classes.listItemTypography }}
                            variant='caption'
                            // autoCapitalize={false}
                            {...TypographyProps}
                        >{children}</Typography>
                    )}
                />
            </ListItem>
        </Link>
    );
};

DrawerTab.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.node, 
    index: PropTypes.number, 
    ListItemProps: PropTypes.object,
    ListItemTexProps: PropTypes.object,
    TypographyProps: PropTypes.object,
};

export default DrawerTab;