import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import isEmail from 'validator/lib/isEmail';

const emailExists = async (email) => {
    const res = await request('GET', `${INTERNAL_API_BASE}/public/email/exists`)
        .set('Content-Type', 'application/json')
        .query({ email });

    if (res?.body?.email) {
        return true;
    }
    return false;
};

const emailValid = (email) => isEmail(email);

const companyExists = async (company_id) => {
    const res = await request('GET', `${INTERNAL_API_BASE}/public/customer/exists`)
        .set('Content-Type', 'application/json')
        .query({ company_id });

    if (res?.body?.customer) {
        return true;
    }
    return false;
};

export default {
    emailExists,
    emailValid,
    companyExists
};