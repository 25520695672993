import { STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID } from '../utils/constants';

const basicProgramRole = [{ 'id': 3, 'name': 'Grant', 'cost': 0 }, { 'id': 4, 'name': 'Delete', 'cost': 0 }, { 'id': 5, 'name': 'Write', 'cost': 0 }, { 'id': 6, 'name': 'Read', 'cost': 0 }];

const generateProgramsBasicRoles = (subscriptions) => {
    const programRoles = {};

    for (const sub of subscriptions) {

        // if user is not subscribing to speedcad or speedtemplate provide basic program role
        if (![STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID].includes(sub.program.external_id)) {
            programRoles[sub.program_id] = basicProgramRole;
        }
    }

    return programRoles;
};

export default {
    basicProgramRole,
    generateProgramsBasicRoles
};