import { makeObservable, observable, action } from 'mobx';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class Modal {
    component = null;

    hidden = false;

    paperless = false;

    disableScrollLock = false;

    keepMounted = false;

    constructor() {
        logger.extra();
        makeObservable(this, {
            component: observable,
            hidden: observable,
            close: action,
            open: action,
            hide: action,
            show: action,
            toggleHidden: action,
        }, {
            deep: true
        });

    }

    close() {
        logger.extra();
        this.component = null;
        this.hide();
    }

    open(component, opts) {
        logger.extra();
        this.component = component;
        this.paperless = opts && opts.paperless ? opts.paperless : false;
        this.disableScrollLock = opts && opts.disableScrollLock ? opts.disableScrollLock : false;
        this.keepMounted = opts && opts.keepMounted ? opts.keepMounted : false;
        this.show();
    }

    hide() {
        logger.extra();
        this.hidden = true;
    }

    show() {
        logger.extra();
        this.hidden = false;
    }

    toggleHidden() {
        logger.extra();
        this.hidden = !this.hidden;
    }

}

export default new Modal();