const typography = {
    typography: {
        h1: {
            fontWeight: 'bold',
            fontSize: '4.30rem',
            letterSpacing: '0.09rem',
        },
        h2: {
            fontWeight: 'bold',
            fontSize: '3.15rem',
            letterSpacing: '0.03rem',
        },
        h3: {
            fontWeight: 'normal',
            fontSize: '2.36rem',
        },
        h4: { // - Title
            fontWeight: 'bold',
            fontSize: '1.77rem',
        },
        h5: { // - Subheading
            fontWeight: 'normal',
            fontSize: '1.33rem',
        },
        body1: { // - Body
            fontWeight: 'normal',
            fontSize: '1rem',
        },
        subtitle1: { // - Subtext
            fontWeight: 'bold',
            fontSize: '0.75rem',
            textTransform: 'uppercase',
        },
        subtitle2: { // - Subtext alt
            fontWeight: 'bold',
            fontSize: '0.75rem',
        },
        button: {

        }
    },
};

export default typography;