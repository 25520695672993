import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import CustomerSearchSelect from '../../../components/CustomerSearchSelect';
import CustomersTable from '../../../components/CustomersTable/CustomersTable';
import Fuse from 'fuse.js';
import { Card } from '@fifth-gear-tech/common-components/dist/components';
import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Redirect, useRouteMatch } from 'react-router-dom';
import './Customer.scss';

import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(({ customers }) => {
    logger.extra();

    const match = useRouteMatch();

    const [filteredCustomers, setFilteredCustomers] = useState(customers);
    const fuse = new Fuse(customers, { keys: ['name', 'internal_id'] });
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    useEffect(() => window.scrollTo(0, 0), []);
    useEffect(() => {
        logger.extra();
        setFilteredCustomers(customers);
    }, [customers]);

    const search = (searchVal) => fuse.search(searchVal).map((itm) => itm.item);

    if (selectedCustomer) {
        return <Redirect to={`${match.url}/c/${selectedCustomer.id}`} />;
    }

    return (
        <section className={'Customers'}>
            <div>
                <h4>Companies</h4>
                <div>
                    <CustomerSearchSelect
                        disableLabel
                        SelectProps={{
                            isClearable: true,
                            onInputChange: (opt, { action }) => {
                                logger.extra('OPT:', opt, typeof opt, opt === '', action);
                                if (opt && opt !== '') {
                                    setFilteredCustomers(search(opt));
                                }
                                if (opt === '' && action === 'input-change') {
                                    setFilteredCustomers(customers);
                                }
                            },
                            onChange: (selectedValue) => {
                                logger.extra('THIS IS THE SELECTED VALUE:', selectedValue);
                                if (selectedValue) {
                                    setFilteredCustomers(search(selectedValue.label));
                                }
                                if (!selectedValue) {
                                    setFilteredCustomers(customers);
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <Card colorTheme={Colors.Grey} className={'Customers--card'}>
                <CustomersTable
                    customers={filteredCustomers.length > 0 ? filteredCustomers : customers}
                    onSettingsClick={(e, customer) => setSelectedCustomer(customer)}
                />
            </Card>
        </section>
    );
});