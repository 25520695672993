import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ComputerIcon from '@material-ui/icons/Computer';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AdminSettings from '@material-ui/icons/VpnLock';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import { isAdmin, isSuper } from '../../utils/auth';
// import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import './Account.scss';

import AuthState from '../../state/auth';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const AccountDrawer = ({ activeTab, className, classes, isOpen, onClose }) => {
    const { isAuthenticated, logout } = useAuth0();

    logger.extra();
    const drawer = (
        <>
            <Hidden smDown >
                <Toolbar />
            </Hidden>
            <div className={'CurrentUserInfo'}>
                <div>
                    <div>
                        <h5 className='subtext'>{`${AuthState.creds.user.first_name} ${AuthState.creds.user.last_name}`}</h5>
                        <p className='subtext'>{AuthState.creds.user.email}</p>
                        <br />
                        <p>
							Company: {AuthState.creds.user.cName}
                            <br />
							ID: {AuthState.creds.user.iId}
                        </p>
                        <br />
                    </div>
                </div>
            </div>
            <Divider />
            <Tabs
                orientation='vertical'
                value={activeTab}
                variant='scrollable'
                scrollButtons='auto'
            >
                <Tab
                    classes={{ root: 'LinkFullWidth' }}
                    label={(
                        <TabLinkListItem
                            index={0}
                            icon={<AccountBoxIcon />}
                            onClick={onClose}
                        >Account Settings</TabLinkListItem>
                    )}
                />
                {isAdmin() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={1}
                                icon={<SupervisorAccountIcon />}
                                onClick={onClose}
                            >Manage Users</TabLinkListItem>
                        )}
                    />
                )}
                {isAdmin() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={2}
                                icon={<ComputerIcon />}
                                onClick={onClose}
                            >Subscriptions</TabLinkListItem>
                        )}
                    />
                )}
                {isAdmin() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={3}
                                icon={<AddShoppingCartIcon />}
                                onClick={onClose}
                            >Request Refills</TabLinkListItem>
                        )}
                    />
                )}
                {isAdmin() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={4}
                                icon={<AccountTreeIcon />}
                                onClick={onClose}
                            >Integration Settings</TabLinkListItem>
                        )}
                    />
                )}
                {isAdmin() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={5}
                                icon={<ReceiptIcon />}
                                onClick={onClose}
                            >Invoice History</TabLinkListItem>
                        )}
                    />
                )}
                {isSuper() && (
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                index={6}
                                icon={<AdminSettings />}
                                onClick={onClose}
                            >Fifth Gear Tech Administrator</TabLinkListItem>
                        )}
                    />
                )}
                <Tab
                    classes={{ root: 'LinkFullWidth' }}
                    label={(
                        <TabLinkListItem
                            icon={<ExitToAppIcon />}
                            ListItemProps={{
                                onClick: () => {
                                    AuthState.logout();
                                    if (isAuthenticated) {
                                        logout();
                                    }
                                    onClose();
                                }
                            }}
                            onClick={onClose}
                        >Logout</TabLinkListItem>
                    )}
                />
                <Hidden mdUp>
                    <hr />
                    <Tab
                        classes={{ root: 'LinkFullWidth' }}
                        label={(
                            <TabLinkListItem
                                icon={<CloseIcon />}
                                ListItemProps={{ onClick: () => onClose() }}
                            >Close Menu</TabLinkListItem>
                        )}
                    />
                </Hidden>
            </Tabs>
        </>
    );

    return (
        <>
            {/* Desktop Drawer*/}
            <Hidden
                smDown
            >
                <Drawer
                    variant='permanent'
                    anchor='left'
                    className={className}
                    classes={classes}
                    PaperProps={{
                        elevation: 1,
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            {/* Mobile Drawer*/}
            <Hidden
                mdUp
            >
                <Drawer
                    variant='temporary'
                    anchor='top'
                    open={isOpen}
                    className={className}
                    classes={classes}
                    PaperProps={{
                        elevation: 1,
                    }}
                    onClose={onClose}
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </>
    );
};

AccountDrawer.propTypes = {
    activeTab: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const TabLinkListItem = ({ index, ListItemProps, icon, ListItemTexProps, TypographyProps, children, ...rest }) => {
    logger.extra();
    const { url } = useRouteMatch();
    const listItem = (
        <ListItem button {...ListItemProps} >
            {icon && (
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText
                disableTypography={true}
                {...ListItemTexProps}
                primary={(
                    <Typography
                        classes={{ root: 'ListItemTypography' }}
                        variant='caption'
                        // autoCapitalize={false}
                        {...TypographyProps}
                    >{children}</Typography>
                )}
            />
        </ListItem>
    );

    if (!index && index !== 0) {
        return listItem;
    }

    return (
        <Link to={`${url}?activeTab=${index}`} className={'nostyle'} {...rest} >
            {listItem}
        </Link>
    );
};

TabLinkListItem.propTypes = {
    index: PropTypes.number,
    ListItemProps: PropTypes.object,
    icon: PropTypes.node,
    ListItemTexProps: PropTypes.object,
    TypographyProps: PropTypes.object,
    children: PropTypes.node.isRequired,
};

export default AccountDrawer;