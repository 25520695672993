import './index.css';

import React from 'react';
// import Logger from '@fgt/common/utils/logger';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import store from './app/store';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
// import * as swr from './serviceWorkerRegistration';
import { ENVIRONMENT } from './utils/constants';
import pkg from '../package.json';

if (ENVIRONMENT !== 'development') {
    Sentry.init({
        dsn: 'https://b0dce8aca85c438fa14039c827234f74@o966616.ingest.sentry.io/5932946',
        integrations: [new Integrations.BrowserTracing()],
        environment: ENVIRONMENT,
        release: pkg.version,
        tracesSampleRate: 1.0,
    });
}

// const logger = new Logger();

ReactDOM.render(<Router>
    <Provider store={store}>
        <App />
    </Provider>
</Router>, document.getElementById('root'));

// swr.register({
//     onSuccess: (registration) => {
//         logger.info({ registration });
//     },
//     onUpdate: (registration) => {
//         logger.info({ registration });

//         const { caches } = window;

//         if (caches) {
//             caches.keys()
//                 .then((keys) => {
//                     logger.info('Cache keys:', { keys });
//                     for (let key of keys) {
//                         logger.info('Removing cache:', key);
//                         caches.delete(key);
//                     }
//                 });
//         }

//         logger.info('Reloading app after cache clear.');
//         window.location.reload(true);
//     }
// });