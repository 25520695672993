import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IntegrationForm from './IntegrationForm';

import DataSourcesState from '../../../state/data_sources';
import ModalState from '../../../state/modal';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const IntegrationsTable = observer(({ dataSources }) => {
    logger.extra();
    return (
        <TableContainer className={'IntegrationTable'}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Integration Type</TableCell>
                        <TableCell>Url</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {dataSources.map((dataSource) => (<DataSourceRow dataSource={dataSource} key={'row'}/>))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

const DataSourceRow = observer(({ dataSource }) => {
    logger.extra();
    const typeNameIndex = DataSourcesState.integrationTypes.findIndex((it) => it.id === dataSource.type);

    useEffect(() => {
        logger.extra();
        // if(dataSource.type === '1' || dataSource.type === '2' ){
        DataSourcesState.fetchIntegrationStatus({ data_source: dataSource });
    }, []);

    return (
        <TableRow>
            <TableCell>{DataSourcesState.integrationTypes[typeNameIndex]?.type} v{dataSource.version}</TableCell>
            <TableCell >{dataSource.url}</TableCell>
            <TableCell >
                <Chip
                    className={`IntegrationStatus ${dataSource.status === 'active' ? '--active' : '--inactive'}`}
                    label={dataSource.status && dataSource.status !== 'N/A' ? dataSource.status : 'inactive'}
                />
            </TableCell>
            <TableCell >
                <IconButton onClick={() => {
                    ModalState.open(<IntegrationForm dataSource={dataSource} onSaveSuccess={() => ModalState.close()} />);
                }}><SettingsIcon /></IconButton>
                <IconButton onClick={() => {
                    DataSourcesState.removeDataSource({ type: dataSource.type });
                }}><DeleteIcon /></IconButton>
            </TableCell>
        </TableRow>
    );
});

export default IntegrationsTable;