import './PrintLog.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { observer } from 'mobx-react';
import { Card, InputField, Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import moment from 'moment';
import SuperPrintLogState from '../../state/super_print_log';
import SearchableSubscriptions from '../../state/searchable_subscriptions';
// import { STRIPE_SPEEDLABEL_PRODUCT_ID } from '../../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

// NOTE : This component will need modification before non super users can utilize this.
export default observer(() => {
    logger.extra();
    const [sortKey, setSortKey] = useState('name');
    const [{ printLog, startDate, endDate, customer_id }] = useState(() => SuperPrintLogState);
    const [{ subscriptions }] = useState(() => SearchableSubscriptions);
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    const zeroPrintArray = subscriptions.map(({ customer }) => {
        const printLogIndex = printLog.findIndex((pl) => customer.id === pl.id);

        if (printLogIndex > -1) {
            return null;
        } else {
            return {
                id: customer.id,
                name: customer.name,
                total: 0,
            };
        }
    });
    const zeroPrintCustomers = zeroPrintArray.filter((p) => p);
    const toSort = customer_id === null ? printLog.concat(zeroPrintCustomers) : printLog;
    const sorted = toSort.slice().sort((a, b) => {
        if (sortKey === 'name') {
            return a[sortKey].localeCompare(b[sortKey]);
        } else if (sortKey === 'total') {
            return a[sortKey] - b[sortKey];
        }
        return false;
    });

    useEffect(() => {
        if (SuperPrintLogState.customer_id === null && SearchableSubscriptions.subscriptions.length === 0) {
            SearchableSubscriptions.fetch();
        }
    }, []);

    const FetchButton = () => (
        <Button
            label='Fetch Print Log'
            disabled={!startDate || !endDate}
            type={ButtonType.Green}
            onClick={() => SuperPrintLogState.fetchPrintLog()}
        />
    );

    const jsonToCsv = (data) => {
        if (data?.length > 0) {
            return (
                Object.keys(data[0]).join(',') +
                '\n' +
                data.map((d) => Object.values(d).join(',')).join('\n')
            );
        }
    };

    const sortedWithDates = sorted.map((s) => ({ ...s, start: `${startMoment.format('MM / DD / YYYY / hh:mm')}`, end: `${endMoment.format('MM / DD / YYYY')}` }));

    return (
        <section>
            <h4>SPEEDlabel Print Log</h4>
            <div className='PrintLog--datepickers' >
                <div>
                    <InputField
                        label='Start Date'
                        type='datetime-local'
                        clearIcon={false}
                        value={startDate}
                        onChange={(e) => SuperPrintLogState.setStartDate(e.target.value)}
                    />
                    <InputField
                        label='End Date'
                        type='datetime-local'
                        clearIcon={false}
                        value={endDate}
                        onChange={(e) => SuperPrintLogState.setEndDate(e.target.value)}
                    />
                </div>
            </div>

            <div className='Buttons'>
                {/* fetch print logs button */}
                {FetchButton()}

                {/* download logs result as csv button */}
                {printLog?.length > 0 &&
                    <CSVLink className='Button CSV' data={jsonToCsv(sortedWithDates)} filename='print-logs.csv' >Download As CSV</CSVLink>
                }
            </div>

            <Card className={'PrintLog--card'} >
                <TableContainer className={'PrintLogTable'}>
                    <Table >

                        <TableHead>
                            <TableRow>
                                <TableCell onClick={() => setSortKey('name')} style={{ cursor: 'pointer' }}>Customer Name</TableCell>
                                <TableCell>Printing Period</TableCell>
                                <TableCell>Average Printed</TableCell>
                                <TableCell onClick={() => setSortKey('total')} style={{ cursor: 'pointer' }}>Inches Printed</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {startDate && endDate && printLog.length > 0 && sorted.length > 0 && sorted.map((logItem) => {

                                const getWeekdayCount = () => {
                                    let date = moment(startDate); // use a clone
                                    let days = endMoment.diff(date, 'days');
                                    let dayCount = 0;

                                    date = date.subtract(1, 'days');

                                    while (days > 0) {
                                        date = date.add(1, 'days');
                                        // decrease "days" only if it's a weekday.
                                        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                                            dayCount += 1;
                                        }

                                        days -= 1;
                                    }

                                    return Number(dayCount);
                                };

                                return (
                                    <TableRow key={'dataTable'}>
                                        <TableCell>
                                            <Link to={`/super/c/${logItem.id}`} class={'--customer_link'}>{logItem.name}</Link>
                                        </TableCell>
                                        <TableCell><strong>{startMoment.format('MM / DD / YYYY')}</strong> {startMoment.format('hh:mm')} → <strong>{endMoment.format('MM / DD / YYYY')}</strong> {moment(endDate).format('hh:mm')}</TableCell>
                                        <TableCell>{Math.round(logItem.total / getWeekdayCount())}</TableCell>
                                        <TableCell>{logItem.total} inches</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                    </Table>
                </TableContainer>
                {/* for some reason dillon needs two buttons for everything */}
                {printLog.length === 0 && (
                    <div className='defaultMessage' >
                        <p> Select your date ranges to fetch print log </p>
                    </div>
                )}
            </Card>
        </section >
    );
});