import './Subscriptions.scss';

import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

// import { Card, FormSection } from '@fifth-gear-tech/common-components/dist/components'

import SubscriptionsTable from '../../../components/SubscriptionsTable';

import SubscriptionsState from '../../../state/subscriptions';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const Subscriptions = observer(() => {
    logger.extra();
    const [subscriptionsState] = useState(() => SubscriptionsState);

    useEffect(() => {
        logger.extra();
        SubscriptionsState.fetchSubscriptions();
    }, []);

    return (
        <section className={'Subscriptions'}>

            <div>
                <h4>Subscriptions</h4>
            </div>

            <SubscriptionsTable subscriptions={subscriptionsState.subscriptions} />
        </section>
    );
});

export default Subscriptions;