import { makeObservable, action, observable } from 'mobx';
import { v4 as uuid } from 'uuid';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
export const logTypeEnum = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
};

class Logger {
    logs = {};

    constructor() {
        logger.extra();
        makeObservable(this, {
            logs: observable,
            addLog: action,
            removeLog: action,
            replaceLog: action,
            clearLog: action,
            clearAllLogs: action,
        }, { deep: true });
    }

    addLog(key, value) {
        logger.extra();
        if (!this.logs[key]) {
            this.logs[key] = [];
        }

        const nValue = {
            id: uuid(),
            ...value,
        };

        this.logs[key].push(nValue);

        if (!nValue.disableCloseTimer) {
            const displayInterval = setInterval(() => {
                this.removeLog(key, nValue);

                clearInterval(displayInterval);
            }, nValue.timer ? nValue.timer : 3000);
        }
    }

    removeLog() {
        logger.extra();
        this.logs = {};
    }

    replaceLog(key, value) {
        logger.extra();
        const nValue = {
            id: uuid(),
            ...value,
        };

        // const logExists = this.logs[key];
        this.logs[key] = [nValue];

        if (!nValue.disableCloseTimer) {
            const displayInterval = setInterval(() => {
                this.removeLog(key, nValue);

                clearInterval(displayInterval);
            }, 5000);
        }
    }

    clearLog(key) {
        logger.extra();
        this.logs[key] = [];
    }

    clearAllLogs() {
        logger.extra();
        this.logs = {};
    }

    findLog(key, value) {
        logger.extra();
        if (!this.logs[key]) {
            return -1;
        }
        return this.logs[key].findIndex((itm) => itm.message === value.message && itm.type === value.type);
    }

}

export default new Logger();