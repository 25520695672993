import { makeObservable, action } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE, SPEEDFOLDER_API_BASE } from '../utils/constants';

import LoggerState, { logTypeEnum } from '../state/logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

class Speedfolder {

    constructor() {
        logger.extra();
        makeObservable(this, {
            speedfolderCheckout: action,
        }, { deep: true });
    }

    async speedfolderCheckout() {
        logger.extra();

        try {
            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/checkout-session/SPEEDfolder`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({});

            logger.extra(res);

            await AuthState.refreshLogin();

            LoggerState.addLog('speedfolder.checked.out', {
                type: logTypeEnum.SUCCESS,
                message: 'You\'ve subscribed to SPEEDfolder!',
            });

        } catch (err) {
            logger.error(err);
            throw err;
        }
    }

    async initSpeedfolderBasicAd() {
        logger.extra();

        try {
            const token = await AuthState.getToken();
            // this can't be done in the subscription-api since that api has no special speedfolder advertiser signup endpoint
            await request('POST', `${SPEEDFOLDER_API_BASE}/private/advertisement/initialize`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({});

        } catch (err) {
            logger.error(err);
            throw err;
        }

    }
}

export default new Speedfolder();