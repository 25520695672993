import AuthState from '../state/auth';
// import RolesState from '../state/roles';
import { ROLES } from './constants';


export const hasAuth = () => AuthState.creds !== null;
export const validateAuth = (role) => hasAuth() && AuthState.creds.user.roles.findIndex((r) => r === role.id) >= 0;
export const isSuper = () => validateAuth(ROLES.SUPER);
export const isAdmin = () => validateAuth(ROLES.ADMIN);
export const isGrant = () => validateAuth(ROLES.GRANT);
export const isWrite = () => validateAuth(ROLES.WRITE);
export const isRead = () => validateAuth(ROLES.READ);
export const isDelete = () => validateAuth(ROLES.DELETE);
export const hasAccessRoles = () => {
    // todo: add product specific access roles check.
    const accessKeys = Object.keys(AuthState.creds.user.access);
    return accessKeys.length > 0;
};
