import colors from './colors';

const palette = {
    palette: {
        primary: {
            main: colors.orange[200],
        },
        secondary: {
            main: colors.monotone[300],
            contrastText: colors.monotone[500],
        },
        error: {
            main: colors.red[200],
        },
        warning: {
            main: colors.yellow[200],
        },
        info: {
            main: colors.blue[200],
        },
        success: {
            main: colors.green[200],
        },
        text: {
            secondary: colors.monotone[500],
        }
    },
};

export default palette;