import { makeObservable, action, observable } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import { roles } from '../helpers';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class Invite {
    invite_code = '';

    invite_sent = false;

    invite_email = '';

    invite_object = null;

    // Basic User
    invite_customer_roles = roles.basicProgramRole;

    invite_subscription_roles = {};

    constructor() {
        logger.extra();
        makeObservable(this, {
            invite_code: observable,
            invite_sent: observable,
            invite_email: observable,
            invite_object: observable,

            invite_customer_roles: observable,
            invite_subscription_roles: observable,

            getCode: action,
            setCode: action,
            sendInvite: action,
            acceptInvite: action,
            setEmail: action,
            clear: action,

            setCustomerRoles: action,
            setSubscriptionRoles: action,
        });
    }

    async getCode() {
        logger.extra();
        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/user/invite`)
                .set('Content-Type', 'application/json')
                .query({ inviteCode: this.invite_code });

            this.invite_object = res.body;
        } catch (e) {
            logger.error('GET INVITE CODE ERROR:', e);
        }
    }

    setCode(value) {
        logger.extra();
        this.invite_code = value;
    }

    async sendInvite() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/user/invite`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    email: this.invite_email,
                    roles: this.invite_customer_roles,
                    programRoles: this.invite_subscription_roles,
                });

            if (res.body.inviteUUID) {
                this.invite_sent = true;
            }

            LoggerState.addLog('inviteState', {
                type: 'success',
                message: 'Invite Sent!'
            });

            this.clear();
        } catch (e) {
            logger.error('SEND INVITE CODE ERROR:', e);

            LoggerState.addLog('inviteState', {
                type: 'error',
                message: `error when sending invite code: ${e}`
            });

            throw e;
        }

    }

    async acceptInvite(user) {
        logger.extra();
        try {
            const accepted = await request('POST', `${INTERNAL_API_BASE}/public/user/acceptInvite`)
                .set('Content-Type', 'application/json')
                .send({
                    inviteCode: this.invite_code,
                    user
                });

            const { customer_id } = accepted;

            AuthState.login({ username: user.username, password: user.password, companyId: customer_id });
        } catch (e) {
            logger.error('ACCEPT INVITE ERROR:');
        }
    }

    setEmail(value) {
        logger.extra();
        this.invite_email = value;
    }

    setCustomerRoles(value) {
        logger.extra();
        this.invite_customer_roles = value;
    }

    setSubscriptionRoles(value) {
        logger.extra();
        this.invite_subscription_roles = value;
    }

    clear() {
        logger.extra();
        this.invite_code = '';
        this.invite_sent = false;
        this.invite_email = '';
        this.invite_object = null;
    }

}

export default new Invite();