import React from 'react';
import './QuantityInput.scss';

import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import PropTypes from 'prop-types';

const safeDecrease = ({ qty, newQty, setQty, floor }) => setQty(newQty >= floor ? newQty : qty);
const safeIncrease = ({ qty, newQty, setQty, ceiling }) => setQty(newQty <= ceiling ? newQty : qty);

const QuantityInput = ({ qty, setQty, ceiling, floor, increaseCallback, decreaseCallback }) => {
    const params = { qty, setQty };

    // use unsafe callback if provided
    const decrease = decreaseCallback ? () => decreaseCallback() : () => safeDecrease({ ...params, newQty: qty - 1, floor });
    const increase = increaseCallback ? () => increaseCallback() : () => safeIncrease({ ...params, newQty: qty + 1, ceiling });

    return (<div className='QuantityInput'>
        <p>Quantity / User</p>

        <p className='QuantityButtons'>
            <button onClick={(e) => {
                e.preventDefault(); decrease();
            }}><MinusIcon /></button>
            {qty}
            <button onClick={(e) => {
                e.preventDefault(); increase();
            }}><PlusIcon /></button>
        </p>

    </div >);
};

QuantityInput.propTypes = {
    qty: PropTypes.number,
    setQty: PropTypes.func,
    ceiling: PropTypes.number,
    floor: PropTypes.number,
    increaseCallback: PropTypes.func,
    decreaseCallback: PropTypes.func
};

export default QuantityInput;