/* eslint-disable no-mixed-spaces-and-tabs */
import './AccountUsers.scss';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { Card, Button, FormSection } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

import AccountUserManager from './AccountUserManager';
import UserRoleEditor from '../../../components/UserRoleEditor';
import Logger from '../../../components/Logger';
import InviteUserModal from '../../../components/InviteUserModal';
import PurchaseLicenseModal from '../../../components/PurchaseLicenseModal';

import { formatPhoneNumber } from '../../../helpers/formatter';
import {
    STRIPE_SPEEDLABEL_PRODUCT_ID,
    STRIPE_SPEEDCAD_PRODUCT_ID,
    STRIPE_SPEEDTEMPLATE_PRODUCT_ID,
    STRIPE_SPEEDFOLDER_PRODUCT_ID
} from '../../../utils/constants';

import AuthState from '../../../state/auth';
import AccountUsersState from '../../../state/account_users';
import LoggerState from '../../../state/logger';
import ProductsState from '../../../state/products';
import ModalState from '../../../state/modal';
import SubscriptionsState from '../../../state/subscriptions';
import { useWindowSize } from '../../../hooks';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);

const updateProgramUserRoles = async ({ user_id, roles, program_id }) => {
    const { uId } = AuthState.creds.user;

    logger.extra({ uId, user_id, program_id, roles });

    const updateRoles = async () => await AccountUsersState.updateProgramRoles({ user_id, roles, program_id });

    try {
        await updateRoles();
    } catch (e) {
        logger.error(e);

        if (e?.status == 402) {
            ModalState.open(<PurchaseLicenseModal userId={user_id} programId={program_id} roles={roles} />);
        }

        throw e;
    }
};

const hasSubscription = (external_id, accountUser) => {
    const programIds = accountUser.programRoles ? Object.keys(accountUser.programRoles) : [];

    if (ProductsState.products.length === 0) {
        return false;
    }

    // const programIds = accountUser.programRoles ? Object.keys(accountUser.programRoles) : [];
    const product = ProductsState.getProductByExternalId(external_id);

    if (product) {
        const programIndex = programIds.findIndex((pId) => Number(pId) === product.id);
        return programIndex > -1;
    } else {
        return false;
    }
};

const AccountUsers = observer(() => {
    logger.extra();
    const [isAdding, setIsAdding] = useState(false);
    const [logState] = useState(() => LoggerState);

    useEffect(() => {
        SubscriptionsState.fetchSubscriptions();
    }, []);

    return (
        <section className={'AccountUsers'}>
            <div>
                <h4>Manage Users</h4>
                <div className={'--ButtonArea'}>
                    <div>
                        <Button
                            label={'Invite Free User To Company'}
                            type={ButtonType.Chat}
                            colorTheme={Colors.Sage}
                            onClick={() => {
                                ModalState.open(<InviteUserModal />);
                            }}
                        />
                    </div>
                    <div>
                        {isAdding ?
                            <Button
                                label={''}
                                type={ButtonType.Close}
                                onClick={() => {
                                    setIsAdding(false);
                                }}
                            />
                            :
                            <Button
                                label={'Add Free User To Company'}
                                type={ButtonType.Add}
                                onClick={() => setIsAdding(true)}
                            />
                        }
                    </div>
                </div>
            </div>

            {
                logState.logs[AccountUsersState.logKeys.createUser] &&
                logState.logs[AccountUsersState.logKeys.createUser].length > 0 &&
                (
                    <div>
                        &nbsp;
                        <Logger logKey={AccountUsersState.logKeys.createUser} />
                    </div>
                )
            }

            <AnimatePresence>
                {isAdding &&
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'initial', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: .25 }}
                        key={1}
                    >
                        <AccountUserManager setIsAdding={setIsAdding} />
                    </motion.div>
                }
            </AnimatePresence>

            <AccountUsersTable />
        </section>
    );
});

const AccountUsersTable = observer(() => {
    logger.extra();
    const [accountUsersState] = useState(() => AccountUsersState);
    const [isEditing, setIsEditing] = useState(false);
    const [selected, setIsSelected] = useState(Array(AccountUsersState.users.length).fill(false));
    const [{ subscriptions }] = useState(() => SubscriptionsState);
    const { width } = useWindowSize();


    useEffect(() => {
        logger.extra();
        AccountUsersState.fetchUsers(AuthState.creds.user.cId);

        if (ProductsState.products.length === 0) {
            ProductsState.fetchProducts();
        }
    }, []);

    const getSubscriptionIcon = (external_id, accountUser) => {
        if (ProductsState.products.length === 0) {
            return <div className='StatusIcon'><CircularProgress /></div>;
        }

        return hasSubscription(external_id, accountUser) ? <div className='StatusIcon'><CheckCircleIcon className={'CheckIcon'} /></div> : <div className='StatusIcon'><CancelIcon className={'CancelIcon'} /></div>;
    };

    const rowHandler = (i) => {
        if (isEditing) { // is open

            if (selected[i] === true) { // close everything & no more editing
                const clone = [...new Set(selected)].fill(false);

                setIsSelected(clone);
                setIsEditing(true);
            } else { // another is selected & keep editing
                const clone = [...new Set(selected)].fill(false);
                clone[i] = true;
                setIsSelected(clone);

            }
        } else { // is closed
            const clone = [...new Set(selected)].fill(false);
            clone[i] = true;
            setIsSelected(clone);
            setIsEditing(true);
        }
    };

    if (width <= 768) {
        return (
            <>
                {accountUsersState.users.map((accountUser, i) => <MobileAccountUserCard accountUser={accountUser} key={`accountUserCard-${i}`} />)}
            </>
        );
    }

    return (
        <Card colorTheme={Colors.Grey} className={'CurrentUsers'}>
            <TableContainer className={'UserTable'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            {/* <TableCell>SPEEDcad</TableCell> */}
                            <TableCell>User</TableCell>
                            {/* <TableCell>Email / Username</TableCell> */}
                            <TableCell>Phone</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>SPEEDlabel</TableCell>
                            <TableCell>SPEEDfolder</TableCell>
                            <TableCell>SPEEDcad</TableCell>
                            <TableCell>SPEEDtemplate</TableCell>
                            <TableCell>Software Roles</TableCell>
                            <TableCell>Company Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(accountUsersState.users.length === 0) &&
                            <tr className={'emptyListRow'}>
                                <td><p className={'subtext'}>No Account Managers Found... Add new users above.</p></td>
                            </tr>
                        }

                        {accountUsersState.users.map((accountUser, i) => (
                            // Name
                            <TableRow key={'accountUsersRow'}>
                                <TableCell>{accountUser.first_name} {accountUser.last_name}</TableCell>
                                {/* User */}
                                <TableCell><a href={`mailto:${accountUser.email}`}>{accountUser.email}</a></TableCell>
                                {/* Phone */}
                                <TableCell>{formatPhoneNumber(accountUser.phone)}</TableCell>
                                {/* Last Login */}
                                <TableCell>{accountUser.last_login_at ? moment(accountUser.last_login_at).format('MMM Do YYYY, h:mm a') : 'Never'}</TableCell>
                                {/* Subscription Access */}
                                <TableCell align='center'>{getSubscriptionIcon(STRIPE_SPEEDLABEL_PRODUCT_ID, accountUser)}</TableCell>
                                <TableCell align='center'>{getSubscriptionIcon(STRIPE_SPEEDFOLDER_PRODUCT_ID, accountUser)}</TableCell>
                                <TableCell align='center'>{getSubscriptionIcon(STRIPE_SPEEDCAD_PRODUCT_ID, accountUser)}</TableCell>
                                <TableCell align='center'>{getSubscriptionIcon(STRIPE_SPEEDTEMPLATE_PRODUCT_ID, accountUser)}</TableCell>
                                {/* Edit Software Roles */}
                                <TableCell>
                                    <div className='StatusIcon'>
                                        <IconButton onClick={() => rowHandler(i)} disabled={subscriptions.length == 0}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </div>
                                </TableCell>
                                {/* Company Role Editor */}
                                <TableCell align='center'>
                                    <TableCell align='center' style={{ width: 150 }} >
                                        <div className='CompanyRoleEditor'>
                                            <UserRoleEditor
                                                user={accountUser}
                                                roles={accountUser.roles}
                                                disableTitle={true}
                                                logKey='accountUser'
                                                onSaveClick={(e, roles) => {
                                                    AccountUsersState.updateRoles({ user_id: accountUser.id, roles: roles });
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableCell>

                                {/* animation dillon rigged up */}
                                {(selected[i] === true) &&
                                    accountUser.roles.length > 0 ?
                                    subscriptions.map((subscription) => (
                                        <div className='expandedRow' key={subscription.program.id}>
                                            <UserRoleEditor
                                                productName={subscription.program.name}
                                                key={'userRoleEditor'}
                                                user={accountUser}
                                                roles={accountUser.programRoles[subscription.program.id] ? accountUser.programRoles[subscription.program.id] : []}
                                                title={`${subscription.program.name} Role:`}
                                                onSaveClick={(e, roles) => updateProgramUserRoles({ user_id: accountUser.id, roles, program_id: subscription.program.id })}
                                            />
                                        </div>
                                    ))
                                    : null
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
});

const MobileAccountUserCard = observer(({ accountUser }) => {
    logger.extra();
    const [formOpen, setFormOpen] = useState(false);
    const [{ subscriptions }] = useState(() => SubscriptionsState);

    return (
        <Card className='MobileAccountUserCard'>
            <FormSection
                label={`${accountUser.first_name} ${accountUser.last_name}`}
                subtext={(
                    <p className={'subtext'}>
                        <span>{accountUser.email}</span>
                        <br />
                        <span>{accountUser.phone}</span>
                        <br />
                        <br />
                        <span><b>Last Login:</b> {accountUser.last_login_at ? moment(accountUser.last_login_at).format('MMM Do YYYY, h:mm a') : 'Never'}</span>
                        <p><strong>Company Role: </strong>
                            {accountUser.roles.length >= 5 ? 'Admin' : null}
                            {accountUser.roles.length >= 4 && accountUser.roles.length < 5 ? 'Basic User' : null}
                            {accountUser.roles.length <= 0 ? 'Disabled' : null}
                        </p>
                    </p>
                )}
            >
            </FormSection>

            <div className='--ButtonArea'>

                <Button
                    label={formOpen ? 'Hide Roles' : 'Edit Roles'}
                    colorTheme={formOpen ? Colors.Red : Colors.Green}
                    type='edit'
                    onClick={() => setFormOpen(!formOpen)}
                />
            </div>
            {formOpen &&
                <>
                    <UserRoleEditor
                        user={accountUser}
                        roles={accountUser.roles}
                        title={'Edit User\'s Company Roles:'}
                        logKey='accountUser'
                        onSaveClick={(e, roles) => {
                            AccountUsersState.updateRoles({ user_id: accountUser.id, roles: roles });
                        }}
                    />
                    {subscriptions.map((subscription) => (
                        <UserRoleEditor
                            key={'userRoleEdior'}
                            user={accountUser}
                            roles={accountUser.programRoles[subscription.program.id] ? accountUser.programRoles[subscription.program.id] : []}
                            title={`Edit User's ${subscription.program.name} Roles:`}
                            buttonLabel={`Save ${subscription.program.name} Roles:`}
                            onSaveClick={(e, roles) => updateProgramUserRoles({ user_id: accountUser.id, roles, program_id: subscription.program.id })}
                        />
                    ))}
                </>
            }
        </Card>
    );
});

export default AccountUsers;