import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import UserSettings from './UserSettings';

import SuperUserCustomerRoles from '../../../../state/super_user_customer_roles';
import SuperUserSubscriptionRoles from '../../../../state/super_user_subscription_roles';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(({ users, crumbUpdate }) => {
    logger.extra();
    const match = useRouteMatch();
    const { user_id } = match.params;
    const user = users.find((u) => u.id === Number(user_id));
    const [{ user_customer_roles }] = useState(() => SuperUserCustomerRoles);

    useEffect(() => {
        logger.extra();
        SuperUserCustomerRoles.fetch(user_id);
        SuperUserSubscriptionRoles.fetch(user_id);
        return () => crumbUpdate(null);
    }, []);

    useEffect(() => {
        logger.extra();
    }, [crumbUpdate, user_id]);

    return user ? (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <UserSettings user={user} user_customer_roles={user_customer_roles} />
            </Grid>
        </Grid>
    ) : null;
});