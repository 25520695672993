import { makeObservable, action, observable } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
// import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class Customer {
    customer = null;

    charges = [];

    chargesLoading = false;

    logKeys = {
        fetchCustomer: 'customer.fetchCustomer',
        fetchInvoices: 'customer.fetchInvoices',
    };

    constructor() {
        logger.extra();
        makeObservable(this, {
            customer: observable,
            charges: observable,
            chargesLoading: observable,
            fetchCustomer: action,
            fetchCharges: action,
        }, { deep: true });
    }

    async fetchCustomer() {
        logger.extra();
        const token = await AuthState.getToken();
        const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${AuthState.creds.user.cId}`)
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${token}`)
            .query({});

        this.customer = res.body.customer;
    }

    async fetchCharges() {
        logger.extra();

        try {
            this.chargesLoading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${AuthState.creds.user.cId}/charges`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            this.charges = res.body.charges;
        } catch (e) {
            logger.error('FETCH INVOICES ERROR', e);
        }

        this.chargesLoading = false;
    }
}

export default new Customer();