import { makeObservable, observable } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';
const logger = new Logger(__filename);

class User {
    user = null;

    logKeys = {
        fetch: 'user.fetch',
        update: 'user.update',
    };

    constructor() {
        logger.extra();
        makeObservable(this, {
            user: observable,
        });
    }

    async fetch({ user_id }) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/user/${user_id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            this.user = res.body;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog(this.logKeys.fetch, {
                type: 'error',
                message: `Fetch User Error: ${e.message}`,
            });
        }
    }

    async update(user_id, body) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('PUT', `${INTERNAL_API_BASE}/private/user/${user_id}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(body);

            this.user = res.body;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog(this.logKeys.update, { type: 'error', message: e.message });
        }
    }

    async agreeToAdvertiserTermsOfService() {
        logger.extra();

        try {
            const token = await AuthState.getToken();
            await request('POST', `${INTERNAL_API_BASE}/private/user/accept-ad-tos`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`);
        } catch (err) {
            logger.error(err);
            throw err;
        }
    }

}

export default new User();