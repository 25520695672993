import { observer } from 'mobx-react';
import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DataSources from '../../state/data_sources';
import Logger from '@fgt/common/utils/logger';
import './CustomersTable.scss';

const logger = new Logger(__filename);
// functionally convert DataSources.integrationTypes to object because dillon made it an array? 🤷
const integrationTypes = DataSources.integrationTypes.reduce((acc, it) => ({ ...acc, [it.id]: it.type }), {});

export default observer(({ customers, onSettingsClick }) => {
    logger.extra();
    return (
        <TableContainer className={'CustomersTable'}>
            <Table>
                <TableHead >
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Company ID</TableCell>
                        <TableCell>Stripe Customer ID</TableCell>
                        <TableCell>Integration</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customers.map((customer) => (
                        <TableRow key={'customerData'}>
                            <TableCell>{customer.name}</TableCell>
                            <TableCell>{customer.internal_id}</TableCell>
                            <TableCell>{customer.external_id}</TableCell>
                            <TableCell>{integrationTypes[customer.type] || 'None'}</TableCell>
                            <TableCell>
                                <IconButton onClick={(e) => {
                                    onSettingsClick(e, customer);
                                }}><SettingsIcon /></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});