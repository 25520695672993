import './UpdateUserLimitModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import ModalState from '../../state/modal';

import QuantityInput from '../QuantityInput';
import PurchaseLicenseModal from '../PurchaseLicenseModal';
import RemoveLicenseModal from '../RemoveLicenseModal/RemoveLicenseModal';

const handleChange = ({ oldLimit, newLimit, programId, programName, subscription }) => {

    // if the limit did not change- close the modal
    if (oldLimit == newLimit) {
        ModalState.close();
        return;
    }

    // if the new limit is greater- use purchase license modal
    if (oldLimit < newLimit) {
        ModalState.open(<PurchaseLicenseModal programId={programId} disableTitle={true} quantityToPurchase={newLimit - oldLimit} />);
        return;
    }

    // if the new limit is less- use decrease license modal
    ModalState.open(<RemoveLicenseModal userLimit={newLimit} subscription={subscription} programName={programName} programId={programId} />);
    return;
};

const UpdateUserLimitModal = ({ programId, program, subscription }) => {

    const { user_limit } = subscription;
    const [newLimit, setNewLimit] = useState(user_limit);

    const { external_product } = program;
    const programName = external_product.name;


    return (
        <div className='UpdateUserLimitModal'>
            <h3>New <strong>{programName}</strong> User Limit</h3>
            <QuantityInput qty={newLimit} setQty={setNewLimit} floor={0} ceiling={user_limit + 5} />
            <Button className='Confirm' type={ButtonType.Save} colorTheme={ButtonType.Green} label={'Confirm'} onClick={() => handleChange({ oldLimit: user_limit, newLimit, programId, programName, program, subscription })} />
            <br />
            <small>(you can only add up to 5 at a time)</small>
        </div>
    );
};

UpdateUserLimitModal.propTypes = {
    programId: PropTypes.number,
    program: PropTypes.object,
    subscription: PropTypes.object
};

export default UpdateUserLimitModal;