import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const useStyles = makeStyles({
    input: {
        padding: 0
    }
});

export default observer(({
    user,
    editMode,
    firstName, setFirstName,
    lastName, setLastName,
    username, setUsername,
    email, setEmail,
    phone, setPhone,
    title, setTitle,
    password, setPassword
}) => {
    logger.extra();
    const classes = useStyles();

    return user ? (
        <Grid
            container
            item
            spacing={2}
            xs={12}
        >
            <Grid container item xs={6} spacing={1}>
                <Grid item>
                    <Typography variant='body1'><strong>Name:</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                    <Input
                        label={'First Name'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}>
                    <Input
                        label={'lastName'}
                        // placeholder={'Last Name'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item xs={6} spacing={1}>
                <Grid item>
                    <Typography variant='body1'><strong>Username:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Input
                        label={'Username'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1} >
                <Grid item>
                    <Typography variant='body1'><strong>Email:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Input
                        label={'Email:'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1} >
                <Grid item>
                    <Typography variant='body1'><strong>Phone:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Input
                        label={'Phone Number'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1} >
                <Grid item>
                    <Typography variant='body1'><strong>Title:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Input
                        label={'Title'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1}>
                <Grid item>
                    <Typography variant='body1'><strong>User Since:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='body1'>{moment(user.created_at).format('MM/DD/YYYY')}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={6} spacing={1} >
                <Grid item>
                    <Typography variant='body1'><strong>Password:</strong></Typography>
                </Grid>
                <Grid item xs={10}>
                    <Input
                        label={'Password'}
                        disabled={!editMode}
                        classes={{ input: classes.input }}
                        type="text"
                        value={password ? password : ''}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
            </Grid>
        </Grid>
    ) : null;
});