import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import UserDetails from './UserDetails';
import SearchableUsers from '../../../../state/searchable_users';
import { Card, Button } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
export default observer(({ user }) => {
    logger.extra();
    const [editMode, setEditMode] = useState(false);
    const [firstName, setFirstName] = useState(user ? user.first_name : '');
    const [lastName, setLastName] = useState(user ? user.last_name : '');
    const [username, setUsername] = useState(user ? user.username : '');
    const [email, setEmail] = useState(user ? user.email : '');
    const [phone, setPhone] = useState(user ? user.phone : '');
    const [title, setTitle] = useState(user ? user.title : '');
    const [password, setPassword] = useState('');

    const handleUserSave = () => {
        logger.extra();
        if (
            firstName !== user.firstName ||
			lastName !== user.lastName ||
			username !== user.username ||
			email !== user.email ||
			phone !== user.phone ||
			title !== user.title
        ) {
            const toSend = {
                id: user.id,
                first_name: firstName,
                last_name: lastName,
                username: username,
                email: email,
                phone: phone,
                title: title,
            };

            if (password && password.length > 0) {
                toSend.password = password;
            }

            SearchableUsers.update(toSend);
        }

        setEditMode(!editMode);
    };

    const handleDiscardChanges = () => {
        logger.extra();
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setUsername(user.username);
        setEmail(user.email);
        setPhone(user.phone);
        setTitle(user.title);
        setEditMode(!editMode);

    };

    return user ? (
        <section className={'UserSettings'}>
            <h5>User Settings</h5>
            <Card>
                <Grid container spacing={4} >
                    <UserDetails
                        user={user}
                        editMode={editMode}
                        firstName={firstName} setFirstName={setFirstName}
                        lastName={lastName} setLastName={setLastName}
                        username={username} setUsername={setUsername}
                        email={email} setEmail={setEmail}
                        phone={phone} setPhone={setPhone}
                        title={title} setTitle={setTitle}
                        password={password} setPassword={setPassword}
                    />

                    <Grid container item xs={12}>
                        {editMode && (
                            <>
                                <Grid item>
                                    <Button
                                        type={ButtonType.Delete}
                                        onClick={handleDiscardChanges}
                                        label={'Cancel'}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        colorTheme={Colors.Green}
                                        type={ButtonType.Save}
                                        onClick={handleUserSave}
                                        label={'Save'}
                                    />
                                </Grid>
                            </>
                        )}
                        {!editMode && (
                            <Grid item>
                                <Button
                                    colorTheme={Colors.Orange}
                                    type={ButtonType.Edit}
                                    onClick={() => setEditMode(!editMode)}
                                    label={'Edit User Details'}
                                />
                            </Grid>
                        )}
                    </Grid>

                </Grid>
            </Card>
        </section>
    ) : null;
});