import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperUserSubscriptionRoles {
    user_id = null;

    user_subscriptions = [];

    loading = false;

    constructor() {
        logger.extra();
        makeObservable(this, {
            user_id: observable,
            user_subscriptions: observable,
            loading: observable,
            fetch: action,
        }, { deep: true });
    }

    async fetch(user_id) {
        logger.extra();
        try {
            this.loading = true;
            this.user_id = user_id;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/super/user/${user_id}/subscriptions`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { user_subscriptions } = res.body;

            this.user_subscriptions = user_subscriptions;
        } catch (e) {
            logger.error(' FETCH USER SUBSCRIPTION ROLES ERROR:', e);
            LoggerState.addLog('superUserSubscriptionRoles', {
                type: 'error',
                message: `Fetch user subscription roles error: ${e}`,
            });
        }

        this.loading = false;
    }

    // - customer_id: Number,
    // - program_id: Number,
    // - roles: { id, cost, name }
    async update(customer_id, program_id, roles) {
        logger.extra();
        try {
            this.loading = true;
            const customerSubscriptionIndex = this.user_subscriptions.findIndex((usub) =>
                usub.customer_id === customer_id &&
				usub.program_id === program_id);

            const token = await AuthState.getToken();
            const res = await request('PUT', `${INTERNAL_API_BASE}/private/super/user/${this.user_id}/subscriptions`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    customer_id,
                    program_id,
                    roles,
                });

            const { user_subscription } = res.body;

            if (customerSubscriptionIndex > -1 && roles.length > 0) {
                this.user_subscriptions.splice(customerSubscriptionIndex, 1, user_subscription);
            } else if (customerSubscriptionIndex > -1 && roles.length === 0) {
                this.user_subscriptions.splice(customerSubscriptionIndex, 1);
            } else {
                this.user_subscriptions.push(user_subscription);
            }

            LoggerState.addLog('superUserSubscriptionRoles', {
                type: 'success',
                message: 'Updated user subscription roles!',
            });

        } catch (e) {
            logger.error('UPDATE USER SUBSCRIPTION ROLES ERROR:', e);
            LoggerState.addLog('superUserSubscriptionRoles', {
                type: 'error',
                message: `Update user subscription roles error: ${e}`,
            });
        }

        this.loading = false;
    }

}

export default new SuperUserSubscriptionRoles();