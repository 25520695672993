import './PurchaseLicenseModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import SubscriptionState from '../../state/subscriptions';
import ProductState from '../../state/products';
import ModalState from '../../state/modal';

import QuantityInput from '../QuantityInput';

const increaseUserLimit = async ({ password, userLimit, subscriptionItemId }) => {

    try {
        document.body.style.pointerEvents = 'none'; // disable mouse events so users don't do anything weird
        ModalState.open(<h3>Setting up your subscription(s)...</h3>);
        await SubscriptionState.updateUserLimit({ password, userLimit, subscriptionItemId });
        // eslint-disable-next-line react/no-unescaped-entities
        ModalState.open(<h3>You can assign your user's role after this page reloads.</h3>);
        setTimeout(() => {
            document.body.style.pointerEvents = 'auto';
            window.location.reload();
        }, 3000);
    } catch (err) {
        {
            // if the password incorrect
            err.status == 401 || !password ?
                ModalState.open(<div>
                    <h3>Incorrect Password!</h3>
                </div>)
                // else have user contact support
                :
                ModalState.open(<div>
                    <h3>There was an issue adding your subscription. Please contact support.</h3>
                    <p>(708) 505-2812</p>
                    <p>support@fifthgeartech.com</p>
                </div>);
            document.body.style.pointerEvents = 'auto';
        }
    }
};

const PurchaseLicenseModal = ({ programId, disableTitle, quantityToPurchase = 1 }) => {
    // state
    const { products } = ProductState;
    const { subscriptions } = SubscriptionState;

    // get product & subscription
    const { external_product, external_product_id } = products.find((p) => p.id == programId);
    const { user_limit, external_subscription } = subscriptions.find((s) => s.program_id == programId);

    const programName = external_product.name;
    const programPrice = external_product.prices[0].unit_amount / 100; // USD
    const subscriptionItem = external_subscription.items.data.find((i) => i.plan.product == external_product_id);

    // hooks
    const [doubleCheck, setDoubleCheck] = useState(false);
    const [password, setPassword] = useState(null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(quantityToPurchase > 5 ? 5 : quantityToPurchase);

    return (
        <div className='PurchaseLicenseModal'>
            {!disableTitle && <div className='LicensePrompt'>
                <h2>Not enough {programName} licenses.</h2>
            </div>}

            {!doubleCheck &&
                <div className='PromptAction'>
                    <h5>Add additional <strong>{programName}</strong> subscriptions?</h5>
                    <QuantityInput qty={purchaseQuantity} setQty={setPurchaseQuantity} floor={1} ceiling={5} />
                    <Button className='Confirm' type={ButtonType.Danger} colorTheme={ButtonType.Orange} label={`Yes Add ${purchaseQuantity} Subscription(s)`} onClick={() => setDoubleCheck(true)} />
                    <Button className='Cancel' type={ButtonType.Close} colorTheme={ButtonType.Green} label='Never Mind' onClick={() => ModalState.close()} />
                    <br />
                    <small>(you can only add up to 5 at a time)</small>
                </div>
            }

            {doubleCheck &&
                <div className='DoubleCheck'>
                    <h5 className='DangerWillRobinson'>You will be billed today and every month alongside your other subscription costs.</h5>

                    <div className='ConfirmPassword'>
                        <p>Confirm Password:</p>
                        <input type='password' onChange={(e) => setPassword(e.target.value)}></input>
                    </div>

                    <Button className='Confirm' type={ButtonType.Danger} colorTheme={ButtonType.Red} label={`Add ${purchaseQuantity} $${programPrice} Subscription(s)`} onClick={() => increaseUserLimit({ password, userLimit: Number(user_limit) + Number(purchaseQuantity), subscriptionItemId: subscriptionItem?.id })} />
                    <Button className='Cancel' type={ButtonType.Close} colorTheme={ButtonType.Green} label='Never Mind' onClick={() => ModalState.close()} />
                </div>
            }
        </div>
    );
};

PurchaseLicenseModal.propTypes = {
    programId: PropTypes.number,
    disableTitle: PropTypes.bool,
    quantityToPurchase: PropTypes.number
};

export default PurchaseLicenseModal;