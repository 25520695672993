import React from 'react';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SignupForm from '../../../features/signupForm';

import ModalState from '../../../state/modal';
import SearchableUsersState from '../../../state/searchable_users';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
import { Card } from '@fifth-gear-tech/common-components/dist/components';

export default observer(({ user = null }) => {
    logger.extra();
    return (
        <Card borderVariant='green'>
            <Grid container spacing={2}>
                <Grid
                    container
                    item
                    xs={12}
                    justify='flex-end'
                >
                    <Grid item xs={10}>
                        <Typography variant='h4'>{user ? 'Edit User' : 'Create User'}</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'end' }}>
                        <IconButton onClick={() => ModalState.close()}><CloseIcon /></IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SignupForm
                        disableCard
                        userOnly
                        logKeyOverride='searchableUsers'
                        onSubmitClick={(userData) => {
                            logger.extra();
                            SearchableUsersState.create({
                                first_name: userData.firstName,
                                last_name: userData.lastName,
                                email: userData.email,
                                phone: userData.phone,
                                title: userData.title,
                                username: userData.username,
                                password: userData.password,
                            });
                            ModalState.close();
                        }}
                    />
                </Grid>
            </Grid>
        </Card>
    );
});