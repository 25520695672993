import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE } from '../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

class SearchableCustomers {
    customers = [];

    constructor() {
        logger.extra();
        makeObservable(this, {
            customers: observable,
            superFetchAll: action,
        });
    }

    async superFetchAll() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customers/listAll`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { customers } = res.body;

            this.customers = customers.map((customer) => ({
                ...customer,
                label: customer.name,
                value: customer.id,
            }));
        } catch (e) {
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
            logger.error('FETCH SEARCHABLE USERS ERROR', e);
        }
    }

}

export default new SearchableCustomers();