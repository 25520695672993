import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

import Users from './Users';
import User from './User';
import { DashboardPanel, DrawerTab } from '../../../components/Dashboard';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const UsersTab = ({ index }) => {
    logger.extra();
    return (
        <DrawerTab index={index}>
            <Typography variant='subtitle3'>Users</Typography>
        </DrawerTab>
    );
};

UsersTab.propTypes = {
    index: PropTypes.number.isRequired,
};

export default observer(({ index, users }) => {
    logger.extra();
    const match = useRouteMatch();
    // eslint-disable-next-line no-unused-vars
    const [breadCrumb, setBreadCrumb] = useState(null);

    return (
        <DashboardPanel
            index={index}
        >
            <Switch>
                <Route path={`${match.url}/u/:user_id`} exact={true}>
                    <User crumbUpdate={setBreadCrumb} users={users} index={index} />
                </Route>
                <Route path={`${match.url}`} exact={true}>
                    <Users users={users} index={index} />
                </Route>
            </Switch>
        </DashboardPanel>
    );
});