import './AuthTabs.scss';
import React from 'react';
import { observer } from 'mobx-react';

import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Button } from '@fifth-gear-tech/common-components/dist/components';

import SignUpForm from '../../features/signupForm';
import SignInForm from '../SignInForm/SignInForm';
import Logger from '@fgt/common/utils/logger';
import ModalState from '../../state/modal';

const logger = new Logger(__filename);

export default observer(({ signInOnSubmitOverride, signUpSuccess }) => {
    logger.extra();

    const SignUp = () => (
        <div>

            <div className='Right'>
                <Button type={ButtonType.Close} label='' onClick={() => ModalState.close()} />
            </div>

            <div className='Enroll'>
                <h3>Enroll Your Company</h3>
                <hr />
                <br />
            </div>

            <div>
                <SignUpForm disableCard={true} signUpSuccess={signUpSuccess} key={'SignUpForm'} />
            </div>

        </div>
    );

    const Login = () => (
        <div >

            <div className='Right'>
                <Button type={ButtonType.Close} label='' onClick={() => ModalState.close()} />
            </div>

            <SignInForm
                disableCard={true}
                onSubmitOverride={signInOnSubmitOverride}
                onLoginSuccess={signUpSuccess}
                key={'SignInForm'}
                customHeader={<h3>Login</h3>}
            />

        </div>
    );

    return (
        <div className='HerdCattle'>
            <div className='LoginDialogue'>
                <p>To get this product you must log in:</p>
                <Button type={ButtonType.Login} label='Login' colorTheme={ButtonType.Green} onClick={() => ModalState.open(<Login />)} />
            </div>

            <div className='SignUpDialogue'>
                <p>{'...Haven\'t enrolled your company?'}</p>
                <Button type={ButtonType.Add} label='Enroll Your Company' onClick={() => ModalState.open(<SignUp />)} />
            </div>
        </div>
    );
});