import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SubscriptionsState from '../../state/subscriptions';
import ModalState from '../../state/modal';

import UpdateUserLimitModal from '../UpdateUserLimitModal';
import { GearIcon } from '@radix-ui/react-icons';
import { Card, FormSection, Button } from '@fifth-gear-tech/common-components/dist/components';
import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import { useWindowSize } from '../../hooks';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const SubscriptionsTable = observer(({ subscriptions, disableLink = false, onSettingsClick }) => {
    const { width } = useWindowSize();

    logger.extra('SUBSCRIPTIONS TABLE: ', JSON.parse(JSON.stringify(subscriptions)));

    if (width <= 768) {
        // return mobile cards instead of table.
        return (
            <>
                {subscriptions.map((subscription) => (
                    <SubscriptionCard
                        key={'SubscriptionCard'}
                        subscription={subscription}
                        disableLink={disableLink}
                        onSettingsClick={onSettingsClick}
                    />
                ))}
            </>
        );
    }

    const editUserLimit = ({ subscription, program }) => {
        const { program_id } = subscription;
        ModalState.open(<UpdateUserLimitModal programId={program_id} program={program} subscription={subscription} />);
    };

    return (
        <Card className='CurrentSubscriptions'>
            <TableContainer className={'SubscriptionTable'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Subscribed On</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Renewal Date</TableCell>
                            <TableCell>User Limit</TableCell>
                            <TableCell>Edit User Limit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(subscriptions.length === 0) && (
                            <tr className={'emptyListRow'}>
                                <td><p className={'subtext'}>You have no subscriptions.</p></td>
                            </tr>
                        )}


                        {subscriptions.map((subscription) => {
                            logger.extra('SUBSCRIPTIONS TABLE MAP:', JSON.parse(JSON.stringify(subscription)));

                            const { /* external_id,*/ program, external_subscription, created_at, status, user_limit, licenses_used } = subscription;
                            const externalPrice = SubscriptionsState.buildExternalPrice(subscription);

                            logger.extra('EXTERNAL PRICE', externalPrice);

                            const price = externalPrice ? externalPrice.price : '$0.00';
                            const interval = externalPrice ? externalPrice.interval : 'per month';
                            const date = externalPrice ? moment(external_subscription.current_period_end * 1000) : null;

                            // if (!externalPrice) return null;

                            // const { price, interval } = externalPrice;

                            logger.extra('SUBSCRIPTIONS TABLE price:', price, interval, subscription);

                            // TODO -- this needs more data to see what this looks like and a working subscription link
                            return (
                                <>
                                    <TableRow>
                                        {/* Product */}
                                        <TableCell>{program.name}</TableCell>

                                        {/* Status */}
                                        <TableCell align='right'>
                                            <Chip
                                                size='small'
                                                className={`SubscriptionStatus ${status === 'active' ? '--active' : '--inactive'}`}
                                                label={status === 'active' ? 'active' : 'inactive'}
                                            />
                                        </TableCell>

                                        {/* Subscribed On */}
                                        <TableCell>{moment(external_subscription ? external_subscription.created * 1000 : created_at).format('MMM DD YYYY')}</TableCell>

                                        {/* Price */}
                                        <TableCell>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography variant="subtitle2" >{price}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption" component="p">{interval}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                        {/* Renewal Date */}
                                        <TableCell align='right' >
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <Typography variant="subtitle2" >{date ? date.fromNow() : 'Lifetime Membership'}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption" component="p" >{date ? date.format('MMM Do YYYY') : ''}</Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>

                                        {/* User Limit */}
                                        <TableCell>{user_limit < 0 ? 'No Limit' : `${licenses_used}/${user_limit}`}</TableCell>

                                        {/* Edit User Limit */}
                                        <TableCell align='center'>
                                            {subscription.user_limit > -1 &&
                                                <div className='EditUserLimit'>
                                                    <IconButton size='medium' onClick={() => editUserLimit({ subscription, program })}>
                                                        <SettingsIcon color='secondary' />
                                                    </IconButton>
                                                </div>
                                            }
                                        </TableCell>

                                    </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
});

const SubscriptionCard = ({ subscription, disableLink = false, onSettingsClick }) => {
    const { program_id, /* external_id,*/ program, external_subscription, customer_id, status } = subscription;
    const externalPrice = SubscriptionsState.buildExternalPrice(subscription);

    const price = externalPrice ? externalPrice.price : '$0.00';
    const interval = externalPrice ? externalPrice.interval : 'per month';
    const date = externalPrice ? moment(external_subscription.current_period_end * 1000) : null;

    return (
        <div>
            <Card className={'MobileSubscriptionCards'}>
                <FormSection
                    label={(
                        <span>
                            {program.name}
                            <Chip
                                style={{ marginLeft: '.5rem' }}
                                className={`SubscriptionStatus ${status === 'active' ? '--active' : '--inactive'}`}
                                label={status === 'active' ? 'active' : 'inactive'}
                            />
                        </span>
                    )}
                    subtext={`${price} ${interval}`}
                >
                    <span className={'MobileRenewalDate'}>
                        <p style={{ marginTop: 0 }}><b>Renewal Date:</b></p>
                        <p>{date ? `${date.format('MMM Do YYYY')}, ` : ''} {date ? date.fromNow() : 'Lifetime Membership'}</p>
                    </span>
                </FormSection>
                <div className={'ButtonArea'}>
                    {!disableLink && (
                        <Link to={`/subscription/${customer_id}/${program_id}`} >
                            <Button
                                label='Manage Subscription'
                                customIcon={GearIcon}
                                colorTheme={Colors.Orange}
                            />
                        </Link>
                    )}
                    {disableLink && (
                        <Tooltip title="Manage Subscription" placement="top" >
                            <IconButton size="medium" onClick={(e) => onSettingsClick(e, subscription)} >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </Card>
        </div>
    );
};

SubscriptionCard.propTypes = {
    subscription: PropTypes.object,
    disableLink: PropTypes.bool,
    onSettingsClick: PropTypes.object
};

// export default SubscriptionCard;
export default SubscriptionsTable;