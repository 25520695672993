import { makeObservable, action, observable } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import { INTERNAL_API_BASE } from '../utils/constants';
import LoggerState from './logger';
import { validation } from '../helpers';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

class Auth {
    creds = localStorage.getItem('creds') ? JSON.parse(localStorage.getItem('creds')) : null;

    validEmailUpdateTimer = null;

    user = localStorage.getItem('creds') ? this.creds.user.uId : null;

    validatedUsernameExists = true;

    validatedCompanyIdExists = true;

    validatedEmailExists = true;

    emailResetRequested = false;

    emailResetValidated = false;

    stripePortalUrl = null;

    requestCompanySelect = false;

    selectedCompany = null;

    selectableCompanies = null;

    logKeys = {
        email_update: 'auth.update_email',
        email_exists: 'validateCompanyId',
        login: 'auth',
        reset_password: 'resetPassword',
        validate_email: 'auth.validate_email',
        validate_username: 'validateUsername',
        validate_companyId: 'validateCompanyId',
        retrieve_company_id: 'auth.retrieve_company_id',
    };

    constructor() {
        logger.extra();

        makeObservable(this, {
            creds: observable,
            user: observable,
            validatedUsernameExists: observable,
            validatedCompanyIdExists: observable,
            validatedEmailExists: observable,
            emailResetRequested: observable,
            emailResetValidated: observable,
            stripePortalUrl: observable,
            requestCompanySelect: observable,
            selectedCompany: observable,
            selectableCompanies: observable,
            login: action,
            refreshLogin: action,
            getToken: action,
            logout: action,
            resetPassword: action,
            create: action,
            refreshUser: action,
            validateUsername: action,
            validateCompanyId: action,
            validateEmail: action,
            requestPasswordReset: action,
            validateResetCode: action,
            requestEmailUpdate: action,
            validateEmailUpdateCode: action,
            requestStripePortalSessionRedirect: action,
            setSelectedCompany: action,
            clearAuth: action,
        }, {
            deep: true
        });

    }

    async login({ username, password, companyId, auth0AccessToken }) {
        logger.extra();

        try {
            const res = await request('POST', `${INTERNAL_API_BASE}/public/login`)
                .set('Content-Type', 'application/json')
                .send({ username, password, companyId, auth0AccessToken });

            localStorage.setItem('creds', JSON.stringify(res.body));
            localStorage.setItem('LOGIN', JSON.stringify({
                companyId: res.body.user.iId,
                username: res.body.user.username,
            }));

            await this.refreshUser(res.body.user.uId);

            this.creds = JSON.parse(localStorage.getItem('creds'));

            LoggerState.clearLog('auth');

            // - reset these options to remove bug.
            this.selectableCompanies = null;
            this.requestCompanySelect = false;
        } catch (err) {
            logger.error(err?.response?.body);
            if (err?.response?.statusCode == 300) {
                this.selectableCompanies = err.response.body.customers;
                this.requestCompanySelect = true;
            } else {
                LoggerState.addLog('auth', {
                    type: 'error',
                    message: `Login Error: ${err}`,
                });
            }
        }
    }

    async refreshLogin() {
        logger.extra(this.creds);
        this.creds = localStorage.getItem('creds') ? JSON.parse(localStorage.getItem('creds')) : null;

        // if no creds, logout
        if (!this.creds) {
            this.logout();
        }

        try {
            const res = await request('POST', `${INTERNAL_API_BASE}/public/refresh`)
                .set('Content-Type', 'application/json')
                .send({ token: this.creds.refresh.token });

            localStorage.setItem('creds', JSON.stringify(res.body));
            localStorage.setItem('LOGIN', JSON.stringify({
                companyId: res.body.user.iId,
                username: res.body.user.username,
            }));

            this.creds = JSON.parse(localStorage.getItem('creds'));

            LoggerState.clearLog('auth');
        } catch (err) {
            logger.error('Could not refresh token', err);
            this.logout();
        }
    }

    logout() {
        this.clearAuth();
        window.location.replace('/login');
    }

    async getToken() {
        logger.info();
        this.creds = localStorage.getItem('creds') ? JSON.parse(localStorage.getItem('creds')) : null;

        // logout if creds don't exist
        if (!this.creds) {
            this.logout();
        }

        try {
            // if access token is expired, get refresh the token
            if (this.creds.access.expires < new Date().getTime()) {
                logger.warn('Access Token Expired, Refreshing...');
                await this.refreshLogin();
                return JSON.parse(JSON.stringify(this.creds)).access.token; // this app is so dumb
            } else {
                // else return access token
                return JSON.parse(JSON.stringify(this.creds)).access.token; // i am convinced i am in hell and dillon is tormenting me
            }
        } catch (err) {
            logger.error('Could not check or refresh token', err);
            this.logout();
        }
    }

    async resetPassword(password, newPassword) {
        logger.extra();
        try {
            const token = await this.getToken();
            await request('POST', `${INTERNAL_API_BASE}/private/updatePassword`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    password,
                    newPassword,
                });
            LoggerState.addLog(this.logKeys.reset_password, {
                type: 'success',
                message: 'Successfully Changed Password!',
            });
        } catch (err) {
            logger.error('RESET PASSWORD ERROR', err);
            LoggerState.addLog(this.logKeys.reset_password, {
                type: 'error',
                message: `Reset Password Error: ${err}`,
            });
        }
    }

    async create({ companyName, companyId, firstName, lastName, email, username, password, phone, title, coupon, auth0AccessToken, granQuartz }) {
        logger.extra();
        try {
            const res = await request('POST', `${INTERNAL_API_BASE}/public/customer`)
                .set('Content-Type', 'application/json')
                .send({ companyName, companyId, firstName, lastName, email, username, password, phone, title, coupon, auth0AccessToken, granQuartz });

            logger.extra('RESULT OF CREATE USER', res);

            this.user = res.body;
        } catch (err) {
            logger.error('CREATE USER ERROR', err);
            LoggerState.addLog('auth', {
                type: 'error',
                message: `Create User Error: ${err}`,
            });
        }
    }

    async refreshUser(userId) {
        logger.extra();
        try {
            const token = await this.getToken();
            const userRes = await request('GET', `${INTERNAL_API_BASE}/private/user/${userId}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            this.user = userRes.body.user;
        } catch (e) {
            logger.error(e);
            this.clearAuth();
        }
    }

    async validateUsername(username) {
        logger.extra();

        if (!username) {
            return;
        }

        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/username/exists`)
                .set('Content-Type', 'application/json')
                .query({
                    username
                });

            const exists = res.body.username;

            if (exists) {
                LoggerState.replaceLog(this.logKeys.validate_username, {
                    type: 'error',
                    message: 'Username Already Exists',
                });
                this.validatedUsernameExists = true;
            } else {
                LoggerState.replaceLog(this.logKeys.validate_username, {
                    type: 'success',
                    message: 'Username is Available',
                });
                this.validatedUsernameExists = false;
            }
        } catch (e) {
            logger.error('VALIDATE USERNAME ERROR', e);
            this.validatedUsernameExists = true;
        }
    }

    async validateCompanyId(company_id) {
        logger.extra();
        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/customer/exists`)
                .set('Content-Type', 'application/json')
                .query({
                    company_id
                });

            const exists = res.body.customer;

            if (exists) {
                LoggerState.replaceLog('validateCompanyId', {
                    type: 'error',
                    message: 'Company ID already exists, Please pick another',
                });
                this.validatedCompanyIdExists = true;
            } else {
                LoggerState.replaceLog('validateCompanyId', {
                    type: 'success',
                    message: 'Company ID is available'
                });
                this.validatedCompanyIdExists = false;
            }

            return exists;
        } catch (e) {
            logger.error('VALIDATE COMPANY NAME ERROR', e);
            this.validatedCompanyIdExists = true;
        }
    }

    async validateEmail(email) {
        logger.extra();

        if (!email) {
            return;
        }

        if (validation.emailValid(email)) {
            try {
                this.validatedEmailExists = await validation.emailExists(email);
            } catch (e) {
                logger.error('VALIDATE EMAIL ERROR:', e);
                this.validatedEmailExists = true;
            }
        } else {
            this.validatedEmailExists = true;
        }

    }

    async requestPasswordReset(body) {
        logger.extra();
        try {
            await request('POST', `${INTERNAL_API_BASE}/public/password/generateCode`)
                .set('Content-Type', 'application/json')
                .send(body);
        } catch (err) {
            logger.error(err);
            LoggerState.addLog('pwResetRequest', {
                type: 'error',
                message: `Error: ${err}`,
            });
        }
    }

    async validateResetCode(body) {
        logger.extra();
        try {
            await request('POST', `${INTERNAL_API_BASE}/public/password/validateCode`)
                .set('Content-Type', 'application/json')
                .send(body);
        } catch (e) {
            logger.error(e);
            LoggerState.addLog('pwResetCodeValidate', {
                type: 'error',
                message: `Error: ${e}`,
            });
        }
    }

    async requestEmailUpdate(body) {
        logger.extra();
        try {
            const token = await this.getToken();
            await request('POST', `${INTERNAL_API_BASE}/private/email/requestUpdateCode`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(body);

            LoggerState.addLog(this.logKeys.email_update, {
                type: 'info',
                message: `Email update request code sent to your old email ${this.creds.user.email}. Please check your email for the single use code and paste it into the field below`,
                timer: 30 * 1000, // 30 seconds
            });

            this.emailResetRequested = true;
        } catch (e) {
            logger.error(e);
            LoggerState.addLog(this.logKeys.email_update, {
                type: 'error',
                message: `Error: ${e.message}`,
            });
        }
    }

    async validateEmailUpdateCode(body) {
        logger.extra();
        const time = 5000;

        try {
            const token = await this.getToken();
            await request('PUT', `${INTERNAL_API_BASE}/private/email/validateUpdateCode`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(body);

            this.validEmailUpdateTimer = setInterval(() => {
                this.logout();
                clearInterval(this.validEmailUpdateTimer);
                this.validEmailUpdateTimer = null;
                this.emailResetRequested = false;
            }, time);

            LoggerState.addLog(this.logKeys.email_update, {
                type: 'success',
                message: `Email updated successfully! Please login again to complete the update. Logging out in ${Math.round(time / 1000)} seconds...`,
            });

            this.emailResetValidated = true;
        } catch (e) {
            logger.error(e);
            LoggerState.addLog(this.logKeys.email_update, {
                type: 'error',
                message: `Error: ${e.message}`,
            });
        }
    }

    async requestRetrieveCompanyId(body) {
        logger.extra();
        try {
            await request('POST', `${INTERNAL_API_BASE}/public/companyId`)
                .set('Content-Type', 'application/json')
                .send(body);

            LoggerState.addLog(this.logKeys.retrieve_company_id, {
                type: 'success',
                message: 'We\'ve sent you an email with the Ids requested. Check your inbox for your companyId',
            });
        } catch (e) {
            logger.error(e);
            LoggerState.addLog(this.logKeys.retrieve_company_id, {
                type: 'error',
                message: `Error: ${e.message}`,
            });
        }
    }

    async requestStripePortalSessionRedirect() {
        logger.extra();
        try {
            const token = await this.getToken();
            const response = await request('POST', `${INTERNAL_API_BASE}/private/stripe_portal_session`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({});

            logger.extra('SESSION URL:', response);

            this.stripePortalUrl = response.body.session_url;
        } catch (e) {
            logger.error('Error requesting Stripe Portal Session');
            throw e;
        }
    }

    setSelectedCompany(internal_id) {
        this.selectedCompany = internal_id;
    }

    clearAuth() {
        logger.extra();
        localStorage.removeItem('creds');
        localStorage.removeItem('LOGIN');

        this.user = null;
        this.creds = null;
    }
}

export default new Auth();