import React from 'react';
import PropTypes from 'prop-types';
import DashboardDrawer from './DashboardDrawer';
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from './styles';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const Dashboard = ({
    children,
    DrawerHeader,
    DrawerTabs,
}) => {
    logger.extra();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <DashboardDrawer
                DrawerHeader={DrawerHeader}
                DrawerTabs={DrawerTabs}
            />
            <div className={classes.contentWrapper}>
                <Toolbar />
                {children}
            </div>
        </div>
    );
};

Dashboard.propTypes = {
    DrawerHeader: PropTypes.node,
    DrawerTabs: PropTypes.arrayOf(PropTypes.node).isRequired,
    children: PropTypes.node
};

export default Dashboard;