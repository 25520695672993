import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import RolesPicker from '../../../components/RolesPicker';
import { SignupForm } from '../../../features';
import SearchableUsersState from '../../../state/searchable_users';
import AccountUsersState from '../../../state/account_users';
import SubscriptionsState from '../../../state/subscriptions';
import { roles } from '../../../helpers';

import { Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { Card, FormSection } from '@fifth-gear-tech/common-components/dist/components';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const AccountUserManager = observer(({ setIsAdding }) => {
    logger.extra();

    const [{ subscriptions }] = useState(() => SubscriptionsState);
    const [subscriptionRoles, setSubscriptionRoles] = useState(roles.generateProgramsBasicRoles(subscriptions));

    // on mount load user state and subscriptions
    useEffect(async () => {
        logger.extra();
        await SearchableUsersState.fetch();
        await SubscriptionsState.fetchSubscriptions();
    }, []);

    const createUser = async ({ ...rest }) => {

        const user = {
            username: rest.email,
            first_name: rest.firstName,
            last_name: rest.lastName,
            roles: roles.basicProgramRole,
            programRoles: subscriptionRoles,
            ...rest
        };

        await AccountUsersState.createUser(user);
        setIsAdding(false);
    };

    return (
        <Card colorTheme={Colors.Grey}>
            {subscriptions.map((subscription) => {

                // only display role picker for users with speed folder
                if (subscription?.program?.name?.toLowerCase().includes('speedfolder')) {

                    return (
                        <FormSection onSubmit={(e) => e.preventDefault()} label={'SPEEDfolder Roles'} subtext={'Configure your SPEEDfolder roles.'} key={'SPEEDfolder roles selection'}>

                            <div className={'InviteUserModal--roles'}>
                                <p className={'InviteUserModal--roles-label'}>Colleague&#39;s {subscription.program.name} Roles </p>
                                <span className={'InviteUserModal--roles-label-subtext'}>{subscription.program.name.toLowerCase().includes('speedfolder') ? 'Note: Basic Users in SPEEDfolder cannot create custom fields, or edit jobs after creating them.' : `This controls if a user can view or make changes to the ${subscription.program.name} software`}</span>
                                <RolesPicker
                                    rolesType='speedfolder'
                                    roles={subscriptionRoles[subscription.program_id] ? subscriptionRoles[subscription.program_id] : []}
                                    setRoles={(nRoles) => {
                                        const nSubRoles = { ...subscriptionRoles };
                                        nSubRoles[subscription.program_id] = nRoles;
                                        setSubscriptionRoles(nSubRoles);
                                    }}
                                />
                            </div>

                        </FormSection>

                    );
                }
            })}
            <SignupForm
                userOnly={true}
                onSubmitClick={createUser}
            />

        </Card >
    );
});

export default AccountUserManager;