import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Modal from '@material-ui/core/Modal';
import ModalState from '../../state/modal';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import './Modal.scss';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const GlobalModal = observer(({ paperProps, ...rest }) => {
    logger.extra();
    const [modalState] = useState(() => ModalState);

    return (
        <Modal
            onBackdropClick={() => ModalState.close()}
            onEscapeKeyDown={() => ModalState.close()}
            {...rest}
            open={!!modalState.component}
            hidden={ModalState.hidden}
            className='Modal'
            disableScrollLock={modalState.disableScrollLock}
            keepMounted={!!modalState.keepMounted}
        >
            <>
                {!modalState.paperless && (
                    <Paper {...paperProps} classes={{ root: 'ModalContent' }} >
                        <CardContent>
                            {modalState.component}
                        </CardContent>
                    </Paper>
                )}
                {modalState.paperless && modalState.component}
            </>
        </Modal>
    );
});

export default GlobalModal;