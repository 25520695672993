import React from 'react';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './UsersTable.scss';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const UsersTable = observer(({ users, onSettingsClick }) => {
    logger.extra();
    return (
        <TableContainer className={'UsersTable'}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>First / Last Name</TableCell>
                        <TableCell>Username</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Last Login</TableCell>
                        <TableCell>Manage</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {users.map((user) => (
                        <TableRow key={'mapOfUsers'}>
                            <TableCell>{user.first_name} {user.last_name}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user?.last_login_at ? new Date(user?.last_login_at).toLocaleString() : 'Never'}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={(e) => {
                                        if (onSettingsClick) {
                                            onSettingsClick(e, user);
                                        }
                                    }}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

export default UsersTable;