import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import Grid from '@material-ui/core/Grid';
import Logger from '../../../components/Logger';
import EmailUpdateForm from '../../../components/EmailUpdateForm';
import AccountUsers from '../AccountUsers/AccountUsers';
import AuthState from '../../../state/auth';
import LoggerState from '../../../state/logger';
import UserState from '../../../state/user';

import './AccountSettings.scss';
import { FormSection, FormContainer, Button, InputField } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, Orientation, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import TitleSelectField from '../../../components/TitleSelectField';
import { isAdmin } from '../../../utils/auth';

import { ROLES } from '../../../utils/constants';
import { useWindowSize } from '../../../hooks';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
const AccountSettings = observer(() => {
    logger.extra();
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [logState] = useState(() => LoggerState);
    const [authState] = useState(() => AuthState);
    const [userState] = useState(() => UserState);
    const {
        iId,
        uId,
        cName,
        username,
        first_name,
        last_name,
        email,
        phone,
        title,
        roles
    } = authState.creds.user;
    const [editMode, setEditMode] = useState(false);
    const [updatedUser, setUpdatedUser] = useState({
        customer_name: cName,
        customer_id: iId,
        email,
        username,
        first_name,
        last_name,
        phone,
        title,
    });

    const { width } = useWindowSize();

    useEffect(() => {
        logger.extra();
        if (!userState.user || userState.user.id !== uId) {
            userState.fetch({ user_id: uId });
        }
    }, [uId, userState]);

    const updateUserField = (key, value) => {
        const nUpdatedUser = { ...updatedUser };

        nUpdatedUser[key] = value;

        setUpdatedUser(nUpdatedUser);
    };

    const detailFields = [
        {
            attribute: 'Company Name:',
            value: cName,
            alt: null,
            key: 'customer_name'
        },
        {
            attribute: 'Company Id:',
            value: iId,
            alt: null,
            key: 'customer_id'
        },
        {
            attribute: 'Username:',
            value: userState.user ? userState.user.username : username,
            alt: null,
            key: 'username'
        },
        {
            attribute: 'Email:',
            value: userState.user ? userState.user.email : email,
            alt: null,
            key: 'email'
        },
        {
            attribute: 'First Name:',
            value: userState.user ? userState.user.first_name : first_name,
            alt: null,
            key: 'first_name'
        },
        {
            attribute: 'Last Name:',
            value: userState.user ? userState.user.last_name : last_name,
            alt: null,
            key: 'last_name'
        },
        {
            attribute: 'Phone:',
            value: userState.user ? userState.user.phone : phone,
            alt: <i>no phone number</i>,
            key: 'phone'
        },
        {
            attribute: 'Title:',
            value: userState.user ? userState.user.title : title,
            alt: <i>no job title</i>,
            key: 'title'
        },
    ];

    const generateRoles = () => {
        logger.extra();
        if (roles.findIndex((r) => r === ROLES.ADMIN.id) > -1) {
            return <span>Admin</span>;
        } if (roles.findIndex((r) => r === ROLES.ADMIN.id) === -1) {
            return <span>Basic User</span>;
        }
    };

    const generateDetailForm = () => {
        logger.extra();
        return detailFields.map((x, i) => (
            <div className={'AccountAttributeInput'} key={`AccountAttribute-${i}`}>
                <span>
                    <em>{x.attribute}</em>&nbsp;
                    {x.key === 'title' ? (
                        <TitleSelectField
                            disableLabel
                            SelectOptions={{
                                value: { value: updatedUser[x.key], label: updatedUser[x.key] },
                                onChange: (option) => {
                                    updateUserField(x.key, option.value);
                                },
                            }}
                        />
                    ) : (
                        <InputField
                            value={updatedUser[x.key]}
                            placeholder={null}
                            label={''}
                            disabled={(x.key === 'email' || x.key === 'customer_name' || x.key === 'customer_id' || x.key === 'username')}
                            onChange={(e) => updateUserField(x.key, e.target.value)} // todo: check username on change here.
                            clearIcon={false}
                        />
                    )}
                </span>
            </div>
        ));
    };

    const generateDetails = () => {
        logger.extra();
        return detailFields.map((x, i) => (
            <div className={'AccountAttribute'} key={`AccountAttribute-${i}`}>
                <span>
                    <em>{x.attribute}</em>&nbsp;
                    {x.value.length ? <p>{x.value}</p> : <i>{x.alt}</i>}
                </span>
            </div>
        ));
    };

    const isUpdateBtnDisabled = () => {
        let isDisabled = true;

        for (let i = 0; i < detailFields.length; i++) {
            const x = detailFields[i];

            if (updatedUser[x.key] !== x.value) {
                isDisabled = false;
            }
        }

        return isDisabled;
    };

    if (authState.stripePortalUrl !== null) {
        window.location.href = authState.stripePortalUrl;
    }

    return (
        <section className={'AccountSettings'}>

            {(width <= 860 && isAdmin()) &&
				<>
				    <AccountUsers />
				    <br />
				    <hr />
				    <br />
				</>
            }

            <FormContainer title={'Account Settings'}>
                <FormSection orientation={Orientation.Mobile}>
                    <div className={'AccountAttributes'}>
                        {editMode ? generateDetailForm() : generateDetails()}
                        <p ><strong>Role: </strong>{generateRoles()}</p>
                    </div>
                </FormSection>

                <div className={'AccountAttributeButtons'}>
                    {editMode && (
                        <>
                            <Button
                                label='Save Changes'
                                colorTheme={Colors.Green}
                                type={ButtonType.Save}
                                disabled={isUpdateBtnDisabled()}
                                onClick={() => {
                                    userState.update(uId, {
                                        first_name: updatedUser.first_name,
                                        last_name: updatedUser.last_name,
                                        phone: updatedUser.phone,
                                        title: updatedUser.title,
                                    });
                                    setEditMode(false);
                                }}
                            />
                            <Button
                                label='Cancel Changes'
                                type={ButtonType.Close}
                                onClick={() => setEditMode(false)}
                            />
                        </>
                    )}
                    {!editMode && (
                        <>
                            <Button
                                colorTheme={Colors.Sage}
                                type={ButtonType.Edit}
                                label='Edit'
                                onClick={() => setEditMode(true)}
                            />
                            {isAdmin() && (
                                <Button
                                    colorTheme={Colors.Sage}
                                    label='Billing'
                                    type={ButtonType.Update}
                                    onClick={() => authState.requestStripePortalSessionRedirect()}
                                />
                            )}
                        </>
                    )}
                </div>

            </FormContainer>

            <EmailUpdateForm />

            <FormContainer
                colorTheme={Colors.Grey}
                title={''}
                buttons={[
                    <Button
                        key={'reset'}
                        label={'Reset Password'}
                        type={ButtonType.Danger}
                        disabled={(
                            !password ||
							!newPassword ||
							!repeatPassword ||
							newPassword !== repeatPassword
                        )}
                        colorTheme={Colors.Red}
                        onClick={() => AuthState.resetPassword(password, newPassword)}
                    />
                ]}
            >
                <FormSection
                    colorTheme={Colors.Grey}
                    orientation={Orientation.Horizontal}
                    label={'Change Password'}
                    subtext={'Please enter your current password before entering your new password'}
                >
                    <>
                        {(logState.logs['resetPassword'] && logState.logs['resetPassword'].length > 0 && (
                            <Grid item xs={12}>
                                <Logger logKey='resetPassword' />
                            </Grid>
                        ))}

                        <InputField
                            value={password}
                            placeholder={'***********'}
                            label={'Current Password'}
                            type={'password'}
                            onChange={(e) => setPassword(e.target.value)}
                            clearIcon={false}
                        />

                        <InputField
                            value={newPassword}
                            placeholder={'***********'}
                            label={'New Password'}
                            type={'password'}
                            onChange={(e) => setNewPassword(e.target.value)}
                            clearIcon={false}
                        />

                        <InputField
                            value={repeatPassword}
                            placeholder={'***********'}
                            label={'Repeat Password'}
                            type={'password'}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            clearIcon={false}
                        />
                    </>

                </FormSection>
            </FormContainer>

        </section>
    );
});

export default AccountSettings;