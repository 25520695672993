import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';
import useQuery from './queryHook';
import AuthState from '../../state/auth';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const DashboardPanel = ({ index, children }) => {
    logger.extra();
    const classes = useStyles();
    const query = useQuery();
    const activeTab = Number(query.get('activeTab'));

    if (activeTab == 4) {
        window.location.href = '/account';
    }

    if (activeTab == 5) {
        AuthState.logout();
    }

    return (
        <div className={classes.contentWrapper} hidden={(index !== activeTab)}>
            {index === activeTab && (
                <div className={classes.content}>
                    {children}
                </div>
            )}
        </div>
    );
};

DashboardPanel.propTypes = {
    index: PropTypes.number,
    children: PropTypes.node
};

export default DashboardPanel;