import './TitleSelectField.scss';
import React, { forwardRef } from 'react';
import { bool, object } from 'prop-types';
import * as LabelPrimitive from '@radix-ui/react-label';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <ChevronDownIcon />
    </components.DropdownIndicator>
);

const TitleSelectField = forwardRef(({ disableIcon, disableLabel, isValid, SelectOptions, ...props }, ref) => (
    <fieldset className={'TitleSelectField'} >

        {!disableLabel && (
            <LabelPrimitive.Root htmlFor={'title'} className={'Label'}>
					Title:
            </LabelPrimitive.Root>
        )}

        <div className={`LabelContainer ${!isValid ? 'invalid' : ''}`}>
            <Select
                {...props}
                ref={ref}
                className={'TitleSelect'}
                classNamePrefix={'title-select'}
                placeholder={'Select Title...'}
                isSearchable={false}
                options={[
                    { label: 'Template / Measurer', value: 'Template / Measurer' },
                    { label: 'Programmer', value: 'Programmer' },
                    { label: 'Sales', value: 'Sales' },
                    { label: 'Project Management', value: 'Project Management' },
                    { label: 'Accounting', value: 'Accounting' },
                    { label: 'Installer', value: 'Installer' },
                    { label: 'Shop', value: 'Shop' },
                    { label: 'Owner', value: 'Owner' },
                    { label: 'Manager', value: 'Manager' },
                ]}
                components={{ DropdownIndicator: disableIcon ? null : DropdownIndicator }}
                {...SelectOptions}
            />

        </div>

    </fieldset>
));

TitleSelectField.displayName = 'TitleSelectField';

TitleSelectField.propTypes = {
    disableLabel: bool,
    isValid: bool,
    value: object,
    disableIcon: bool,
    SelectOptions: object
    // SelectOptions: Select.propTypes,
};

TitleSelectField.defaultProps = {
    disableIcon: false,
    disableLabel: false,
    isValid: true,
    value: { label: '', value: '' },
    // SelectOptions: Select.defaultProps,
};

export default TitleSelectField;