import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import colors from '../../../../theme/colors';
import { Card, Button } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import RolesPicker from '../../../../components/RolesPicker';
import UserSearchSelect from '../../../../components/UserSearchSelect';
import { ROLES } from '../../../../utils/constants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../Customer.scss';

import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export default observer(({ users, updateUserRoles }) => {
    logger.extra();
    const [selectedUser, setSelectedUser] = useState(null);

    const generateTable = () => {
        logger.extra();
        return (
            <TableContainer className={'CustomerUsers--table'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Company Role</TableCell>
                            <TableCell>Edit User</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    {user.first_name} {user.last_name}
                                </TableCell>
                                <TableCell>
                                    {user.username}
                                </TableCell>
                                <TableCell>
                                    <RolesPicker
                                        roles={user.roles ? user.roles.map((r) => r.role) : []}
                                        setRoles={(nRoles) => updateUserRoles(user.id, nRoles)}
                                    />
                                </TableCell>
                                <TableCell align='center'>
                                    <Link to={`/super/u/${user.id}?activeTab=1`}>
                                        <IconButton> {/* todo: Add link to the specific user page here. */}
                                            <EditIcon style={{ color: colors.blue[200] }} />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return users ? (
        <>
            <section className={'CustomerUsers'}>
                <div>
                    <h5>Users</h5>
                    <div className={'rightTwoColumn'}>
                        <UserSearchSelect
                            disableLabel
                            SelectProps={{
                                value: selectedUser,
                                onChange: setSelectedUser,
                            }}
                        />
                        <Button
                            type={ButtonType.Add}
                            disabled={!selectedUser}
                            variant='contained'
                            onClick={() => updateUserRoles(selectedUser.id, [ROLES.READ])}
                            label={'Add User'}
                        />
                    </div>
                </div>
                <Card colorTheme={Colors.Grey} className={'CustomerUsers--card'}>
                    {generateTable()}
                </Card>
            </section>

        </>
    ) : null;
});