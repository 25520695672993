import { makeObservable, observable } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE } from '../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SearchableSubscriptions {
    subscriptions = [];

    constructor() {
        logger.extra();
        makeObservable(this, {
            subscriptions: observable,
        });
    }

    async fetch() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/subscription/listAll`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { subscriptions } = res.body;

            this.subscriptions = subscriptions.map((subscription) => ({
                ...subscription,
                label: `${subscription.customer.name} -- ${subscription.program.name}`,
                value: subscription.id,
            }));
        } catch (e) {
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
            logger.error('FETCH SEARCHABLE SUBSCRIPTIONS ERROR', e);
        }
    }

}

export default new SearchableSubscriptions();