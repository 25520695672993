import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, FormSection, PhoneField, LocalAlert } from '@fifth-gear-tech/common-components/dist/components';
import { signupFormSelector, set, handleInput } from '../../features/signupForm/signupFormSlice';

import TitleSelectField from '../TitleSelectField';

const PersonalDetails = () => {
    // --- redux state ---
    const dispatch = useDispatch();
    const signupForm = useSelector(signupFormSelector);
    const { firstName, lastName, title, phone } = signupForm;

    // component phone validation
    const [phoneValid, setPhoneValid] = useState(false);

    // component title phone discovery state
    const [titleDiscovered, setTitleDiscovered] = useState(false);
    const titleRef = useRef();

    // --- helpers ---
    const handlePhoneInput = (value) => {
        dispatch(handleInput({ name: 'phone', value }));
    };

    const handleTitleInput = (payload) => {
        const { value } = payload;
        dispatch(handleInput({ name: 'title', value }));
    };

    // listen to name, title, and number updates and update personDetailsValid state accordingly
    useEffect(() => {
        if (firstName.length > 0 && lastName.length > 0 && title.length > 0 && phoneValid) {
            dispatch(set({ name: 'personalDetailsValid', val: true }));
        } else {
            dispatch(set({ name: 'personalDetailsValid', val: false }));
        }
    }, [firstName, lastName, title, phone, phoneValid]);


    // --- return component ---
    return (
        <FormSection label={'Personal Details'} subtext={'Your name and contact information.'}>

            <LocalAlert active={!firstName} message={'Missing'} />
            <InputField
                // aesthetic
                label={'First Name:'}
                placeholder={'First Name'}
                clearIcon={false}
                // entry
                value={firstName}
                name={'firstName'}
                onChange={(e) => dispatch(handleInput(e.target))}
                // validation
                isValid={!!firstName}
            />

            <LocalAlert active={!lastName} message={'Missing'} />
            <InputField
                // aesthetic
                label={'Last Name:'}
                placeholder={'Last Name'}
                clearIcon={false}
                // entry
                name={'lastName'}
                value={lastName}
                // open TitleSelectField menu 👇 when clicking away / tabbing to another field (make it stupid obvious what to do!)
                // set "titleDiscovered" to true as the user should now know that this is a mandatory select field
                onBlur={() => {
                    titleRef.current.focus(); setTitleDiscovered(true); 
                }}
                onChange={(e) => dispatch(handleInput(e.target))}
                // validation
                isValid={!!lastName}
            />

            <LocalAlert active={!title} message={'Missing'} />
            <TitleSelectField
                // entry
                // this will be set to true as soon as the last name blurs and this auto-focuses... therefore, this will NOT auto-focus on any blur events on last name after the original focus event...
                // yoshi wanted it like this... idc anymore
                openMenuOnFocus={!titleDiscovered}
                ref={titleRef}
                SelectOptions={{
                    value: title ? { label: title, value: title } : null,
                    onChange: (e) => handleTitleInput(e)
                }}
                // validation
                isValid={!!title}
            />

            <LocalAlert active={!phoneValid} message={phone ? 'Not a valid phone number.' : 'Missing'} />
            <PhoneField
                // aesthetic
                label={'Phone Number:'}
                // entry
                phone={phone}
                name={'phone'}
                onChange={(e) => handlePhoneInput(e)}
                // validation
                isValid={!!phoneValid}
                validate={setPhoneValid}
            />

        </FormSection>
    );
};

export default PersonalDetails;