// export { default } from './PrintLog';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

import { DashboardPanel, DrawerTab } from '../../../components/Dashboard';
import PropTypes from 'prop-types';
import PrintLog from '../../../components/PrintLog';

import SuperPrintLogState from '../../../state/super_print_log';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

export const PrintLogTab = ({ index }) => (
    <DrawerTab index={index} >
        <Typography variant='subtitle3' >SPEEDlabel Print Log</Typography>
    </DrawerTab>
);

PrintLogTab.propTypes = {
    index: PropTypes.number.isRequired,
};

export default observer(({ index }) => {
    logger.extra();

    useEffect(() => {
        SuperPrintLogState.setCustomerID(null);
        SuperPrintLogState.clearPrintLog();
    }, []);

    return (
        <DashboardPanel
            index={index}
        >
            <PrintLog />
        </DashboardPanel>
    );
});