import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CheckIcon } from '@radix-ui/react-icons';
import { InputField, FormSection, SelectField, Button, Card, Checkbox } from '@fifth-gear-tech/common-components/dist/components';
import { /* Colors,*/ ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import Logger from '../../../components/Logger';

import DataSourcesState from '../../../state/data_sources';
// import ModalState from '../../../state/modal';
import LoggerState from '../../../state/logger';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
const IntegrationForm = ({ dataSource, onSaveSuccess }) => {
    logger.extra();
    const [type, setType] = useState(dataSource ? dataSource.type : '');
    const [url, setUrl] = useState(dataSource ? dataSource.url : '');
    const [username, setUsername] = useState(dataSource ? dataSource.username : '');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [logState] = useState(() => LoggerState);
    const [passwordFieldType, setPWFieldType] = useState(true);

    const isDisabled = () => {
        logger.extra();
        return (
            !type ||
			(!url && (type === '1' || type === '2')) ||
			(!username && (type !== '3' && type !== '5')) ||
			(!password && (type !== '5')) ||
			(password !== repeatPassword && type !== '5')
        );
    };

    return (
        <Card>
            <h4>{dataSource ? 'Edit Integration' : 'New Integration'}</h4>
            {logState.logs['integrations'] && logState.logs['integrations'].length > 0 && (
                <div>
                    <Logger key='integrations' />
                </div>
            )}
            <FormSection
                label={''}
                subtext={'Select the intergration type that matches your ERP solution.'}
            >
                <SelectField
                    label={'Integration Type'}
                    placeholder={'Your ERP Solution'}
                    value={type ? { id: type, label: DataSourcesState.integrationTypes.find((it) => it.id === type).type } : null
                    }
                    options={DataSourcesState.integrationTypes.map(({ id, type }) => {
                        const hasConfigured = DataSourcesState
                            .data_sources
                            .find((ds) => ds.type === id);

                        if (!dataSource && hasConfigured) {
                            return null;
                        }

                        return { value: id, label: type, id, type };
                    })}
                    onChange={({ value }) => setType(value)}
                />
                {(type === '1' || type === '2' || type === '6') && (
                    <InputField
                        label={'Integration URL'}
                        placeholder={'Domain'}
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                    />
                )}
            </FormSection>
            {type !== '5' && (
                <FormSection
                    label={''}
                    subtext={'Specify your user details / API key to allow access to your ERP solution'}
                >
                    {type !== '3' && (
                        <InputField
                            label={'Integration User'}
                            placeholder={'Username'}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            clearIcon={false}
                        />
                    )}

                    <InputField
                        label={type !== '3' ? 'Integration Password' : 'API Key'}
                        placeholder={'***********'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        clearIcon={false}
                        type={passwordFieldType ? 'password' : 'text'}
                    />

                    <InputField
                        label={type !== '3' ? 'Repeat Password' : 'Repeat API Key'}
                        placeholder={'***********'}
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        clearIcon={false}
                        type={passwordFieldType ? 'password' : 'text'}
                    />
                    <br />
                    <Checkbox label={'Show Password'} defaultChecked={false} inputCallback={() => setPWFieldType(!passwordFieldType)} />
                </FormSection>
            )}

            <Button
                label={'Save Integration'}
                disabled={isDisabled()}
                type={isDisabled() ? ButtonType.Grey : ButtonType.Green}
                // colorTheme={isDisabled() ? Colors.Grey : Colors.Orange}
                customIcon={CheckIcon}
                form={false}
                onClick={() => {
                    const URL = type !== '4' && type !== '3' && type !== '5' ? url : 'N/A';
                    const USERNAME = type !== '3' && type !== '5' ? username : 'N/A';
                    const data_source = {
                        type: type,
                        url: URL,
                        username: USERNAME,
                        password: type !== '5' ? password : 'N/A',
                        status: type !== '4' && type !== '3' && type !== '5' ? 'inactive' : 'active',
                        version: '1.0.0',
                    };

                    if (!dataSource) {
                        DataSourcesState.createDataSource(data_source);
                    } else {
                        DataSourcesState.updateDataSource(data_source);
                    }

                    if (onSaveSuccess) {
                        onSaveSuccess();
                    }
                }}
            />
        </Card>
    );
};

IntegrationForm.propTypes = {
    dataSource: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
        username: PropTypes.string,
    }),
    onSaveSuccess: PropTypes.func,
};

export default IntegrationForm;