import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Button, InputField, LocalAlert } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import RolesPicker from '../../components/RolesPicker';
import { EnvelopeOpenIcon } from '@radix-ui/react-icons';

import InviteState from '../../state/invite';
import SubscriptionsState from '../../state/subscriptions';
import AuthState from '../../state/auth';
import ModalState from '../../state/modal';

import Logger from '../Logger';

import './InviteUserModal.scss';

import { roles, validation } from '../../helpers';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
export default observer(() => {
    logger.extra();
    // --- mobx state ---
    const [{ invite_email, invite_subscription_roles }] = useState(() => InviteState);
    const [{ subscriptions }] = useState(() => SubscriptionsState);

    // component state
    const [emailInvalid, setEmailInvalid] = useState(true);
    const [emailAlertMessage, setEmailAlertMessage] = useState('Not a valid email.');

    // ensure that subscriptions have been fetched on mount and then assign basic roles to each subscription by default.
    useEffect(async () => {
        await SubscriptionsState.fetchSubscriptions();
        InviteState.setSubscriptionRoles(roles.generateProgramsBasicRoles(subscriptions));
    }, []);

    // validate the invite email
    useEffect(async () => {
        if (invite_email && validation.emailValid(invite_email)) { // if email value is in state and is a valid email
            const exists = await validation.emailExists(invite_email); // check whether email already exists
            if (exists) {
                setEmailAlertMessage('Email already in use.');
                setEmailInvalid(true); // not a valid email if the email exists
            } else {
                setEmailInvalid(false); // valid email since the email does not exist in the db
            }
        } else {
            setEmailAlertMessage('Not a valid email.');
            setEmailInvalid(true); // if the value in state is not an email
        }
    }, [invite_email]);

    const InviteSent = <div><strong>{`Your invite was sent to ${invite_email}.`}</strong></div>;

    return (
        <section className={'InviteUserModal'}>
            <div className={'InviteUserModal--header'}>
                <div> <EnvelopeOpenIcon /> </div>
                <div>
                    <h4>Invite Users</h4>
                    <p>Invite a colleague to your company using their email.</p>
                </div>
            </div>

            {/* {LoggerState.logs[AuthState.logKeys.validate_email] && ( */}
            <Logger logKey={AuthState.logKeys.validate_email} />
            <Logger logKey={'inviteState'} />
            {/* )} */}


            <LocalAlert message={emailAlertMessage} active={emailInvalid} />
            <InputField
                label="Colleague's Email"
                defaultValue={invite_email}
                placeholder={'user@yourcompany.com'}
                onChange={(e) => InviteState.setEmail(e.target.value)}
            />

            {/* Roles for each active Subscription*/}
            {subscriptions.map((subscription) => {

                // only display role picker for users with speed folder
                if (subscription?.program?.name?.toLowerCase().includes('speedfolder')) {
                    return (
                        <div className={'InviteUserModal--roles'} key={'InviteUserModal'}>
                            <p className={'InviteUserModal--roles-label'}>Colleague&apos;s {subscription.program.name} Roles </p>
                            <span className={'InviteUserModal--roles-label-subtext'}>{subscription.program.name.toLowerCase().includes('speedfolder') ? 'Note: Basic Users in SPEEDfolder cannot create custom fields, or edit jobs after creating them.' : `This controls if a user can view or make changes to the ${subscription.program.name} software`}</span>
                            <RolesPicker
                                rolesType='speedfolder'
                                roles={invite_subscription_roles[subscription.program_id] ? invite_subscription_roles[subscription.program_id] : []}
                                setRoles={(nRoles) => {
                                    const nSubRoles = { ...invite_subscription_roles };
                                    nSubRoles[subscription.program_id] = nRoles;
                                    InviteState.setSubscriptionRoles(nSubRoles);
                                }}
                            />
                        </div>
                    );
                }
            })}

            <Button
                className={'InviteUserButton'}
                label='Invite User'
                disabled={emailInvalid}
                type={ButtonType.Email}
                onClick={async () => {
                    try {
                        await InviteState.sendInvite();
                        ModalState.close(this);
                        setTimeout(() => {
                            ModalState.open(InviteSent);
                        }, '500');
                    } catch (err) {
                        logger.error('Could not send invite.', err);
                        throw err;
                    }
                }}
            />
            <Button
                className={'CancelButton'}
                label='Cancel'
                type={ButtonType.Close}
                onClick={() => {
                    ModalState.close(this), InviteState.clear();
                }}
            />
        </section>
    );
});