import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line
import signupFormSlice from '../features/signupForm/signupFormSlice';

export default configureStore({
    reducer: {
        signupForm: signupFormSlice
    },
    // cowboy stuff
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});