import './CouponCodeInput.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import Grid from '@material-ui/core/Grid';
import { logTypeEnum } from '../../state/logger';
import Logger from '../Logger';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);

const CouponCodeInput = ({
    onClick,
    value,
    PaperProps,
    InputBaseProps,
    IconButtonProps,
    logKey,
    logTypes = [logTypeEnum.WARNING, logTypeEnum.SUCCESS]
}) => {
    logger.extra();
    return (
        <Grid container>
            {logKey && (
                <Grid item xs={12}>
                    <Logger
                        logKey={logKey}
                        logTypes={logTypes}
                    />
                </Grid>
            )}
            <Grid item xs={12}>
                <Paper component="form" className="CouponCodeInput" {...PaperProps} >
                    <InputBase
                        className={'CouponCodeInputBase'}
                        placeholder="Enter Coupon Code"
                        value={value}
                        {...InputBaseProps}
                    />
                    <Divider orientation="vertical" className="CouponCodeDivider" />
                    <Button
                        className="CouponCodeIconButton"
                        onClick={onClick}
                        endIcon={<ArrowForwardIcon />}
                        {...IconButtonProps}
                    >Apply Code</Button>
                </Paper>
            </Grid>
        </Grid>
    );
};

CouponCodeInput.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    PaperProps: PropTypes.object,
    InputBaseProps: PropTypes.object,
    IconButtonProps: PropTypes.object,
    logKey: PropTypes.string,
    logTypes: PropTypes.arrayOf(PropTypes.string),
};

export default CouponCodeInput;