import './AdTermsOfServiceModal.scss';
import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@fifth-gear-tech/common-components/dist/components';
import Logger from '@fgt/common/utils/logger';

import UserState from '../../state/user';
import ModalState from '../../state/modal';
import AuthState from '../../state/auth';

import sfAdTosPdf from '../../assets/sf-ad-tos.pdf';

const logger = new Logger(__filename);

const AdTermsOfServiceModal = ({ uId, callback }) => {

    const agreeToTerms = async ({ uId }) => {
        try {
            await UserState.agreeToAdvertiserTermsOfService({ uId });
            await callback();
            ModalState.close();
            AuthState.refreshLogin();
        } catch (err) {
            logger.extra(err);
        }
    };

    return (
        <div className='AdTermsOfServiceModal'>
            <h5>Agree to the <a className='Tos' target='_blank' href={sfAdTosPdf} rel="noreferrer">advertiser terms of service</a> to promote ads on SPEEDfolder?</h5>
            <br />
            <Checkbox label={'Agree to Terms'} defaultChecked={false} inputCallback={() => agreeToTerms({ uId })} />
        </div>
    );
};

AdTermsOfServiceModal.propTypes = {
    uId: PropTypes.number,
    callback: PropTypes.func
};

export default AdTermsOfServiceModal;