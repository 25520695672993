import { makeObservable, observable } from 'mobx';
import request from 'superagent';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class Products {
    products = [];

    constructor() {
        logger.extra();
        makeObservable(this, {
            products: observable,
        });
    }

    async fetchProducts() {
        logger.extra();
        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/products`)
                .set('Content-Type', 'application/json');

            this.products = res.body;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            logger.error('PRODUCT REQUEST ERROR', e);
        }
    }

    getProductByName(name) {
        logger.extra();
        const productIndex = this
            .products
            .findIndex((product) => product.external_product.name === name);

        return productIndex > -1 ? this.products[productIndex] : null;
    }

    getProductByExternalId(external_id) {
        logger.extra();
        const productIndex = this
            .products
            .findIndex((product) => product.external_product.id === external_id);

        return productIndex > -1 ? this.products[productIndex] : null;
    }

    getProductByPriceId(price_id) {
        logger.extra();
        return this.products.find(({ external_product }) => external_product.prices.find((price) => price.id === price_id));
    }

    getProductComponentsByExternalId(external_product_id) {
        logger.extra();
        const productIndex = this
            .products
            .findIndex((product) => product.external_product.id === external_product_id);

        if (productIndex > -1) {
            const productComponents = [];

            for (let i = 0; i < this.products.length; i++) {
                const product = this.products[i];
                const { external_product } = product;

                if (
                    external_product.metadata &&
                    external_product.metadata.component === 'true' &&
                    external_product.metadata.product_id === external_product_id
                ) {
                    productComponents.push(product);
                }
            }

            return productComponents;
        } else {
            return null;
        }
    }

}

export default new Products();