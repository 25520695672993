import React from 'react';
import { SignInForm } from '@fifth-gear-tech/common-components/dist/components';
import AuthState from '../../state/auth';
import PropTypes from 'prop-types';


const SignIn = ({ customHeader, onLoginSuccess }) => (
    <SignInForm
        fullWidth
        disableCard={true}
        customHeader={customHeader}
        onLoginSuccess={onLoginSuccess ? async () => {
            await AuthState.refreshLogin(); await onLoginSuccess(); 
        } : () => AuthState.refreshLogin()}
    />
);

SignIn.propTypes = {
    customHeader: PropTypes.node,
    onLoginSuccess: PropTypes.func
};

export default SignIn;