const isStandardPricing = ({ price }) => {
    const { type, tiers_mode, billing_scheme, transform_quantity } = price;
    return (
        !transform_quantity &&
		(type === 'one_time' || type === 'recurring') &&
		billing_scheme &&
		!tiers_mode
    );
};

const isPackagePricing = ({ price }) => {
    const { type, tiers_mode, billing_scheme, transform_quantity } = price;
    return (
        !!transform_quantity &&
		(type === 'one_time' || type === 'recurring') &&
		billing_scheme === 'per_unit' &&
		!tiers_mode
    );
};

const isGraduatedPricing = ({ price }) => {
    const { type, tiers_mode, billing_scheme } = price;

    return (
        type === 'recurring' &&
		!!billing_scheme &&
		billing_scheme === 'tiered' &&
		!!tiers_mode &&
		tiers_mode === 'graduated'
    );
};

const isVolumePricing = ({ price }) => {
    const { type, tiers_mode, billing_scheme } = price;
    return (
        type === 'recurring' &&
		!!billing_scheme &&
		billing_scheme === 'tiered' &&
		!!tiers_mode &&
		tiers_mode === 'volume'
    );
};

// - assumes amount is integer and in cents (default for stripe.price objects).
const parseAmount = ({ unit_amount }) => {
    const base = unit_amount / 100;
    let decimalAdapter = '';

    if (unit_amount % 100 === 0) {
        decimalAdapter = '.00';
    } else if ((unit_amount % 100) % 10 === 0) {
        decimalAdapter = '0';
    }

    return `$${base}${decimalAdapter}`;
};

const parseRecurringInterval = ({ price }) => {
    const { recurring } = price;
    const { interval, interval_count } = recurring;
    const intervalCount = interval_count > 1 ? `${interval_count} ` : '';
    const intervalStr = `${interval}${interval_count > 1 ? 's' : ''}`;

    return `${interval_count > 1 ? 'every' : 'per'} ${intervalCount}${intervalStr}`;
};

const transformTieredPrice = ({ price, unit = 'unit' }) => {
    const { tiers, tiers_mode } = price;
    const recurringString = parseRecurringInterval({ price });
    const breakdown = [];

    for (let i = 0; i < tiers.length; i++) {
        const { unit_amount, flat_amount, up_to } = tiers[i];
        const up_from = i === 0 ? 1 : tiers[i - 1].up_to + 1;
        let up_to_string = '';

        if (tiers_mode === 'graduated') {
            if (!!up_to && up_from === 1) {
                up_to_string = `for the first ${up_from > 1 ? up_from : ''} ${unit}${(up_to - up_from) > 1 ? 's' : ''}${up_from === up_to ? '' : ` to ${up_to} ${unit}s`}`;
            }

            if (!!up_to && up_from > 1) {
                up_to_string = `for the next ${up_from} ${unit}${(up_to - up_from) > 1 ? 's' : ''}${up_from === up_to ? '' : ` to ${up_to} ${unit}s`}`;
            }

            if (!up_to && up_from > 1) {
                up_to_string = 'for the rest';
            }
        } else if (tiers_mode === 'volume') {
            if (!!up_to && up_from >= 1) {
                up_to_string = `if the total quantity ${up_from}${up_from === up_to ? '' : ` to ${up_to}`}`;
            }

            if (!up_to && up_from > 1) {
                up_to_string = `if the total quantity is ${up_from} or more`;
            }
        }

        breakdown.push({
            per_unit_preamble: i === 0 ? 'Starts at ' : i !== (tiers.length - 1) ? 'then ' : 'then ',
            per_unit_price: unit_amount ? parseAmount({ unit_amount }) : null,
            per_unit_string: unit_amount ? ` per ${unit}` : null,
            flat_amount_preamble: unit_amount && flat_amount ? ' + ' : null,
            flat_amount_price: flat_amount ? parseAmount({ unit_amount: flat_amount }) : null,
            flat_amount_string: flat_amount ? ' Flat Fee' : null,
            up_to_string: up_to_string,
            interval_string: recurringString,
        });
    }

    return {
        friendly_type: tiers_mode,
        price_id: price.id,
        product_id: price.product_id,
        breakdown,
        price: price,
    };

};

const transformStandardPrice = ({ price }) => {
    const { recurring, unit_amount } = price;

    const breakdown = [{
        per_unit_preamble: null,
        per_unit_price: parseAmount({ unit_amount }),
        per_unit_string: null,
        flat_amount_preamble: null,
        flat_amount_price: null,
        flat_amount_string: null,
        up_to_string: null,
        interval_string: recurring ? ` ${parseRecurringInterval({ price })}` : null,
    }];

    return {
        friendly_type: 'standard',
        price_id: price.id,
        product_id: price.product_id,
        breakdown,
        price: price,
    };
};

const transformPackagedPrice = ({ price, unit = 'group' }) => {
    const { unit_amount, recurring, transform_quantity } = price;

    const breakdown = [{
        per_unit_preamble: null,
        per_unit_price: parseAmount({ unit_amount }),
        per_unit_string: `per ${unit} of ${transform_quantity.divide_by}`,
        flat_amount_preamble: null,
        flat_amount_price: null,
        flat_amount_string: null,
        up_to_string: null,
        interval_string: recurring ? ` ${parseRecurringInterval({ price })}` : null,
    }];

    return {
        friendly_type: 'package',
        price_id: price.id,
        product_id: price.product_id,
        breakdown,
        price: price,
    };
};

const transformGraduatedPrice = (arg) => transformTieredPrice(arg);

const transformVolumePrice = (arg) => transformTieredPrice(arg);

/*
transformed price:
{
price_id: 'somePriceID', // the price id of the original price.
friendly_type: 'standard' // - one of 'graduated' || 'volume' || 'standard' || 'package',
breakdown: [breakdown_object], // - array because the price could be tiered. standard and package ALWAYS [].length === 1
price: {}, // - the original price object
};

breakdown_object:
{
per_unit_preamble: null || 'Starts at', // -- defined if type:graduated
per_unit_price: '$10.00', // -- possibly null if flat_amount_price defined.
per_unit_string: null || '',// -- defined only if type:package (per ${transform_quantity.divide_by});
flat_amount_preamble: null || '', // -- null if type:standard or type:package
flat_amount_price: null || '',// -- null if type:standard for type:package
flat_amount_string: null, // -- only null, feature specifics still TBD
up_to_string: null || 'For the first 1 to 4', // -- null if type:standard or type:package
interval_string: null || '/ month',
}
*/
const transformPrice = (arg) => {
    if (isStandardPricing(arg)) {
        return transformStandardPrice(arg);
    } else if (isPackagePricing(arg)) {
        return transformPackagedPrice(arg);
    } else if (isGraduatedPricing(arg)) {
        return transformGraduatedPrice(arg);
    } else if (isVolumePricing(arg)) {
        return transformVolumePrice(arg);
    }
};

// - assumes amount is integer and in cents (default for stripe.price objects).
const calcAmount = ({ amount, quantity }) => {
    const amount_subtotal = amount * quantity;
    const friendly_amount_subtotal = parseAmount({ unit_amount: amount_subtotal });

    return {
        amount,
        transformed_amount: parseAmount({ unit_amount: amount }),
        quantity,
        amount_subtotal,
        friendly_amount_subtotal,
    };
};

const calcStandardPrice = ({ price, quantity, unit }) => {
    if (quantity === 0) {
        return { subtotal: 0, breakdown: [] };
    }

    const calculated_unit_amount = calcAmount({ amount: price.unit_amount, quantity });

    return {
        subtotal: calculated_unit_amount.amount_subtotal,
        breakdown: [{ calculated_unit_amount, calculated_flat_amount: null, tier: null }],
        transformed_price: transformPrice({ price, unit }),
        price_id: price.id,
        price,
        quantity,
    };
};

const calcPackagePrice = calcStandardPrice;

const calcVolumePrice = ({ price, quantity, unit }) => {
    if (quantity === 0) {
        return { subtotal: 0, breakdown: [] };
    }

    const { tiers } = price;
    const tier = tiers.find(({ up_to }) => quantity <= up_to || up_to === null);
    const calculated_unit_amount = tier.unit_amount ? calcAmount({ amount: tier.unit_amount, quantity }) : null;
    const calculated_flat_amount = tier.flat_amount ? calcAmount({ amount: tier.flat_amount, quantity: 1 }) : null;

    return {
        subtotal: (calculated_unit_amount ? calculated_unit_amount.amount_subtotal : 0) + (calculated_flat_amount ? calculated_flat_amount.amount_subtotal : 0),
        breakdown: [{ tier, calculated_unit_amount, calculated_flat_amount }],
        transformed_price: transformPrice({ price, unit }),
        price_id: price.id,
        price,
        quantity,
    };

};

const calcGraduatedPrice = ({ price, quantity, unit }) => {
    const { tiers } = price;
    const breakdown = [];
    let subtotal = 0;

    const forEval = (i) => {
        const up_from = i === 0 ? 0 : tiers[i - 1].up_to;
        return quantity >= up_from && i < tiers.length;
    };

    for (let i = 0; forEval(i); i++) {
        const tier = tiers[i];
        const up_from = i === 0 ? 0 : tiers[i - 1].up_to;
        const { up_to, unit_amount, flat_amount } = tier;
        let tier_quantity = 0;

        if (!!up_to && quantity >= up_to) {
            tier_quantity = up_to - up_from;
        } else if (!up_to || (!!up_to && quantity < up_to)) {
            tier_quantity = quantity - up_from;
        }

        const calculated_unit_amount = unit_amount ? calcAmount({ amount: unit_amount, quantity: tier_quantity }) : null;
        const calculated_flat_amount = flat_amount ? calcAmount({ amount: flat_amount, quantity: 1 }) : null;

        breakdown.push({ tier, calculated_flat_amount, calculated_unit_amount });

        subtotal += calculated_unit_amount ? calculated_unit_amount.amount_subtotal : 0;
        subtotal += calculated_flat_amount ? calculated_flat_amount.amount_subtotal : 0;
    }

    return {
        subtotal,
        breakdown,
        transformed_price: transformPrice({ price, unit }),
        price_id: price.id,
        price,
        quantity,
    };
};

/*
calculated price
{
transformed_price: transformed_price,
subtotal: Integer in cents,
breakdown: [breakdown_object],
price_id,
price,
quantity,
}

breakdown object
{
calculated_unit_amount,
calculated_flat_amount,
}

calculated unit and flat amount
{
amount: Integer of amount pre quantity modifier
friendly_amount: string in usd decimal,
quantity: Integer of amount_qty
amount_subtotal: Integer in cents, ex: 15000
friendly_amount_subtotal: string in usd decimal, '$150.00'
}
*/

const calcPrice = (arg) => {
    if (isStandardPricing(arg)) {
        return calcStandardPrice(arg);
    } else if (isPackagePricing(arg)) {
        return calcPackagePrice(arg);
    } else if (isGraduatedPricing(arg)) {
        return calcGraduatedPrice(arg);
    } else if (isVolumePricing(arg)) {
        return calcVolumePrice(arg);
    }
};


module.exports = {
    isStandardPricing,
    isPackagePricing,
    isGraduatedPricing,
    isVolumePricing,

    parseAmount,
    parseRecurringInterval,

    transformPrice,
    transformTieredPrice,
    transformStandardPrice,
    transformPackagedPrice,
    transformGraduatedPrice,
    transformVolumePrice,

    calcAmount,
    calcStandardPrice,
    calcPackagePrice,
    calcVolumePrice,
    calcGraduatedPrice,
    calcPrice,
};