import './Cart.scss';

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { FormContainer, Card, Button } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType, ButtonSize, Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { EmailOutlined, PhoneOutlined } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import CartProductLineItem from '../../components/CartProductLineItem';
import CouponCodeInput from '../../components/CouponCodeInput';
import Logger from '../../components/Logger';

import { ArrowLeftIcon } from '@radix-ui/react-icons';

import { hasAuth } from '../../utils/auth';

import CartState from '../../state/cart';
import ProductsState from '../../state/products';
import SubscriptionsState from '../../state/subscriptions';
import LoggerState from '../../state/logger';
import eula from '../../assets/eula.pdf';
import fgtLogger from '@fgt/common/utils/logger';

const logger = new fgtLogger(__filename);
const Cart = observer(() => {
    logger.extra();
    const [{ products }] = useState(() => ProductsState);
    const [{ items, couponCode, checkoutSession }] = useState(() => CartState);
    const [{ subscriptions }] = useState(() => SubscriptionsState);
    const [fetchedSubscriptions, setFetchedSubscriptions] = useState(false);
    const duplicateSubscriptionsLogKey = 'subscriptions.duplicate';
    const userAuthed = hasAuth();
    const history = useHistory();

    const hasSubscriptionsInCart = () => {
        logger.extra();
        let hasSubscriptions = -1;

        if (hasAuth()) {
            for (let i = 0; i < items.length; i++) {
                const { product: { external_product_id } } = items[i];
                const subscriptionIndex = subscriptions.findIndex((sub) => sub.program.external_product.id === external_product_id);

                if (subscriptionIndex > -1) {
                    hasSubscriptions = subscriptionIndex;
                }
            }
        }

        const logValue = {
            type: 'error',
            message: 'You have one or more items in your cart for which you are already subscribed. Please remove them to continue checking out',
            disableCloseTimer: true,
        };

        if (hasSubscriptions > -1) {
            LoggerState.replaceLog(duplicateSubscriptionsLogKey, logValue);

            return true;
        }

        logger.extra('REMOVE LOG');
        LoggerState.removeLog(duplicateSubscriptionsLogKey, logValue);

        return false;
    };

    const isDisabled = () => {
        logger.extra();
        return (
            !hasAuth() ||
            CartState.items.length <= 0 ||
            (LoggerState.logs[duplicateSubscriptionsLogKey] && LoggerState.logs[duplicateSubscriptionsLogKey].length > 0)
        );
    };

    useEffect(() => {
        logger.extra();
        if (items.length > 0 && userAuthed && subscriptions.length === 0 && !fetchedSubscriptions) {
            logger.extra('FETCH SUBSCRIPTIONS');
            SubscriptionsState.fetchSubscriptions();
            setFetchedSubscriptions(true);
        } else {
            hasSubscriptionsInCart();
        }
    }, [items.length, userAuthed, subscriptions]);

    useEffect(() => {
        logger.extra();
        if (checkoutSession && checkoutSession.url && new Date(checkoutSession.expires_at * 1000) > new Date()) {
            window.location.href = checkoutSession.url;
        }
    }, [checkoutSession]);

    useEffect(() => {
        CartState.abandonShipIfNoSubscription();
    }, []);

    return (
        <div className='Checkout' >
            <center>
                <Container className="MuiContainer">

                    {
                        LoggerState.logs[duplicateSubscriptionsLogKey] &&
                        LoggerState.logs[duplicateSubscriptionsLogKey].length > 0 && (
                            <Grid item xs={12}>
                                <Logger logKey={duplicateSubscriptionsLogKey} />
                                &nbsp;
                            </Grid>
                        )
                    }

                    {/* Back button */}
                    <div className='HomeButton'>
                        {/* eslint-disable-next-line */}
                        <p onClick={() => history.push('/')}><ArrowLeftIcon />Back</p>
                    </div>

                    {/* Coupon Code */}
                    <CouponCodeInput
                        onClick={() => CartState.applyCouponCode()}
                        value={couponCode}
                        InputBaseProps={{
                            onChange: (e) => CartState.setCouponCode(e.target.value),
                            error: true,
                        }}
                        PaperProps={{
                            style: { backgroundColor: '#f8f8f8' },
                            elevation: 4,
                        }}
                        logKey='cart.coupon'
                    />

                    <Card>

                        <Typography variant='caption'><strong>By clicking checkout you agree to FifthGear Tech&#39;s <a href={eula} target='_blank' rel="noreferrer" style={{ color: '#ff791f' }} > End User License Agreement</a>.</strong></Typography>

                        <br />
                        <br />

                        <Button
                            type={isDisabled() ? ButtonType.Grey : ButtonType.Orange}
                            label={CartState.sessionLoading ? 'Redirecting' : 'Checkout'}
                            size={ButtonSize.Large}
                            colorTheme={Colors.Green}
                            disabled={isDisabled()}
                            onClick={() => CartState.createCheckoutSession()}
                        >{CartState.sessionLoading ? <CircularProgress size='small' color='secondary' /> : null}</Button>

                    </Card>


                    <FormContainer title={''} onSubmit={() => { }} >
                        <Grid container spacing={3}>
                            {/* if there are no items in the cart */}
                            {items.length < 1 && (
                                <Grid item xs={12}>
                                    <Typography variant='h4' align={'center'}>Your Cart Is Currently Empty</Typography>
                                    <div className='CartNote'>
                                        <p className='Centered'>Request <em>SPEEDlabel</em> refills by contacting support. </p>
                                        <p><EmailOutlined fontSize='small' className='Icon' /> <a href='mailto:support@fifthgeartech.com'>support@fifthgeartech.com</a></p>
                                        <p><PhoneOutlined fontSize='small' className='Icon' /><a href='tele:+17085052812'>(708) 505-2812</a></p>
                                    </div>
                                </Grid>
                            )}
                            {items.map((item, i) => (
                                <CartProductLineItem
                                    products={{ products }}
                                    product={item.product}
                                    price={item.price}
                                    qty={item.qty}
                                    key={`${item.product.external_product.name}-${i}`}
                                />
                            ))}
                        </Grid>
                    </FormContainer>

                </Container>
            </center>
        </div>
    );
});

export default Cart;