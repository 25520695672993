import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import SearchableCustomersState from '../../state/searchable_customers';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const CustomerSearchSelect = observer(({
    disableLabel = false,
    SelectProps,
    GridContainerProps,
}) => {
    const [searchableCustomersState] = useState(() => SearchableCustomersState);

    useEffect(() => {
        if (searchableCustomersState.customers.length === 0) {
            SearchableCustomersState.superFetchAll();
        }
    }, [searchableCustomersState.customers.length]);

    return (
        <Grid {...GridContainerProps} container item xs={12}>
            {!disableLabel && (
                <>
                    <Grid item xs={12}>
                        <Typography align='left'>Select Existing Customer</Typography>
                    </Grid>
                    <Grid item xs={12} ><Divider /></Grid>
                </>
            )}
            <Grid item xs={12}>
                <Select
                    {...SelectProps}
                    options={searchableCustomersState.customers}
                />
            </Grid>
        </Grid>
    );
});

export default CustomerSearchSelect;