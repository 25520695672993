const colors = {
    orange: {
        100: '#843003',
        200: '#F38A27', // - main
        300: '#FFBA66',
        400: '#FFF2D9',
    },
    monotone: {
        100: '#151C23',
        200: '#1F2933',
        300: '#52606D', // - main
        400: '#9AA5B1',
        500: '#E4E7EB',
        600: '#F5F7FA',
    },
    blue: {
        100: '#2D3A8C',
        200: '#4C63B6', // - main
        300: '#98AEEB',
    },
    red: {
        100: '#780A0A',
        200: '#BA2525', // - main
        300: '#F29B9B',
    },
    yellow: {
        100: '#7C5E10',
        200: '#E9B949', // - main
        300: '#F8E3A3',
    },
    green: {
        100: '#0E5814',
        200: '#3F9142', // - main
        300: '#A3D9A5',
    },
};

export default colors;