import './AccountIntegrations.scss';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import IntegrationsTable from './IntegrationsTable';
import IntegrationForm from './IntegrationForm';

import DataSourcesState from '../../../state/data_sources';
import AuthState from '../../../state/auth';
// import ModalState from '../../../state/modal';

import { Card, Button, FormSection } from '@fifth-gear-tech/common-components/dist/components';
import { Colors, ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import { InfoCircledIcon, GearIcon, TrashIcon, Cross1Icon } from '@radix-ui/react-icons';
import { useWindowSize } from '../../../hooks';
import Chip from '@material-ui/core/Chip';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
const AccountIntegrations = observer(() => {
    logger.extra();
    const [collapseExpanded, setCollapseExpanded] = useState(false);
    const [dataSourcesState] = useState(() => DataSourcesState);
    const [formOpen, setFormOpen] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        logger.extra();
        DataSourcesState.fetchDataSources(AuthState.creds.user.cId);
    }, []);

    const defaultMessage = (
        <div className={'defaultMessage'}>
            <InfoCircledIcon />
            <div>
                <p>Integrations connect your project management software allowing you to generate labels on the fly.</p>
                <p>Add new or modify existing data sources such as <a href={'https://www.moraware.com/'} target='_blank' rel="noreferrer" >Moraware</a> or <a href='https://easededge.com/' target='_blank' rel="noreferrer" >Eased Edge</a>.</p>
            </div>
        </div>
    );

    return (
        <section className={'AccountIntegrations'}>
            <div>
                <h4>Integration Settings</h4>
                <Button
                    label={formOpen ? '' : 'Add Integration'}
                    type={formOpen ? ButtonType.Close : ButtonType.Add}
                    onClick={() => setFormOpen(!formOpen)}
                    disabled={dataSourcesState.loading || dataSourcesState.data_sources.length > 0}
                />
            </div>

            {formOpen && (<IntegrationForm onSaveSuccess={() => setFormOpen(!setFormOpen)} />)}

            {/* mobile version*/}
            {width <= 768 && !dataSourcesState.loading && dataSourcesState.data_sources.length > 0 && (
                <IntegrationCard dataSource={dataSourcesState.data_sources[0]} />
            )}

            {width <= 768 && (!dataSourcesState.loading && dataSourcesState.data_sources.length === 0) &&
				<Card colorTheme={Colors.Grey} className={'CurrentIntegrations'} >
				    {defaultMessage}
				</Card>
            }

            {/* tablet / desktop version*/}
            {width >= 769 && (
                <Card colorTheme={Colors.Grey} className={'CurrentIntegrations'}>
                    {(!dataSourcesState.loading && dataSourcesState.data_sources.length === 0) && defaultMessage
                    }

                    {!dataSourcesState.loading && dataSourcesState.data_sources.length > 0 && (
                        <ExistingIntegrations
                            dataSourcesState={dataSourcesState}
                            collapseExpanded={collapseExpanded}
                            setCollapseExpanded={setCollapseExpanded}
                        />
                    )}

                    {dataSourcesState.loading && dataSourcesState.data_sources.length === 0 && (
                        <div className={'defaultMessage'}>
                            <CircularProgress />
                        </div>
                    )}

                </Card>
            )}
        </section>
    );
});

const ExistingIntegrations = observer(({ dataSourcesState }) => {
    logger.extra();
    return (
        <>
            {dataSourcesState.loading && dataSourcesState.data_sources.length === 0 && (
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            )}

            {!dataSourcesState.loading && dataSourcesState.data_sources.length > 0 && (
                <Grid item xs={12}>
                    <IntegrationsTable dataSources={dataSourcesState.data_sources} />
                </Grid>
            )}
        </>
    );
});

const IntegrationCard = ({ dataSource }) => {
    const [formOpen, setFormOpen] = useState(false);
    const typeNameIndex = DataSourcesState.integrationTypes.findIndex((it) => it.id === dataSource.type);

    useEffect(() => {
        logger.extra();
        if (dataSource.type === '1' || dataSource.type === '2') {
            DataSourcesState.fetchIntegrationStatus({ data_source: dataSource });
        }
    }, []);

    return (
        <Card
            className={'MobileIntegrationCard'}
        >
            <FormSection
                // className={'MobileIntegrationCard'}
                label={`${DataSourcesState.integrationTypes[typeNameIndex].type} v${dataSource.version}`}
                subtext={(
                    <span>
                        {dataSource.url}
                        <Chip
                            className={`IntegrationStatus --mobile ${dataSource.status === 'active' ? '--active' : '--inactive'}`}
                            label={dataSource.status}
                        />
                    </span>
                )}
            >
                <div className={'--buttonArea'}>
                    <div>
                        <Button
                            label={formOpen ? 'Close Edit Form' : 'Edit Integration'}
                            customIcon={formOpen ? Cross1Icon : GearIcon}
                            colorTheme={formOpen ? Colors.Yellow : Colors.Orange}
                            onClick={() => setFormOpen(!formOpen)}
                        />
                    </div>
                    <div>
                        {!formOpen && (
                            <Button
                                label={'Delete Integration'}
                                customIcon={TrashIcon}
                                colorTheme={Colors.Red}
                                onClick={() => DataSourcesState.removeDataSource({ type: dataSource.type })}
                            />
                        )}
                    </div>
                </div>
            </FormSection>
            {formOpen && (
                <IntegrationForm
                    dataSource={dataSource}
                    onSaveSuccess={() => setFormOpen(!formOpen)}
                />
            )}
        </Card>
    );
};

IntegrationCard.propTypes = {
    dataSource: PropTypes.shape({
        type: PropTypes.string,
        version: PropTypes.string,
        url: PropTypes.string,
        status: PropTypes.string,
    }).isRequired
};

export default AccountIntegrations;