import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Logger from '@fgt/common/utils/logger';
import PropTypes from 'prop-types';

const logger = new Logger(__filename);
const Error = ({ log }) => {
    logger.extra();
    const { message/* , id, type, canClose*/ } = log;

    return (
        <Alert
            severity='error'
            variant='filled'
        >
            {message}
        </Alert>
    );
};

Error.propTypes = {
    log: PropTypes.object
};

export default Error;