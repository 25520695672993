import { makeObservable, action, observable } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import { INTERNAL_API_BASE } from '../utils/constants';
import AuthState from './auth';
// import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
class SuperChargesHistory {
    charges = [];

    chargesLoading = false;

    hasMore = false;

    logKeys = {
        fetchCustomer: 'super_charges_history.fetch',
    };

    constructor() {
        logger.extra();
        makeObservable(this, {
            charges: observable,
            chargesLoading: observable,
            hasMore: observable,
            fetch: action,
        }, { deep: true });
    }

    async fetch() {
        logger.extra();

        try {
            this.chargesLoading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/super/charges`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            this.charges = res.body.charges;
            this.hasMore = res.body.has_more;
        } catch (e) {
            logger.error('FETCH SUPER CHARGES ERROR');
        }

        this.chargesLoading = false;
    }
}

export default new SuperChargesHistory();