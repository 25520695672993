import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import colors from './colors';

const overrides = {
    overrides: {
        MuiButton: {
            contained: {
                '&:hover': {
                    backgroundColor: colors.orange[300],
                    color: colors.monotone[500],
                },
                backgroundColor: colors.orange[200],
                color: colors.monotone[600],
            },
            outlined: {
                '&:hover': {
                    borderColor: colors.orange[300],
                    color: colors.orange[300],
                },
                borderColor: colors.orange[200],
                color: colors.orange[200],
            }
        },
    }
};

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    ...overrides,
    ...palette,
    ...typography,
});

const customTheme = { ...defaultTheme, ...theme };

export default customTheme;