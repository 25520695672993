import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import AuthState from '../../../../state/auth';
import { Button, Card, InputField, FormSection } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType, Colors } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';

import CustomerDetails from './CustomerDetails';
import CustomerUsers from './CustomerUsers';
import CustomerSubscriptions from './CustomerSubscriptions';
import ChargesTable from '../../../../components/ChargesTable';

import SuperCustomer from '../../../../state/super_customer';
import SuperCustomerSubscriptions from '../../../../state/super_customer_subscriptions';
import DataSources from '../../../../state/data_sources';
import SuperCustomerUsers from '../../../../state/super_customer_users';
import RolesState from '../../../../state/roles';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
export default observer(({ crumbUpdate }) => {
    logger.extra();
    const match = useRouteMatch();
    const { customer_id } = match.params;
    const [{ customer, charges, chargesLoading }] = useState(() => SuperCustomer);
    const [{ subscriptions }] = useState(() => SuperCustomerSubscriptions);
    const [{ users }] = useState(() => SuperCustomerUsers);
    const [password, setPassword] = useState('');

    useEffect(() => {
        logger.extra();
        RolesState.fetch();
        SuperCustomer.fetch(customer_id);
        SuperCustomer.fetchCharges();
        DataSources.fetchDataSources(customer_id);
        SuperCustomerUsers.fetch(customer_id);
        SuperCustomerSubscriptions.fetch(customer_id);
        crumbUpdate(customer_id);
        crumbUpdate(null);
    }, []);

    useEffect(() => {
        logger.extra();
        crumbUpdate(<>
            {customer_id}
        </>);
    }, [crumbUpdate, customer_id]);

    const handleCustomerAuth = () => {
        if (!customer || !customer?.internal_id) {
            SuperCustomer.fetch(customer_id);
        } else {
            AuthState.login({
                username: AuthState.creds.user.username,
                companyId: customer.internal_id,
                password
            });
        }
    };

    logger.extra('Customer id:', AuthState.creds.user.cId, customer_id);

    return (
        <Grid container spacing={4} >
            <Grid item xs={12}>
                <CustomerDetails customer={customer} />
            </Grid>
            <Grid item xs={12}>
                <CustomerUsers
                    users={users}
                    updateUserRoles={(...args) => SuperCustomerUsers.updateUserRoles(...args)}
                />
            </Grid>
            <Grid item xs={12}>
                <Card>
                    {AuthState.creds.user.cId !== Number(customer_id) && (
                        <>
                            <FormSection
                                label='Login with Company Profile'
                                subtext='Enter super user password to login with company profile.'
                            >
                                <InputField
                                    label={'Password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={'*************'}
                                    type='password'
                                />
                            </FormSection>
                            <Button
                                label={'Login with Company Profile'}
                                colorTheme={Colors.Green}
                                type={ButtonType.Login}
                                onClick={handleCustomerAuth}
                            />
                        </>
                    )}

                    {AuthState.creds.user.cId === Number(customer_id) && (
                        <>
                            <FormSection>
                                <h5>You are currently logged in as this customer.</h5>
                            </FormSection>
                            <Button
                                label={'Manage customer in dashboard'}
                                colorTheme={Colors.Green}
                                type={ButtonType.Login}
                                onClick={() => window.location.href = '/account'}
                            />
                        </>
                    )}
                </Card>
            </Grid>
            <Grid item xs={12} >
                <CustomerSubscriptions subscriptions={subscriptions} />
            </Grid>
            <Grid item xs={12}>
                <ChargesTable
                    charges={charges}
                    loading={chargesLoading}
                />
            </Grid>
        </Grid>
    );
});