import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE } from '../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

class Roles {
    roles = [];

    constructor() {
        logger.extra();
        makeObservable(this, {
            roles: observable,
            fetch: action,
        }, { deep: true });
    }

    async fetch() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/roles`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { roles } = res.body;
            const superIndex = roles.find((r) => r.name === 'Super');

            roles.splice(superIndex, 1);

            this.roles = roles;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
        }
    }
}

export default new Roles();