import { makeObservable, observable, action } from 'mobx';
import request from 'superagent';
// import Joi from 'joi';
import AuthState from './auth';
import { INTERNAL_API_BASE } from '../utils/constants';
import LoggerState from './logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

class DataSources {
    data_sources = [];

    loading = false;

    customer_id = null;

    status_loading = false;

    // because why would this be a pure object? 🤦
    integrationTypes = [
        { id: '1', type: 'Moraware' },
        { id: '2', type: 'Moraware (Self Hosted)' },
        { id: '3', type: 'Eased Edge' },
        { id: '4', type: 'Action Flow' },
        { id: '5', type: 'SPEEDfolder' },
        { id: '6', type: 'Stone App' }
    ];

    constructor() {
        logger.extra();
        makeObservable(this, {
            data_sources: observable,
            loading: observable,
            status_loading: observable,
            fetchDataSources: action,
            createDataSource: action,
            updateDataSource: action,
            removeDataSource: action,
            fetchIntegrationStatus: action,
        }, { deep: true });
    }

    async fetchDataSources(customer_id) {
        logger.extra();
        try {
            this.loading = true;
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${customer_id}/data_sources`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});
            const { data_sources } = res.body;

            this.customer_id = customer_id;
            this.data_sources = data_sources;
            this.loading = false;
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
        }
    }

    async createDataSource(dataSource) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/data_source`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({ data_source: dataSource });

            const { data_source } = res.body;

            this.data_sources.push(data_source);
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }
            LoggerState.addLog('integrations', {
                type: 'error',
                message: `Create integration error: ${e}`,
            });
        }
    }

    async updateDataSource(data_source) {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('PUT', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/data_source`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({
                    data_source,
                });

            const { data_source: data_source_resp } = res.body;
            const dataSourceIndex = this.data_sources.findIndex((d) =>
                d.customer_id === data_source_resp.customer_id && d.type === data_source_resp.type);

            if (dataSourceIndex > -1) {
                this.data_sources.splice(dataSourceIndex, 1, data_source_resp);
            } else {
                this.data_sources.push(data_source_resp);
            }
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('integrations', {
                type: 'error',
                message: `Update integration error: ${e.message}`,
            });
        }
    }

    async removeDataSource({ type }) {
        logger.extra();
        this.status_loading = true;

        try {
            const token = await AuthState.getToken();
            const res = await request('DELETE', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/data_source/${type}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({});

            const dataSourceIndex = this.data_sources.findIndex((d) =>
                d.customer_id === this.customer_id && d.type === type);

            const { success } = res.body;

            if (success) {
                this.data_sources.splice(dataSourceIndex, 1);
            }

            this.status_loading = false;
        } catch (e) {
            logger.error(e);
            this.status_loading = false;
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            LoggerState.addLog('integrations', {
                type: 'error',
                message: `Remove data source error: ${e.message}`
            });
        }

    }

    async fetchIntegrationStatus({ data_source }) {
        logger.extra();
        const dataSourceIndex = this.data_sources.findIndex((d) =>
            d.customer_id === this.customer_id && d.type === data_source.type);

        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/customer/${this.customer_id}/data_source/${data_source.type}/status`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send({});
            const { success } = res.body;

            if (success) {
                this.data_sources.splice(dataSourceIndex, 1, {
                    ...this.data_sources[dataSourceIndex],
                    status: 'active'
                });
            }
        } catch (e) {
            logger.error(e);
            if (e.code === 'Unauthorized') {
                AuthState.clearAuth();
            }

            this.data_sources.splice(dataSourceIndex, 1, {
                ...this.data_sources[dataSourceIndex],
                status: 'inactive'
            });

            /* LoggerState.addLog('integrations', {
type: 'error',
message: `Fetch integration status error: ${e.message}`,
});*/
        }
    }

}

export default new DataSources();