import './PasswordResetValidationForm.scss';
import React, { useState } from 'react';

import { FormSection, FormContainer, Button, InputField, Checkbox } from '@fifth-gear-tech/common-components/dist/components';
import { ButtonType } from '@fifth-gear-tech/common-components/dist/utils/StyledTheme';
import PropTypes from 'prop-types';

import { useQuery } from '../../hooks';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const PasswordResetValidate = ({ disableCard = false, ...props }) => {
    logger.extra();
    if (disableCard === false) {
        return (
        // <FormContainer title='Reset Password' >
            <PasswordResetValidateForm {...props} />
        // </FormContainer>
        );
    }

    return <PasswordResetValidateForm {...props} />;
};

const PasswordResetValidateForm = ({ customHeader, onSubmit, customLinkArea }) => {
    logger.extra();
    const query = useQuery();
    const resetHash = query.get('resetHash');
    const [resetCode, setResetCode] = useState(resetHash ? resetHash : '');
    const [newPass, setNewPass] = useState('');
    const [repeatPass, setRepeatPass] = useState('');
    const [passwordFieldType, setPasswordFieldType] = useState(true);

    const handleSubmit = (e) => {
        logger.extra();
        e.preventDefault();

        onSubmit(e, { newPass, repeatPass, resetCode });
    };

    return (
        <div className='PasswordResetValidateForm'>
            <FormContainer title='Reset Password' onSubmit={handleSubmit}  >
                {!customHeader && (<h5>Enter Username and Email</h5>)}

                {!!customHeader && customHeader}

                <FormSection
                    label={'Enter New Password'}
                    subtext={'Enter your one time code and new password here.'}
                >
                    <InputField
                        label='Reset Code:'
                        placeholder='Reset Code'
                        value={resetCode}
                        clearIcon={null}
                        onChange={(e) => setResetCode(e.target.value)}
                    />
                    <InputField
                        label='New Password:'
                        placeholder='New Password'
                        value={newPass}
                        clearIcon={null}
                        type={passwordFieldType ? 'password' : 'text'}
                        onChange={(e) => setNewPass(e.target.value)}
                    />
                    <InputField
                        label='Repeat New Password:'
                        placeholder='Repeat New Password'
                        value={repeatPass}
                        clearIcon={null}
                        type={passwordFieldType ? 'password' : 'text'}
                        onChange={(e) => setRepeatPass(e.target.value)}
                    />
                    <br />
                    <Checkbox label={'Show Password'} defaultChecked={false} inputCallback={() => setPasswordFieldType(!passwordFieldType)} />
                </FormSection>
                <div className={'ButtonArea'}>
                    <Button
                        label={'Reset Password'}
                        disabled={(
                            !newPass ||
							!repeatPass ||
							(newPass !== repeatPass)
                        )}
                        type={ButtonType.Orange}
                        variant='contained'
                        form={true}
                    />
                    {!!customLinkArea && (
                        <span>{customLinkArea}</span>
                    )}
                </div>
            </FormContainer>
        </div>
    );
};

PasswordResetValidate.propTypes = {
    disableCard: PropTypes.bool
};

PasswordResetValidateForm.propTypes = {
    customHeader: PropTypes.node,
    onSubmit: PropTypes.func,
    customLinkArea: PropTypes.node
};

export default PasswordResetValidate;