import { makeObservable, action, observable } from 'mobx';
import request from 'superagent';
import AuthState from './auth';
import { INTERNAL_API_BASE, STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDFOLDER_PRODUCT_ID, STRIPE_SPEEDLABEL_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID } from '../utils/constants';

// import SubscriptionsState from '../state/subscriptions';
import LoggerState, { logTypeEnum } from '../state/logger';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);
// import Logger from './logger'

const CHECKOUT_SESSION_ID_KEY = 'checkout_session_id';

class Cart {

    items = [];

    checkoutSession = null;

    checkoutSessionId = localStorage.getItem(CHECKOUT_SESSION_ID_KEY) ? localStorage.getItem(CHECKOUT_SESSION_ID_KEY) : null;

    checkoutComplete = false;

    completedSession = null;

    receiptUrl = null;

    coupon = null;

    couponCode = '';

    sessionLoading = false;

    shippingMethods = [];

    shippingMethodsLoading = false;

    shippingMethodsFetched = false;

    selectedShippingMethod = null;

    constructor() {
        logger.extra();
        makeObservable(this, {
            items: observable,
            completedSession: observable,
            checkoutSession: observable,
            receiptUrl: observable,
            coupon: observable,
            couponCode: observable,
            sessionLoading: observable,
            checkoutComplete: observable,
            shippingMethods: observable,
            shippingMethodsLoading: observable,
            shippingMethodsFetched: observable,
            selectedShippingMethod: observable,
            abandonShipIfNoSubscription: observable,
            itemInCart: action,
            addItem: action,
            updateQty: action,
            createCheckoutSession: action,
            getCheckoutSession: action,
            fetchShippingMethods: action,
            selectShippingMethods: action
        }, { deep: true });
    }

    abandonShipIfNoSubscription = () => {
        // check if subscription still exists in cart
        // if not navigate home because the cart only exists for subscriptions now
        const subscription = this.items.find((i) => [STRIPE_SPEEDCAD_PRODUCT_ID, STRIPE_SPEEDFOLDER_PRODUCT_ID, STRIPE_SPEEDLABEL_PRODUCT_ID, STRIPE_SPEEDTEMPLATE_PRODUCT_ID].includes(i?.product?.external_product_id));
        if (!subscription) {
            document.location.href = '/';
        }
    }

    itemInCart(product) {

        if (product) {

            const productIndex = this.items.findIndex((itm) =>
                itm.product.external_product.id === product.external_product.id);

            return productIndex > -1 ? true : false;

        }

        return false;
    }

    addItem({ product, price, qty }) {
        logger.extra({ product, price, qty });

        this.items.push({ product, price, qty });
    }

    updateQty({ product_id, qty }) {
        logger.extra({ product_id, qty });
        const productIndex = this.items.findIndex((itm) =>
            itm.product.id === product_id);

        logger.extra('UPDATE QTY:', product_id, productIndex);

        // this is removing an item if the quantity is less than 0 🤷
        if (productIndex > -1 && qty > 0) {
            const nProduct = { ...this.items[productIndex] };
            logger.extra('SPLICE AND UPDATE,', qty);
            this.items.splice(productIndex, 1, { ...nProduct, qty });
        } else if (productIndex > -1) {
            logger.extra('sPLICE and remove: ', productIndex);
            this.items.splice(productIndex, 1);
        }

        this.abandonShipIfNoSubscription();
    }

    setCouponCode(val) {
        logger.extra();
        this.couponCode = val;
    }

    async applyCouponCode() {
        logger.extra();
        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/coupon`)
                .set('Content-Type', 'application/json')
                .query({ coupon: this.couponCode });

            if (res.body.coupon !== null) {
                this.coupon = res.body.coupon;
                LoggerState.addLog('cart.coupon', {
                    type: logTypeEnum.SUCCESS,
                    message: 'Coupon Code Applied!',
                });
            } else {
                LoggerState.addLog('cart.coupon', {
                    type: logTypeEnum.WARNING,
                    message: 'Coupon Code Not Found.',
                });
            }
        } catch (e) {
            logger.error(e);
            LoggerState.addLog('cart.coupon', {
                type: logTypeEnum.ERROR,
                message: e
            });
        }
    }

    async createCheckoutSession() {
        logger.extra();
        this.sessionLoading = true;

        try {
            const sessionData = {
                coupon: this.coupon,
                items: this.items.map(({ product, price, qty }) => ({
                    program_id: product.id ? product.id : null,
                    external_price_id: price.id,
                    external_product_id: product.external_product.id,
                    quantity: qty,
                }))
            };

            if (this.selectedShippingMethod !== null) {
                sessionData.shipping_rate = [this.selectedShippingMethod];
            }

            const token = await AuthState.getToken();
            const res = await request('POST', `${INTERNAL_API_BASE}/private/checkout-session`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .send(sessionData);

            this.checkoutSession = res.body.checkoutSession;
            this.sessionLoading = false;

            localStorage.setItem(CHECKOUT_SESSION_ID_KEY, this.checkoutSession.id);
        } catch (e) {
            logger.error('CREATE CHECKOUT SESSION ERROR:', e);
            this.sessionLoading = false;
        }
    }

    async getCheckoutSession() {
        logger.extra();
        try {
            const token = await AuthState.getToken();
            const res = await request('GET', `${INTERNAL_API_BASE}/private/checkout-session/${this.checkoutSessionId}`)
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${token}`)
                .query({});

            const { checkoutSession } = res.body;
            const receiptUrl = checkoutSession?.receipt_url;
            this.checkoutSession = checkoutSession;

            this.receiptUrl = receiptUrl ? receiptUrl.replace('?s=ap', '/pdf?s=em') : null;

        } catch (e) {
            logger.error('GET CHECKOUT SESSION ERROR:', e);
        }
    }

    emptyCart() {
        logger.extra();
        this.items = [];
    }

    async fetchShippingMethods() {
        logger.extra();
        this.shippingMethodsLoading = true;

        try {
            const res = await request('GET', `${INTERNAL_API_BASE}/public/shipping`)
                .set('Content-Type', 'application/json')
                .query({});

            this.shippingMethods = res.body.shippingMethods;
            this.shippingMethodsFetched = true;
        } catch (e) {
            logger.error('FETCH SHIPPING METHODS', e);
        }

        this.shippingMethodsLoading = false;
    }

    selectShippingMethods(shippingMethodID) {
        const shippingMethodIndex = this.shippingMethods.findIndex((sm) => sm.id === shippingMethodID);

        if (shippingMethodIndex > -1) {
            this.selectedShippingMethod = this.shippingMethods[shippingMethodIndex].id;
        }
    }

}

export default new Cart();