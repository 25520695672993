import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // --- account details ---
    email: '',
    password: '',
    repeatPassword: '',
    accountDetailsValid: false,

    // --- company details ---
    companyName: '',
    companyId: '',
    repeatCompanyId: '',
    companyDetailsValid: false,

    // --- personal details ---
    firstName: '',
    lastName: '',
    phone: '',
    title: '',
    personalDetailsValid: false,

    // --- gran quartz sales rep details ---
    repFirstName: '',
    repLastName: '',
    repDetailsValid: false
};

export const signupFormSlice = createSlice({

    name: 'signupForm',

    initialState,

    reducers: {
        handleInput: (state, { payload }) => {
            const { name, value } = payload;
            state[name] = value;
        },
        set: (state, { payload }) => {
            const { name, val } = payload;
            state[name] = val;
        },
        reset: () => initialState
    }

});

export const signupFormSelector = (state) => state.signupForm;
export const { handleInput, set, reset } = signupFormSlice.actions;
export default signupFormSlice.reducer;