import './RolesPicker.scss';
// import FormGroup from '@material-ui/core/FormGroup';
import RolesState from '../../state/roles';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Tooltip from '@material-ui/core/Tooltip';
// import Checkbox from '@material-ui/core/Checkbox';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
// import { SelectField } from '@fifth-gear-tech/common-components/dist/components';
import SelectField from 'react-select';

import { ROLES } from '../../utils/constants';
import Logger from '@fgt/common/utils/logger';

const logger = new Logger(__filename);

const UserRoles = observer(({ roles, setRoles, label = '', className = '', style, rolesType = 'company' }) => {
    logger.extra();

    useEffect(() => {
        logger.extra();
        if (RolesState.roles.length === 0) {
            RolesState.fetch();
        }
    }, []);

    // this is some super cowboy shit but we're on a deadline so whatever
    const createSelectOptions = (rType) => {
        const basicLabel = rType == 'speedfolder' ? 'Basic User (Read Only)' : 'Basic User';
        const adminLabel = rType == 'speedfolder' ? 'Admin User (Read & Write)' : 'Admin User';

        const options = [
            {
                label: basicLabel,
                value: 'BASIC_USER',
                roles: [
                    ROLES.GRANT,
                    ROLES.DELETE,
                    ROLES.WRITE,
                    ROLES.READ
                ]
            },
            {
                label: adminLabel,
                value: 'ADMIN',
                roles: [
                    ROLES.ADMIN,
                    ROLES.GRANT,
                    ROLES.DELETE,
                    ROLES.WRITE,
                    ROLES.READ
                ]
            }
        ];

        if (rType !== 'speedfolder') {
            options.push({ label: 'Disabled', value: 'DISABLED', roles: [] });
        }

        // if (rType == 'ads') {
        //     options.push({
        //         label: 'Advertiser',
        //         value: 'ADVERTISER',
        //         roles: [
        //             ROLES.ADVERTISE,
        //             ROLES.ADMIN,
        //             ROLES.GRANT,
        //             ROLES.DELETE,
        //             ROLES.WRITE,
        //             ROLES.READ
        //         ]
        //     });
        // }

        return options;
    };

    // yolo
    const selectOptions = createSelectOptions(rolesType);

    const findValue = () => {
        let index;

        logger.extra('FIND VALUE:', roles.findIndex((r) => r.id === ROLES.ADMIN.id), 'ROLES:', roles, 'ROLE:', ROLES.ADMIN);

        // if no roles or empty array. value = NONE.
        if (!roles || roles.length === 0) {
            index = 2;
        } else if (roles.findIndex((r) => r.id === ROLES.ADVERTISE.id) > -1) {
            index = 3;
        } else if (roles.findIndex((r) => r.id === ROLES.ADMIN.id) > -1) {
            // if roles contains ADMIN role, value = ADMIN.
            index = 1;
        } else {
            // anything else is basic user.
            index = 0;
        }

        return selectOptions[index];
    };

    return (
        <div className={`RolesPicker ${className}`} style={style ? style : null} >
            {label && (<p className={'label'}>{label}</p>)}
            {label.toLowerCase().includes('speedfolder') ? <div><small><em><strong>Note:</strong> Basic Users in SPEEDfolder cannot create custom fields, or edit jobs after creating them.</em></small><br /><br /></div> : null}
            <SelectField
                options={selectOptions}
                value={findValue()}
                onChange={(option) => {
                    setRoles(option.roles);
                }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
            />
        </div >
    );
});

export default UserRoles;